import { Switch } from "antd";
import React, { FC } from "react";
import "./switchComponent.scss";

interface SwitchComponentProps {
  label?: string;
  onSwitch: (value: boolean) => void;
  value: boolean;
  checkedTitle?: string;
  unCheckedTitle?: string;
}

const SwitchComponent: FC<SwitchComponentProps> = (props) => {
  const { label, onSwitch, value, checkedTitle, unCheckedTitle } = props;

  return (
    <div className="switch-component__wrapper">
      {label ? <label>{label}</label> : ""}
      <div className={"switch-component"}>
        <Switch checked={value} onChange={onSwitch} />
        {checkedTitle || unCheckedTitle ? (
          <div className={`switch-title ${value ? "checked" : "unchecked"}`}>{value ? checkedTitle : unCheckedTitle}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SwitchComponent;
