import { Divider, message } from "antd";
import { Form, Formik, FormikValues } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { string } from "yup";
import { VendorModel } from "../../../models/Vendor/vendor.model";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import AuthService from "../../../services/AuthService/auth.service";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import IconComponent from "../../../shared/components/IconComponent";
import OTPField from "../../../shared/components/OTPField";
import "./validateotpemail.scss";
import { validateOtpEmailValidation } from "./validateOtpEmailValidation";

interface ValidateOtpEmailProps { }

const ValidateOtpEmail: FC<ValidateOtpEmailProps> = (props) => {
  const { } = props;
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const location = useLocation()
  const mobileNumber = searchParams?.get("mobile-number");
  const countryCodeId = searchParams?.get("country-code");
  const email = (location.state ?? searchParams?.get("email")) as string;
  const navigate = useNavigate();
  const { error, loading, validateEmailMobileNumberOtp, resendOtp } = AuthService();
  const [mobileTimer, setMobileTimer] = useState(30);
  const [emailTimer, setEmailTimer] = useState(30);

  const [disableResendEmailButton, setDisableResendEmailButton] = useState(false);
  const [disableResendMobileButton, setDisableResendMobileButton] = useState(false);

  const initialValues = {
    emailOtp: "",
    mobileNumberOtp: ""
  };

  const startTimer = (type: "email" | "mobile") => {
    let ms = 29;
    let otpTimer = setInterval(() => {
      if (ms === 0) { 
        clearInterval(otpTimer); 
        return; 
      } 
      --ms; 
      type === "email" ? setEmailTimer(ms) : setMobileTimer(ms);
    }, 1000);
  };

  const onResendCode = (values: FormikValues, type: "email"|"mobile") => {
    type === "email" ? setDisableResendEmailButton(true) : setDisableResendMobileButton(true);
    resendOtp({ email, mobileNumber: mobileNumber??"", resendOtpType: type === "email" ? "E" : "M" }, () => {
      startTimer(type);
      setTimeout(() => {
        type === "email" ?
        setDisableResendEmailButton(false) :
        setDisableResendMobileButton(false)
      }, 1000);
    })
  };

  const onSubmit = (values: FormikValues) => {
    if (values?.emailOtp?.length == 4 && values?.mobileNumberOtp?.length == 4 && mobileNumber && email && countryCodeId) {
      validateEmailMobileNumberOtp({...values,mobileNumber,email, countryCodeId},()=>{
        navigate(AppRoutes.BUSINESSDETAILS)
      })
    } else {
      message.error("OTP is required.");
    }
  };

  useEffect(() => {
    startTimer("email")
    startTimer("mobile")
  }, []);

  return (
    <div className="validation-otp-email-form">
      <div className=" form_heading">
        <h1>{t("validate_otp_email")}</h1>
      </div>
      <div>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validateOtpEmailValidation}>
          {({ values, setFieldValue, isValid, errors }) => (
            <Form>
              <p className="validation-otp-email-form-field__info">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.2" d="M21 5.25L12 13.5L3 5.25H21Z" fill="#75787B"/>
                  <path d="M21 4.5H3C2.80109 4.5 2.61032 4.57902 2.46967 4.71967C2.32902 4.86032 2.25 5.05109 2.25 5.25V18C2.25 18.3978 2.40804 18.7794 2.68934 19.0607C2.97064 19.342 3.35218 19.5 3.75 19.5H20.25C20.6478 19.5 21.0294 19.342 21.3107 19.0607C21.592 18.7794 21.75 18.3978 21.75 18V5.25C21.75 5.05109 21.671 4.86032 21.5303 4.71967C21.3897 4.57902 21.1989 4.5 21 4.5ZM19.0687 6L12 12.4781L4.93125 6H19.0687ZM20.25 18H3.75V6.95625L11.4937 14.0531C11.632 14.1796 11.8126 14.2498 12 14.2498C12.1874 14.2498 12.368 14.1796 12.5062 14.0531L20.25 6.95625V18Z" fill="#75787B"/>
                </svg>
                {t("enter_otp_sent_to")} {email}
              </p>
              <OTPField
                count={4}
                onChange={(otp) => {
                  setFieldValue("emailOtp", otp);
                }}
              />
              <p
                className={`validation-otp-email-form-timer__info ${!emailTimer && "timed-out"} ${disableResendEmailButton&&"disabled"}`}
                onClick={() => {
                  if (!emailTimer) onResendCode(values, "email");
                }}
              >
                {emailTimer
                ? t("resend_code_timer", {
                  timer: emailTimer?.toString()?.padStart(2, "0"),
                })
                : t("resend_code_to_email")}
              </p>

              <Divider />

              <p className="validation-otp-email-form-field__info">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path opacity="0.2" d="M8.67187 11.7C9.44364 13.2938 10.7324 14.5792 12.3281 15.3469C12.4458 15.4026 12.576 15.4267 12.7059 15.4169C12.8358 15.407 12.9608 15.3635 13.0687 15.2906L15.4125 13.725C15.516 13.6548 15.6357 13.6119 15.7603 13.6004C15.8849 13.589 16.0104 13.6092 16.125 13.6594L20.5125 15.5437C20.6625 15.6062 20.7877 15.7161 20.869 15.8567C20.9504 15.9973 20.9832 16.1608 20.9625 16.3219C20.8234 17.4073 20.2937 18.4048 19.4723 19.1278C18.6509 19.8508 17.5943 20.2498 16.5 20.25C13.1185 20.25 9.87548 18.9067 7.48439 16.5156C5.0933 14.1245 3.75 10.8815 3.75 7.5C3.75025 6.40575 4.1492 5.34908 4.87221 4.52771C5.59522 3.70634 6.59274 3.17656 7.67812 3.0375C7.83922 3.01681 8.00266 3.04964 8.14326 3.13096C8.28386 3.21227 8.39384 3.33755 8.45625 3.4875L10.3406 7.88438C10.3896 7.9972 10.4101 8.12035 10.4003 8.24296C10.3905 8.36558 10.3507 8.4839 10.2844 8.5875L8.71875 10.9688C8.64905 11.0765 8.60814 11.2002 8.59993 11.3283C8.59172 11.4563 8.61649 11.5843 8.67187 11.7Z" fill="#75787B"/>
                  <path d="M16.5 21C9.05625 21 3 14.9438 3 7.50001C3.00281 6.22319 3.46919 4.99083 4.31243 4.03206C5.15566 3.07329 6.31837 2.45336 7.58437 2.28751C7.90711 2.25172 8.23284 2.31967 8.51435 2.4815C8.79586 2.64332 9.01851 2.89061 9.15 3.18751L11.0344 7.58438C11.1321 7.81261 11.1713 8.06163 11.1484 8.30885C11.1255 8.55607 11.0412 8.79364 10.9031 9.00001L9.34687 11.3813C10.0516 12.8114 11.2127 13.966 12.6469 14.6625L15 13.0969C15.2061 12.9579 15.4443 12.8738 15.692 12.8525C15.9397 12.8312 16.1888 12.8733 16.4156 12.975L20.8125 14.85C21.1094 14.9815 21.3567 15.2041 21.5185 15.4857C21.6803 15.7672 21.7483 16.0929 21.7125 16.4156C21.5467 17.6816 20.9267 18.8443 19.9679 19.6876C19.0092 20.5308 17.7768 20.9972 16.5 21ZM7.77187 3.77813C6.86714 3.89443 6.03582 4.33652 5.43356 5.02161C4.8313 5.70671 4.4994 6.58783 4.5 7.50001C4.50248 10.6818 5.76756 13.7326 8.01746 15.9825C10.2674 18.2324 13.3182 19.4975 16.5 19.5C17.4122 19.5006 18.2933 19.1687 18.9784 18.5665C19.6635 17.9642 20.1056 17.1329 20.2219 16.2281L15.825 14.3531L13.4812 15.9188C13.2656 16.0613 13.0168 16.1457 12.7589 16.1638C12.501 16.1819 12.2428 16.133 12.0094 16.0219C10.2628 15.177 8.84994 13.7708 7.99687 12.0281C7.8844 11.7955 7.83393 11.5378 7.85036 11.2799C7.86678 11.022 7.94955 10.7727 8.09062 10.5563L9.65625 8.17501L7.77187 3.77813Z" fill="#75787B"/>
                </svg>
                {t("enter_otp_sent_to")} {mobileNumber}
              </p>
              <OTPField
                count={4}
                onChange={(otp) => {
                  setFieldValue("mobileNumberOtp", otp);
                }}
              />
              <p
                className={`validation-otp-email-form-timer__info ${!mobileTimer && "timed-out"} ${disableResendMobileButton&&"disabled"}`}
                onClick={() => {
                  if (!mobileTimer) onResendCode(values, "mobile");
                }}
              >
                {mobileTimer
                ? t("resend_code_timer", {
                  timer: mobileTimer?.toString()?.padStart(2, "0"),
                })
                : t("resend_code_to_mobile")}
              </p>


              <ButtonComponent disabled={values?.emailOtp?.length !== 4||values?.mobileNumberOtp?.length!==4} className="validation-otp-email-form-button" htmlType="submit" size="wide" type="primary">
                {t("Verify")}
              </ButtonComponent>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ValidateOtpEmail;
