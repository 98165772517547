import React, { FC, useRef } from "react";
import "./mapsAutocompleteComponent.scss";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY ?? "");
Geocode.enableDebug();

interface MapsAutocompleteComponentProps {
  onSearch: (position: { lat: number; lng: number }) => void;
}

const MapsAutocompleteComponent: FC<MapsAutocompleteComponentProps> = (
  props
) => {
  const { onSearch } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className="maps-autocomplete-component">
      <Autocomplete
        ref={inputRef}
        onPlaceSelected={(data) => {
          if (
            data?.geometry?.location?.lat() &&
            data?.geometry?.location?.lng()
          ) {
            Geocode.fromLatLng(
              data?.geometry?.location?.lat()?.toString(),
              data?.geometry?.location?.lng()?.toString()
            ).then((response) => {
              onSearch(response?.results?.[0]?.geometry?.location);
              if (inputRef?.current?.value) inputRef.current.value = "";
            });
          }
        }}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        options={{
          types: ["establishment"],
        }}
      />
    </div>
  );
};

export default MapsAutocompleteComponent;
