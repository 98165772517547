import React, { FC } from "react";
import { BadgeTypesDefinition } from "../../../../../definitions/badgeTypesDefinition";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import { VendorModel } from "../../../../../models/Vendor/vendor.model";
import CardSkeletonComponent from "../../../../../shared/components/CardSkeletonComponent";
import IconComponent from "../../../../../shared/components/IconComponent";
import MenuComponent from "../../../../../shared/components/MenuComponent";
import "./vendorDetailsCard.scss";

interface VendorDetailsCardProps {
  vendorType: "approved" | "not-approved";
  editHandler?: () => void;
  blacklistHandler?: () => void;
  loading?: boolean;
  vendor: VendorModel | undefined;
}

const VendorDetailsCard: FC<VendorDetailsCardProps> = ({
  vendorType,
  editHandler,
  blacklistHandler,
  loading,
  vendor,
}) => {
  return loading ? (
    <CardSkeletonComponent />
  ) : (
    <div className="vendor-details-card">
      <div className="item">
        <div className="title">Monthly Trans. Volume</div>
        <div className="value">{vendor?.monthlyTransactionVolume}</div>
      </div>
      <div className="item">
        <div className="title">Max Credit / Order</div>
        <div className="value">{vendor?.maxCreditPerOrder}</div>
      </div>
      <div className="item">
        <div className="title">Processing Fee %</div>
        <div className="value">{vendor?.processingFee} {"%"}</div>
      </div>
    </div>
  );
};

export default VendorDetailsCard;
