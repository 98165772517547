import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { PaginationModel } from "../../models/pagination.model";
import {
  PurchaseListFilterModel,
  PurchaseListModel,
  PurchaseModel,
} from "../../models/Purchase/purchase.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

const PurchaseService = () => {
  const [purchases, setPurchases] = useState<PurchaseListModel[]>([]);
  const [purchase, setPurchase] = useState<PurchaseModel>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const [paginations, setPaginations] = useState<PaginationModel>()

  const fetchPurchases = (params?: PurchaseListFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    axiosInstance
      .get(ApiRoutes.PURCHASES, { params: _params })
      .then((response) => {
        const data = deserialize(
          PurchaseListModel,
          response?.data?.customer_purchases
        ) as PurchaseListModel[];
        const meta = deserialize(PaginationModel, response?.data?.meta)
        setPaginations(meta);
        setPurchases(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPurchase = (purchaseId: string) => {
    const API_URL = generatePath(ApiRoutes.PURCHASE, {
      purchaseId,
    });
    setLoading(true);
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          PurchaseModel,
          response?.data?.customer_purchase
        );
        setPurchase(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const challengePurchaseReturn = (purchaseId: string, onSuccess:Function) => {
    const API_URL = generatePath(ApiRoutes.PURCHASE_CHALLENGE_RETURN, {
      purchaseId,
    });
    setLoading(true);
    axiosInstance
      .put(API_URL, {
        "customer_purchase":{
          "purchase_status": "return_cancelled"
        }
      })
      .then((response) => {
        const data = deserialize(
          PurchaseModel,
          response?.data?.customer_purchase
        );
        setPurchase(data);
        onSuccess()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    purchases,
    purchase,
    fetchPurchases,
    fetchPurchase,
    challengePurchaseReturn,
    paginations
  };
};

export default PurchaseService;
