import { SortColumnTypes } from "../shared/types/SortColumn.type";

export const SortColumnTypeDefinitions = {
    [SortColumnTypes.CUSTOMER_NAME]: "customer_name",
    [SortColumnTypes.TOTAL_AMOUNT]: "total_amount",
    [SortColumnTypes.PURCHASE_STATUS]: "purchase_status",
    [SortColumnTypes.CUSTOMER_PAYMENT_STATUS]: "customer_payment_status",
    [SortColumnTypes.PURCHASE_ID]: "purchase_id",
    [SortColumnTypes.PURCHASE_DATE]: "purchase_date",
    [SortColumnTypes.VENDOR_NAME]: "vendor_name",
    [SortColumnTypes.VENDOR_ID]: "vendor_id",
    [SortColumnTypes.VENDOR_TYPE]: "vendor_type",
    [SortColumnTypes.VENDOR_STATUS]: "vendor_status",
    [SortColumnTypes.PAYMENT_DATE]: "payment_date",
    [SortColumnTypes.PAYMENT_AMOUNT]: "payment_amount",
    [SortColumnTypes.DEALL_SCORE]: "deall_score",
    [SortColumnTypes.MAX_PURCHASE_POWER]: "max_purchase_power",
    [SortColumnTypes.PROCESSING_FEE]: "processing_fee",
    [SortColumnTypes.CUSTOMER_STATUS]: "customer_status",
    [SortColumnTypes.USER_ID]: "user_id",
    [SortColumnTypes.USER_NAME]: "user_name",
    [SortColumnTypes.USER_ROLE]: "user_role",
    [SortColumnTypes.USER_STATUS]: "user_status",
    [SortColumnTypes.ONBOARDING_START_DATE]: "onboarding_start_date",
    [SortColumnTypes.SUBMITTED_ON]: "submitted_on",
    [SortColumnTypes.ASCEND]: "asc",
    [SortColumnTypes.DESCEND]: "desc",
    [SortColumnTypes.SETTLED_AT]: "settled_at",
    [SortColumnTypes.CREATED_AT]: "created_at",
    [SortColumnTypes.NUMBER_OF_ORDERS]: "no_of_orders",
    [SortColumnTypes.MINIMUM_DUE]: "min_amount",
    [SortColumnTypes.DUE_DATE]: "due_date",
    [SortColumnTypes.SETTLEMENT_NO_OF_ORDERS]: "settlement_no_of_orders",
    [SortColumnTypes.VENDOR_NO_OF_ORDERS]: "vendor_no_of_orders",
    [SortColumnTypes.SETTLEMENT_AMOUNT]: "settlement_amount",
    [SortColumnTypes.INSTORE_NAME]: "instore_name",
    [SortColumnTypes.INSTORE_NO_OF_ORDERS]: "instore_no_of_orders",
    [SortColumnTypes.VENDOR_SETTLEMENT_NO_OF_ORDERS]: "vendor_settlement_no_of_orders",
    [SortColumnTypes.VENDOR_SETTLEMENT_SETTLED_AT]: "vendor_settlement_settled_at"
}