import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { Link } from "react-router-dom";

import { Select } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import IconComponent from "../../../shared/components/IconComponent";
import { registerFormValidation } from "./registerFormValidation";
import { useTranslation } from "react-i18next";
import { VendorModel } from "../../../models/Vendor/vendor.model";
import MetaService from "../../../services/MetaService/meta.service";
import { countryCodesInitialValue, generateCountryCodeOptions } from "../../../shared/utils/generateCountryCodeOptions";
import "./registerForm.scss"

const RegisterForm = () => {
    const { error, loading, createVendor } = AuthService();
    const { getCountryCodesMeta, countryCodesMeta } = MetaService()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const initialValue = {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        countryCodeId: countryCodesInitialValue(countryCodesMeta),
        isdCode: "+20"
    };

    const onSubmit = (user: VendorModel) => {
        createVendor(user, () => {
            navigate({ pathname: AppRoutes.VALIDATEOTPEMAIL, search: `email=${user?.email}&mobile-number=${user?.mobileNumber}&country-code=${user?.countryCodeId}` },  {state: user?.email})
        })
    };

    useEffect(() => {
        getCountryCodesMeta()
    }, [])    

    return (
        <div className="register-form">
            <div className='form_heading create_heading'>
                <h1>
                    {t("create_new_vendor_account")}
                </h1>
            </div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={registerFormValidation}
                enableReinitialize
            >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                    <Form>
                        <div className='firstname'>
                            <InputField type="text" label='First Name' name="firstName" placeholder="Enter" />
                        </div>
                        <div className='lastname'>
                            <InputField type="text" label='Last Name' name="lastName" placeholder="Enter" />
                            <p className='para_info'><IconComponent name='icon-info-grey' />  {t("enter_full_last_name_as_stated_in_your_national_id")}</p>
                        </div>
                        <div className='email_part'>
                            <InputField type="email" label='Email' name="email" placeholder="Enter" />
                        </div>
                        <div className='mobile'>
                            <InputField
                                addonBefore={<Select
                                    onChange={(value:{ value: string, label: string[] }|string) => {
                                        if(typeof value !== "string"){
                                            setFieldValue("isdCode", value?.label?.[2])
                                            setFieldValue("countryCodeId", value?.value)
                                        }
                                        setFieldValue("mobileNumber", undefined)
                                    }}
                                    labelInValue
                                    value={values?.countryCodeId}>
                                    {generateCountryCodeOptions(countryCodesMeta)}
                                </Select>}
                                name="mobileNumber"
                                label="Mobile Number"
                                placeholder="Enter" />
                                {/* TODO: Needed for production */}
                            {/* <InputField prefix='+20' type="text" name="mobileNumber" label='Mobile Number' placeholder="Enter" /> */}
                        </div>
                        <div className='password_part'>
                            <InputField
                                type="password"
                                name="password"
                                placeholder="Enter"
                                label='Set Password'
                            />
                        </div>
                        <div className='login_button'>
                            <ButtonComponent type="primary"
                                htmlType="submit"
                                className="auth-form__btn"
                                size="wide"
                            > {t("verify_email_mobile")} </ButtonComponent>
                        </div>
                        <div className='have_account'>
                            <p>{t("already_have_an_account")} <Link to={AppRoutes.LOGIN}>{t("login_here")}</Link> </p>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default RegisterForm;