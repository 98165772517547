import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { PaginationModel } from "../../models/pagination.model";
import {
  PastSettlementListModel,
  SettlementModel,
  PendingSettlementModel,
} from "../../models/Settlement/settlement.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

export const SettlementService = () => {
  const [pendingSettlements, setPendingSettlements] = useState<
    PendingSettlementModel[]
  >([]);
  const [pastSettlements, setPastSettlements] = useState<
    PastSettlementListModel[]
  >([]);
  const [settlement, setSettlement] = useState<SettlementModel>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const [paginations, setPaginations] = useState<PaginationModel>()

  const fetchPendingSettlementList = (params?: SortFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    axiosInstance
      .get(ApiRoutes.PENDING_SETTLEMENTS, { params: _params })
      .then((response) => {
        const data = deserialize(
          PendingSettlementModel,
          response?.data?.vendor_settlements
        ) as PendingSettlementModel[];
        const meta = deserialize(PaginationModel, response?.data?.meta)
        setPaginations(meta);
        setPendingSettlements(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPastSettlementList = (params?: SortFilterModel) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    axiosInstance
      .get(ApiRoutes.PAST_SETTLEMENTS, { params: _params })
      .then((response) => {
        const data = deserialize(
          PastSettlementListModel,
          response?.data?.vendor_settlements
        ) as PastSettlementListModel[];
        const meta = deserialize(PaginationModel, response?.data?.meta)
        setPaginations(meta);
        setPastSettlements(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSettlement = (settlementId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.SETTLEMENT, {
      settlementId,
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          SettlementModel,
          response?.data?.vendor_settlement
        );
        setSettlement(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    pendingSettlements,
    pastSettlements,
    settlement,
    fetchPendingSettlementList,
    fetchPastSettlementList,
    fetchSettlement,
    paginations
  };
};
