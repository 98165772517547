export enum SortColumnTypes {
    TOTAL_AMOUNT = "total_amount",
    PURCHASE_STATUS = "purchase_status",
    CUSTOMER_PAYMENT_STATUS = "customer_payment_status",
    PURCHASE_ID = "purchase_id",
    PURCHASE_DATE = "purchase_date",
    VENDOR_NAME = "vendor_name",
    VENDOR_ID = "vendor_id",
    VENDOR_TYPE = "vendor_type",
    VENDOR_STATUS = "vendor_status",
    PAYMENT_DATE = "payment_date",
    PAYMENT_AMOUNT = "payment_amount",
    DEALL_SCORE = "deall_score",
    MAX_PURCHASE_POWER = "max_purchase_power",
    PROCESSING_FEE = "processing_fee",
    CUSTOMER_STATUS = "customer_status",
    CUSTOMER_NAME = "customer_name",
    USER_ID = "user_id",
    USER_NAME = "user_name",
    USER_ROLE = "user_role",
    USER_STATUS = "user_status",
    ONBOARDING_START_DATE = "onboarding_start_date",
    SUBMITTED_ON = "submitted_on",
    ASCEND = "ascend",
    DESCEND = "descend",
    SETTLED_AT = "settled_at",
    NUMBER_OF_ORDERS = "no_of_orders",
    CREATED_AT = "created_at",
    MINIMUM_DUE = "min_amount",
    DUE_DATE = "due_date",
    SETTLEMENT_NO_OF_ORDERS = "settlement_no_of_orders",
    VENDOR_NO_OF_ORDERS = "vendor_no_of_orders",
    SETTLEMENT_AMOUNT = "settlement_amount",
    INSTORE_NAME = "instore_name",
    INSTORE_NO_OF_ORDERS = "instore_no_of_orders",
    VENDOR_SETTLEMENT_NO_OF_ORDERS = "vendor_settlement_no_of_orders",
    VENDOR_SETTLEMENT_SETTLED_AT = "vendor_settlement_settled_at",
}