import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { Link } from "react-router-dom";

import { Button } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { AuthContext } from "../../../context/AuthContext";
import { DownloadOutlined } from '@ant-design/icons';
import ButtonComponent from "../../../shared/components/ButtonComponent";
import UnderVerificationImg from "../../../assets/images/hourglass-with-pink-sand.png";
import "./accountverify.scss"
import { useTranslation } from "react-i18next";
import IconComponent from "../../../shared/components/IconComponent";


interface User {
    email: string;

}

const initialValue = {
    email: ""
};
const AccountVerify = () => {
    const navigate = useNavigate()
    const {t}= useTranslation()
    const onSubmit = (user: User) => {
        navigate(AppRoutes.LOGIN)
    };
    return (
        <div className="account-verify-wrapper">
           <p className="all_set">{t("all_set")}</p>
            <div className='form_heading'>
                <h1>
                    {t('your_account_is_created_and_being_verified')}
                </h1>
            </div>
            
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}

            >
                <Form>
                    <div className="password_reset_link_div">
                        <img src={UnderVerificationImg} className="verify_account_img" />
                    </div>
                    <div className="reset_info">
                        <p>{t("you_will_recieve_a_mail_once_verified")}</p>
                    </div>

                    <ButtonComponent type="primary"
                        htmlType="submit"
                        className="auth-form__btn"
                        size="wide"
                    > <IconComponent name="icon-logout-blue" /> {t("logout")} </ButtonComponent>


                </Form>
            </Formik>
        </div>
    )
}

export default AccountVerify;