import { Checkbox, Col, Row } from 'antd'
import { Form, Formik, FormikValues } from 'formik'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SupportDocuments } from '../../../models/Vendor/vendor.model'
import { AppRoutes } from '../../../routes/routeConstants/appRoutes'
import AuthService from '../../../services/AuthService/auth.service'
import S3AttachmentService from '../../../services/S3UploadService/s3upload.service'
import ButtonComponent from '../../../shared/components/ButtonComponent'
import UploadComponent from '../../../shared/components/UploadComponent'
import { supportDocumentsValidation } from './supportDocumentsValidation'

interface SupportDocumentsFormProps {
  nextHandler: any
}

const SupportDocumentsForm:FC<SupportDocumentsFormProps> = (props) => {
  const { nextHandler } = props
  const { loading, error, updateDocuments } = AuthService()
  const { uploadAttachment, loading: attachmentLoading } = S3AttachmentService()
  const {t} = useTranslation()
  const onSubmit = (values:FormikValues) => {
    updateDocuments(values, ()=>{
      nextHandler()
    })
   }
  const initialValues = {
    ...new SupportDocuments(),
    agreeToTC: false
  }
  return (
    <div className='support-documents-form__wrapper'>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={supportDocumentsValidation}
      >
        {({ values, setFieldValue, isValid, dirty, errors }) => (
          <Form className='support-documents-form'>
            <Row>
              <h2 className='business-details-form--header'>
                {t("support_documents")}
              </h2>
              <Col span={24}>
                <div className="business-details-form--field">
                  <label className='label'>{t("commercial_registration")}</label>
                  <UploadComponent
                    viewType='modal'
                    fileId={values?.commercialRegistrationId??""}
                    fileUrl={values?.commercialRegistration?.s3Url}
                    fileName={values?.commercialRegistration?.filename??""}
                    fileType={values?.commercialRegistration?.fileType??""}
                    type="document"
                    title={"Upload Document"}
                    accept={"image/png, image/jpg, image/jpeg, application/pdf"}
                    onUpload={async (file, fileUrl) => {
                      setFieldValue("commercialRegistration.s3Url", fileUrl);
                      setFieldValue("commercialRegistration.filename", file?.name);
                      setFieldValue("commercialRegistration.fileType", file?.type);
                      const _commercialRegistration = await uploadAttachment(file);
                      setFieldValue("commercialRegistrationId", _commercialRegistration);
                    }}
                    onRemove={() => {
                      setFieldValue("commercialRegistration.s3Url", undefined);
                      setFieldValue("commercialRegistrationId", undefined);
                    }}
                    className="upload_return_reciept"
                    error={errors?.commercialRegistrationId}
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <label className='label'>{t("tax_card")}</label>
                  <UploadComponent
                    viewType='modal'
                    fileId={values?.taxCardId??""}
                    fileUrl={values?.taxCard?.s3Url}
                    fileName={values?.taxCard?.filename??""}
                    fileType={values?.taxCard?.fileType??""}
                    type="document"
                    title={"Upload Document"}
                    accept={"image/png, image/jpg, image/jpeg, application/pdf"}
                    onUpload={async (file, fileUrl) => {
                      setFieldValue("taxCard.s3Url", fileUrl);
                      setFieldValue("taxCard.filename", file?.name);
                      setFieldValue("taxCard.fileType", file?.type);
                      const _taxCard = await uploadAttachment(file);
                      setFieldValue("taxCardId", _taxCard);
                    }}
                    onRemove={() => {
                      setFieldValue("taxCard.s3Url", undefined);
                      setFieldValue("taxCardId", undefined);
                    }}
                    className="upload_return_reciept"
                    error={errors?.taxCardId}
                  />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                <Checkbox className='tnc-text' checked={values?.agreeToTC} onChange={(e)=>setFieldValue("agreeToTC",e.target.checked)}>
                  {t("agree_to")} <a className='tnc-link' href={window.location.origin+AppRoutes.TERMS_AND_CONDITIONS} target="_blank">{t("t&c")}</a>
                  <p className='tc-note'>({t("t&c_includes_privacy_policy")}).</p>
                </Checkbox>
                <p className='input__error'>{errors?.agreeToTC}</p>
                </div>
              </Col>

              <Col span={24}>
                <div className="business-details-form--field documents-next-button">
                  <ButtonComponent
                    disabled={loading || attachmentLoading}
                    type="primary"
                    htmlType="submit"
                    className="auth-form__btn"
                    size="wide"
                  > {t("next")} </ButtonComponent>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SupportDocumentsForm