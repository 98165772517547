import React, { FC } from "react";
import CardSkeletonComponent from "../CardSkeletonComponent";
import "./cardComponent.scss";

interface CardComponentProps {
  title?: string|null;
  className?: string;
  loading?: boolean;
}

const CardComponent: FC<CardComponentProps> = (props) => {
  const { title, className, children, loading } = props;

  return loading ? (
    <CardSkeletonComponent />
  ) : (
    <div className={`card-component ${className}`}>
      <div className="card-component-content">{children}</div>
    </div>
  );
};

export default CardComponent;
