import * as Yup from "yup";
import { REGEX } from "../../../../enums/regex";
export const vendorFormValidation = Yup.object().shape({
  vendorDetails: Yup.object().shape({
    name: Yup.string()
      .required('Business name is required'),
    logoId: Yup.string()
      .required('Logo is required'),
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
    mobileNumber: Yup.string()
      .required('Phone number is required.')
      .when("countryCode.isdCode", {
        is: "+20",
        then: Yup.string()
          .matches(REGEX.PHONE.match, REGEX.PHONE.error)
      })
      .when("countryCode.isdCode", {
        is: "+972",
        then: Yup.string()
          .matches(REGEX.PHONE_ISRAEL.match, REGEX.PHONE_ISRAEL.error)
      })
      .when("countryCode.isdCode", {
        is: "+91",
        then: Yup.string()
          .matches(REGEX.PHONE_INDIA.match, REGEX.PHONE_INDIA.error)
      })
      .when("countryCode.isdCode", {
        is: "+1",
        then: Yup.string()
          .matches(REGEX.PHONE_US.match, REGEX.PHONE_US.error)
      }),
    categoryId: Yup.string()
      .required('Category is required'),
      // TODO required for next phase
    // subCategoryId: Yup.string()
    //   .required('Sub Category is required'),
    type: Yup.string()
      .required('Type is required'),
    monthlyTransactionVolume: Yup.number()
      .positive()
      .nullable()
      .required('Monthly transaction volume is required'),
    maxCreditPerOrder: Yup.number(),
  }),
  contactPersonDetails: Yup.object().shape({
    firstName: Yup.string()
      .matches(REGEX.NAME.match, REGEX.NAME.error)
      .required('First name is required'),
    lastName: Yup.string()
      .matches(REGEX.NAME.match, REGEX.NAME.error)
      .required('Last name is required'),
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
    mobileNumber: Yup.string()
      .required('Phone number is required.')
      .when("countryCode.isdCode", {
        is: "+20",
        then: Yup.string()
          .matches(REGEX.PHONE.match, REGEX.PHONE.error)
      })
      .when("countryCode.isdCode", {
        is: "+972",
        then: Yup.string()
          .matches(REGEX.PHONE_ISRAEL.match, REGEX.PHONE_ISRAEL.error)
      })
      .when("countryCode.isdCode", {
        is: "+91",
        then: Yup.string()
          .matches(REGEX.PHONE_INDIA.match, REGEX.PHONE_INDIA.error)
      })
      .when("countryCode.isdCode", {
        is: "+1",
        then: Yup.string()
          .matches(REGEX.PHONE_US.match, REGEX.PHONE_US.error)
      }),
  }),
  paymentDetails: Yup.object().shape({
    accountHolderName: Yup.string()
      .matches(REGEX.NAME.match, REGEX.NAME.error)
      .required('Account holder name is required'),
    address: Yup.string()
      .required('Address is required'),
    accountNumber: Yup.string()
      .required('Account number is required'),
    iban: Yup.string()
      .required('iban is required'),
    swiftCode: Yup.string()
      .required('Swift code is required'),
    branch: Yup.string()
      .required('Branch is required')
    // TODO - Required for e-wallet integration
    // walletNumber: Yup.string()
    //   .typeError("Wallet number should be a alphanumeric"),
    // walletTypeId: Yup.string()
    //   .when("walletNumber", {
    //     is: (walletNumber) => walletNumber?.length > 0,
    //     then: Yup.string().required('Wallet type is required').typeError("Select a valid Wallet type")
    //   })
  }),
  supportDocuments: Yup.object({
    commercialRegistrationId: Yup.string()
      .required('Commercial registration is required'),
    taxCardId: Yup.string()
      .required('Tax card is required'),
  })
});
