import axios from 'axios';
import { deserialize } from 'serializr';
import { NotificationTypes } from '../enums/notificationTypes';
import { TokenModel, VendorModel } from '../models/Vendor/vendor.model';
import {ApiRoutes} from "../routes/routeConstants/apiRoutes";
import { AppRoutes } from '../routes/routeConstants/appRoutes';
import Notification from '../shared/components/Notification';
import { LocalStorage } from '../shared/utils/localStorage';

export const getHeaders = (): any => {
    let headers, token;
    if (LocalStorage.getItem("ACCESS_TOKEN")) {
        token = LocalStorage.getItem("ACCESS_TOKEN");
    }
    headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 20000,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status
        }
    },
    (error) => {
        const { response } = error;
        if (response?.status === 401) {
              const originalRequest = error?.config;
              originalRequest._retry = true;
              const refreshToken = LocalStorage.getItem("REFRESH_TOKEN");
              const vendor_user = {
                  grant_type: "refresh_token",
                  refresh_token: refreshToken,
              };
              return axios
                  .post(`${ApiRoutes.BASE_URL}${ApiRoutes.LOGIN}`, {vendor_user})
                  .then((response) => {
                      if (response.status >= 200 && response.status <= 299) {
                          const userDetails = deserialize(VendorModel, response.data["vendor_user"]);
                          const token = deserialize(TokenModel, response.data["token"]);
                          LocalStorage.setItem("VENDOR",userDetails)
                          LocalStorage.setItem("ACCESS_TOKEN", token?.accessToken);
                          LocalStorage.setItem("REFRESH_TOKEN", token?.refreshToken);
                          axios.defaults.headers.common["Authorization"] =
                              "bearer " + token?.accessToken;
                          originalRequest.headers["Authorization"] =
                              "bearer " + token?.accessToken;
                          return axios(originalRequest);
                      }
                  })
                  .catch((error) => {
                      localStorage.clear();
                      window.location.replace(AppRoutes.LOGIN);
                  });
          }
        else if (response?.status === 422) {
            Notification({
                message: response?.data?.error,
                description: "Please try again!",
                type: NotificationTypes.ERROR,
            });
        } else if (response?.status === 500) {
            Notification({
                message: "Error 500",
                description: "Oops, something went wrong.",
                type: NotificationTypes.ERROR,
            });
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
