import { Col, Row } from 'antd';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom';
import { currencyString } from '..';
import { BadgeTypesDefinition } from '../../../definitions/badgeTypesDefinition';
import { BadgeTypes } from '../../../enums/badgeTypes';
import { AppRoutes } from '../../../routes/routeConstants/appRoutes';
import BadgeComponent from '../../../shared/components/BadgeComponent';
import DatePickerField from '../../../shared/components/DatePickerField';
import DropdownField from '../../../shared/components/DropdownField';
import IconComponent from '../../../shared/components/IconComponent';
import InputField from '../../../shared/components/InputField';
import PageView from '../../../shared/components/PageView'
import TableComponent, { TableFilterComponent, TableFilterDropdownComponent } from '../../../shared/components/TableComponent';
import { tableSorter } from '../../../shared/utils/tableSorter';
import "./purchases.scss"
import NoPurchasesImg from "../../../assets/empty/purchases.svg";
import { deserialize, serialize } from 'serializr';
import { PurchaseListFilterModel, PurchaseListModel } from '../../../models/Purchase/purchase.model';
import { VendorTypes } from '../../../enums/vendorTypes';
import { VendorTypesDefinition } from '../../../definitions/vendorTypesDefinition';
import PurchaseService from '../../../services/PurchaseService/purchase.service';
import { removeUndefined } from '../../../shared/utils/removeUndefinied';
import { useTranslation } from 'react-i18next';
import { SortFilterModel } from '../../../models/SortFilterModel/SortFilterModel.model';
import { SortColumnTypes } from '../../../shared/types/SortColumn.type';

const Purchases = () => {
  const navigate = useNavigate()
  const [isFilterShown, setIsFilterShown] = useState(false);
  const { loading, fetchPurchases, purchases, paginations } = PurchaseService();
  const [filters, setFilters] = useState<PurchaseListFilterModel>({});
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const {t} = useTranslation()

  const handleFilterSubmit = (values: SortFilterModel) => {
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  const filterInitialValues = new PurchaseListFilterModel();
  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const handlePageChange =(page:number)=> {
    setPage(page)
    fetchPurchases({page, ...filters, ...sortOptions})
  }

  useEffect(() => {
    fetchPurchases({...filters, ...sortOptions});
  }, [filters]);

  useEffect(() => {
    fetchPurchases()
  }, [])

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm, errors }) => (
          <TableFilterComponent
          applyHandler={handleSubmit}
          clearHandler={() => {
            handleFilterClose();
            setFilters(() => ({
              ...filterInitialValues,
              search: filters?.search,
            }));
            resetForm({});
          }}
          >
            <Form>
              <Row gutter={16}>
                <Col span={12} className="purchases-filter-field">
                  <DatePickerField
                    name="purchaseDate"
                    placeholder="Enter"
                    label="Purchase Date"
                    value={values?.createdAt}
                    onChange={(date, dateString) => {
                      setFieldValue("createdAt", dateString);
                    }}
                  />
                </Col>
                <Col span={12} className="purchases-filter-field">
                  <DropdownField
                    label="Purchase Status"
                    options={[
                      {
                        value: BadgeTypes.COMPLETED,
                        label: BadgeTypesDefinition[BadgeTypes.COMPLETED],
                      },
                      {
                        value: BadgeTypes.FAILED,
                        label: BadgeTypesDefinition[BadgeTypes.FAILED],
                      },
                      {
                        value: BadgeTypes.PARTIAL_RETURN,
                        label: BadgeTypesDefinition[BadgeTypes.PARTIAL_RETURN],
                      },
                      {
                        value: BadgeTypes.RETURN,
                        label: BadgeTypesDefinition[BadgeTypes.RETURNED],
                      },
                    ]}
                    name="purchaseStatus"
                    placeholder="Enter"
                    onChange={(value) => setFieldValue("purchaseStatus", value)}
                    value={values?.purchaseStatus}
                  />
                </Col>
                <Col span={12} className="purchases-filter-field">
                  <InputField
                    name="customerName"
                    placeholder="Enter"
                    label="Customer Name"
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );

  return (
    <PageView
      title={{
        name: t("purchases"),
        icon: <IconComponent name="icon-purchases" />,
      }}
    >
      <div className="purchases">
        <TableComponent
        paginationMeta={paginations}
        handlePageChange={handlePageChange}
        onChange={fetchPurchases}
        onSort={(data)=>{
         setSortOptions(data)
        }}
        filtersSorts={{page, ...filters, search}}
          search={{
            placeholder: t("search_purchase_by_id"),
            onSearch: (value) => {
              setSearch(value)
              handleFilterSubmit({ search: value });
            },
          }}
          filter={filter}
          loading={loading}
          columns={[
            {
              title: t("purchase_id"),
              dataIndex: "purchaseId",
              key: SortColumnTypes.PURCHASE_ID,
              sorter: true,
              render: (text, record) => (
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(
                      generatePath(AppRoutes.PURCHASE_VIEW, {
                        purchaseId: record?.id,
                      })
                    )
                  }
                >
                  {" "}
                  {text}
                </span>
              ),
            },
            {
              title:t("purchase_date"),
              dataIndex: "createdAt",
              key: SortColumnTypes.PURCHASE_DATE,
              render: (text) => moment(text)?.format("DD MMM YYYY"),
              sorter: true,
            },
            {
              title:"Purchase Time",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (text) => moment(text)?.local().format("HH:mm"),
            },
            {
              title: "Customer",
              dataIndex: "customerName",
              key: "customerName",
            },
            {
              title: t("mode"),
              dataIndex: "vendor",
              key: "vendor",
              render: (record: any) => record?.mode == VendorTypes.ONLINE ? VendorTypesDefinition[VendorTypes.ONLINE] : record?.instore?.name,
            },
            {
              title: t("items_total"),
              dataIndex: "totalAmount",
              key: SortColumnTypes.TOTAL_AMOUNT,
              render: (text, record) => (
                <span className="vendor__name">
                  {currencyString}
                  {text}
                </span>
              ),
              sorter: true,
            },
            {
              title: t("purchase_status"),
              dataIndex: "purchaseStatus",
              key: SortColumnTypes.PURCHASE_STATUS,
              render: (text) => <BadgeComponent type={text === BadgeTypes.RETURN ? BadgeTypes.RETURNED : text} />,
              sorter: true,
            },
          ]}
          data={purchases}
          empty={{
            img: NoPurchasesImg,
            text: "No purchases",
          }}
        />
      </div>
    </PageView>
  )
}

export default Purchases