export const generateLabelValues = (label: string, value: string, items: any[]) => {
    const _options = items.map((item, index)=> {
        return {
            ...item,
            label: item[label],
            value: item[value]
        }
    })
    _options.unshift({label:"All Instores", value: ""})
    return _options
}