import React, { FC, Fragment, ReactNode } from "react";
import { Field, ErrorMessage, FormikProps, useFormikContext, FieldAttributes } from "formik";
import { Input } from "antd";
import Error from "../Error";
import "./inputField.scss";
interface InputFieldProps {
  type?: "email" | "password" | "number" | "text";
  name: string;
  placeholder: string;
  label?: string;
  className?: string;
  suffix?: ReactNode;
  prefix?: ReactNode;
  addonBefore?: ReactNode;
  onChange?: (value: string) => void;
  disabled?: boolean
}

const InputField: FC<InputFieldProps> = (props) => {
  const { name, label, className, type = "text", onChange, ...rest } = props;
  const { setFieldValue } = useFormikContext()
  return (
    <Fragment>
      <Field name={name}>
        {({ field, form: { touched, errors }, meta }:FieldAttributes<any>) => (
          <div className={`input-field ${(meta?.error && meta.touched) && "error"} ${className}`}>
            {label && <label>{label}</label>}
            <Input autoComplete="new-password" data-testid={name} type={type} {...rest}  {...field} onChange={(e) => onChange ? onChange(e.target.value) : setFieldValue(name, e.target.value)} />
          </div>
        )}
      </Field>
      <ErrorMessage name={name}>
        {(message: string) =>
          <Error
            message={message}
            testId={`input-error-${name}`}
          />
        }
      </ErrorMessage>
    </Fragment>
  );
};

export default InputField;
