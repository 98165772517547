import { ChartData } from 'chart.js'
import React, { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import "./barChart.scss"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const barChartAxisColor = "#7886A9"

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
        },
    },
    scales: {
        y: {
          title: {
            display: true,
            text: 'LE VALUE',
            color: barChartAxisColor
          },
          grid: {
            color: "#E4E9F2",
          },
        },
        x: {
          title: {
            display: true,
            text: 'TIMELINE',
            color: barChartAxisColor
          },
          grid: {
            color: "#E4E9F2",
          },
        }
    },
    borderRadius: 10,
    barThickness: 20,
    colours: [ { fillColor: '#000' }]
};

interface BarChartProps {
    chartData: {
        labels: string[];
        datasets: {
            data: number[];
            backgroundColor: string[];
        }[];
    }
}

const BarChart: FC<BarChartProps> = (props) => {

    const { chartData } = props

    return (
        <div className="bar-chart">
            <Bar options={options} data={chartData} />
        </div>
    )
}

export default BarChart