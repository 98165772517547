export enum VendorTypes  {
    ONLINE = "online",
    BOTH = "both",
    INSTORE = "offline"
}

export enum VendorUsers {
    VENDOR = "vendor",
    VENDOR_USER = "vendor_user",
    VENDOR_INTERNAL_USER = "vendor_internal_user"
}

export enum VendorProfileStatusTypes {
    APPROVED = "approved",
    REJECTED = "rejected",
    INACTIVE = "inactive",
    CREATED = "created",
    OTP_VERIFIED = "otp_verified",
    BUSINESS_INFO = "business_info",
    CONTACT_INFO = "contact_info",
    WAITING_FOR_APPROVAL = "waiting_for_approval"
}