import { Col, Row } from 'antd';
import { ChartData } from 'chart.js';
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { deserialize } from 'serializr';
import CardSkeletonComponent from '../../../shared/components/CardSkeletonComponent';
import IconComponent from '../../../shared/components/IconComponent';
import PageView from '../../../shared/components/PageView'
import BarChart from './BarChart';
import "./dashboard.scss"
import { Dashboard as DashboardModel } from "./../../../models/Dashboard/dashboard.model"
import DashboardService from '../../../services/DashboardService/dashboard.service';
import VendorService from '../../../services/VendorService/vendor.service';
import { generateLabelValues } from '../../../shared/utils/generateLabelValues';
import { useLocation } from 'react-router-dom';

interface DashboardProps { }

const Dashboard: FC<DashboardProps> = (props) => {
  const { fetchDashboardKeyMetrics, dashboardKeyMetrics, keyMetricsLoading, fetchDashboardPurchaseCount, fetchDashboardPurchaseValue, purchaseCountGraphData, purchaseValueGraphData } = DashboardService()
  const { fetchVendorInstoreList, vendorInstores } = VendorService()
  const location = useLocation()
  const [instoreFilters, setInstoreFilters] = useState<any>()
  const { } = props
  const { t } = useTranslation()

  const makeApiCalls = (params: { instoreId: string, dateFilterKey: string, startDate: string, endDate: string }) => {
    fetchDashboardKeyMetrics(params)
    fetchDashboardPurchaseCount(params)
    fetchDashboardPurchaseValue(params)
  }

  useEffect(() => {
    fetchDashboardKeyMetrics({})
    fetchDashboardPurchaseCount({})
    fetchDashboardPurchaseValue({})
    fetchVendorInstoreList()
  }, [])

  return (
    <PageView type='dashboard' title={{
      name: t("dashboard"),
      icon: <IconComponent name="icon-dashboard" />,
    }}
      onFilterChange={(filter: { selectedFilter: string, selectedInstore: string }) => {
        const startDate = filter.selectedFilter.includes('/') ? filter.selectedFilter.split(' - ')[0] : ""
        const endDate = filter.selectedFilter.includes('/') ? filter.selectedFilter.split(' - ')[1] : ""
        makeApiCalls({ instoreId: filter?.selectedInstore, dateFilterKey: filter.selectedFilter.includes('/') ? "custom" : filter?.selectedFilter, startDate, endDate })
      }}>
      <div className="dashboard-view">
        {keyMetricsLoading ? (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <CardSkeletonComponent
                className="dashboard__container-skeleton metrics"
                rows={1}
              />
            </Col>
            <Col span={12}>
              <CardSkeletonComponent
                className="dashboard__container-skeleton graph"
                rows={4}
              />
            </Col>
            <Col span={12}>
              <CardSkeletonComponent
                className="dashboard__container-skeleton graph"
                rows={4}
              />
            </Col>
          </Row>
        ) : (
          <div>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row className='dashboard__container header'>
                  <Col span={5}>
                    <Row align="top" className='header-card blue'>
                      <div className="image">
                        <div className="icon__container">
                          <IconComponent name="icon-purchases" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">{t("#_of_purchases")}</div>
                        <div className="value">{dashboardKeyMetrics?.numberOfPurchases ?? 0}</div>
                      </div>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row align="top" className='header-card violet'>
                      <div className="image">
                        <div className="icon__container">
                          <IconComponent name="icon-coin" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">{t("total_purchases")}</div>
                        <div className="value">{`LE ${dashboardKeyMetrics?.totalPurchaseAmount ?? 0}`}</div>
                      </div>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row align="top" className='header-card yellow'>
                      <div className="image">
                        <div className="icon__container">
                          <IconComponent name="icon-settlement" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">Pending Settlements</div>
                        <div className="value">{`LE ${dashboardKeyMetrics?.totalPendingSettlementAmount ?? 0}`}</div>
                      </div>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Row align="top" className='header-card brown'>
                      <div className="image">
                        <div className="icon__container">
                          <IconComponent name="icon-refund-dashboard-icon" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">{t("total_refunds")}</div>
                        <div className="value">{`LE ${dashboardKeyMetrics?.totalRefundAmount ?? 0}`}</div>
                      </div>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <Row align="top" className='header-card green'>
                      <div className="image">
                        <div className="icon__container">
                          <IconComponent name="icon-vendor" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">{t("active_instores")}</div>
                        <div className="value">{dashboardKeyMetrics?.numberOfActiveInstores ?? 0}</div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={12} >
                <div className="dashboard__container">
                  <div className="title">{t("#_of_purchases_of_instore")}</div>
                  <div className="graph">
                    {
                      purchaseCountGraphData &&
                      <BarChart chartData={purchaseCountGraphData} />
                    }
                  </div>
                </div>
              </Col>
              <Col span={12} >
                <div className="dashboard__container">
                  <div className="title">{t("value_of_purchases_of_instore")}</div>
                  <div className="graph">
                    {
                      purchaseValueGraphData &&
                      <BarChart chartData={purchaseValueGraphData} />
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </PageView>
  )
}

export default Dashboard