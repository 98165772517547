import React, { FC } from "react";
import "./iconComponent.scss";

interface IconComponentProps {
  name:
    | "icon-customers"
    | "icon-info"
    | "icon-info-grey"
    | "icon-search"
    | "icon-search-blue"
    | "icon-vendors"
    | "icon-purchases"
    | "icon-payments"
    | "icon-user-profile"
    | "icon-user-profile-blue"
    | "icon-user-profile-black"
    | "icon-edit"
    | "icon-close"
    | "icon-back"
    | "icon-bell"
    | "icon-bell-blue"
    | "icon-ellipsis"
    | "icon-email"
    | "icon-email-blue"
    | "icon-phone"
    | "icon-phone-blue"
    | "icon-phone-grey"
    | "icon-document"
    | "icon-document-blue"
    | "icon-upload"
    | "icon-upload-black"
    | "icon-filter"
    | "icon-filter-blue"
    | "icon-camera"
    | "icon-trash"
    | "icon-trash-black"
    | "icon-blacklist"
    | "icon-star"
    | "icon-map"
    | "icon-map-black"
    | "icon-map-blue"
    | "icon-down"
    | "icon-down-grey"
    | "icon-calender"
    | "icon-calender-black"
    | "icon-user-config"
    | "icon-setting"
    | "icon-dashboard"
    | "icon-settlements"
    | "icon-password"
    | "icon-password-black"
    | "icon-logout"
    | "icon-logout-blue"
    | "icon-language-grey"
    | "icon-language-black"
    | "icon-external-brand"
    | "icon-external-blue"
    | "icon-coin"
    | "icon-refund-dashboard-icon"
    | "icon-vendor"
    | "icon-vendor-primary"
    | "icon-edit-black"
    | "icon-edit-blue"
    | "icon-edit-white"
    | "icon-close-black"
    | "icon-minus-white"
    | "icon-contact-us"
    | "icon-contact-us-blue"
    | "icon-vendor-blue"
    | "icon-calender-blue"
    | "icon-settlement";
  onClick?: () => void;
  pointer?: boolean;
}

const IconComponent: FC<IconComponentProps> = (props) => {
  const { name, onClick, pointer } = props;
  const renderIcon = () => {
    switch (name) {
      case "icon-contact-us": {
        return (
          <i className="icon-contact-us4">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
          </i>
        );
      }
      case "icon-contact-us-blue": {
        return (
          <i className="icon-contact-us5">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
          </i>
        );
      }
      case "icon-vendor-primary": {
        return (
          <i className="icon-vendor3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-vendor-blue": {
        return (
          <i className="icon-vendor5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-vendor": {
        return (
          <i className="icon-vendor">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-refund-dashboard-icon": {
        return (
          <i className="icon-refund-dashboard-icon"/>
        );
      }
      case "icon-coin": {
        return (
          <i className="icon-coin">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-external-brand": {
        return (
          <i className="icon-external-link4">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-external-blue": {
        return (
          <i className="icon-external-link5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-language-grey": {
        return (
          <i className="icon-language3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-language-black": {
        return (
          <i className="icon-language3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }

      case "icon-dashboard": {
        return (
          <i className="icon-dashboard3">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
            <span className="path4"/>
            <span className="path5"/>
            <span className="path6"/>
            <span className="path7"/>
            <span className="path8"/>
          </i>
        );
      }
      case "icon-customers": {
        return (
          <i className="icon-customers">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </i>
        );
      }
      case "icon-password": {
        return (
          <i className="icon-change-password4">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </i>
        );
      }
      case "icon-password-black": {
        return (
          <i className="icon-change-password3">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </i>
        );
      }
      case "icon-info": {
        return (
          <i className="icon-info">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
            <span className="path4"/>
          </i>
        );
      }
      case "icon-info-grey": {
        return (
          <i className="icon-info2">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
            <span className="path4"/>
          </i>
        );
      }
      case "icon-search": {
        return (
          <i className="icon-search4">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-search-blue": {
        return (
          <i className="icon-search5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-settlements": {
        return (
          <i className="icon-settlement3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-vendors": {
        return (
          <i className="icon-vendor3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-purchases": {
        return (
          <i className="icon-purchase3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-payments": {
        return (
          <i className="icon-payment">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
            <span className="path4"/>
          </i>
        );
      }
      case "icon-user-profile": {
        return (
          <i className="icon-user-profile">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-user-profile-blue": {
        return (
          <i className="icon-user-profile5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-user-profile-black": {
        return (
          <i className="icon-user-profile3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-calender": {
        return (
          <i className="icon-calender">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-calender-black": {
        return (
          <i className="icon-calender3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-calender-blue": {
        return (
          <i className="icon-calender5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-edit": {
        return (
          <i className="icon-edit4">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-edit-black": {
        return (
          <i className="icon-edit3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-edit-blue": {
        return (
          <i className="icon-edit5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-edit-white": {
        return (
          <i className="icon-edit">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-filter": {
        return (
          <i className="icon-filter3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-filter-blue": {
        return (
          <i className="icon-filter5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-close": {
        return <i className="icon-close"/>;
      }
      case "icon-close-black": {
        return <i className="icon-close3"/>;
      }
      case "icon-bell": {
        return (
          <i className="icon-bell">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-bell-blue": {
        return (
          <i className="icon-bell5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-back": {
        return <i className="icon-back3"/>;
      }
      case "icon-logout": {
        return <i className="icon-logout"/>;
      }
      case "icon-logout-blue": {
        return <i className="icon-logout5"/>;
      }
      case "icon-down": {
        return <i className="icon-dropdown-arrow3"/>;
      }
      case "icon-down-grey": {
        return <i className="icon-dropdown-arrow2"/>;
      }
      case "icon-ellipsis": {
        return <i className="icon-more"/>;
      }
      case "icon-upload": {
        return <i className="icon-upload"/>;
      }
      case "icon-upload-black": {
        return <i className="icon-upload3"/>;
      }
      case "icon-email": {
        return (
          <i className="icon-email4">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-email-blue": {
        return (
          <i className="icon-email5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-phone": {
        return (
          <i className="icon-phone4">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-phone-blue": {
        return (
          <i className="icon-phone5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-phone-grey": {
        return (
          <i className="icon-phone2">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-document": {
        return (
          <i className="icon-document3">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-document-blue": {
        return (
          <i className="icon-document5">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-camera": {
        return (
          <i className="icon-camera">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
          </i>
        );
      }
      case "icon-map": {
        return (
          <i className="icon-map">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
          </i>
        );
      }
      case "icon-map-black": {
        return (
          <i className="icon-map3">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
          </i>
        );
      }
      case "icon-map-blue": {
        return (
          <i className="icon-map5">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
          </i>
        );
      }
      case "icon-trash": {
        return (
          <i className="icon-trash">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
            <span className="path4"/>
          </i>
        );
      }
      case "icon-trash-black": {
        return (
          <i className="icon-trash3">
            <span className="path1"/>
            <span className="path2"/>
            <span className="path3"/>
            <span className="path4"/>
          </i>
        );
      }
      case "icon-blacklist": {
        return (
          <i className="icon-blacklist">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-star": {
        return (
          <i className="icon-star">
            <span className="path1"/>
            <span className="path2"/>
          </i>
        );
      }
      case "icon-user-config": {
        return (
          <i className="icon-user-config3">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </i>
        );
      }
      case "icon-setting": {
        return (
          <i className="icon-setting">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </i>
        );
      }
      case "icon-minus-white": {
        return (
          <i className="icon-minus">
            <span className="path1" />
            <span className="path2" />
          </i>
        );
      }
      case "icon-settlement": {
        return (
          <i className="icon-settlement">
            <span className="path1" />
            <span className="path2" />
          </i>
        );
      }
      default: {
        return <i/>;
      }
    }
  };
  return (
    <span className={`icon-component ${pointer && "pointer"}`} onClick={onClick}>
      {renderIcon()}
    </span>
  );
};

export default IconComponent;
