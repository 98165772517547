import { Radio } from "antd";
import { ErrorMessage, Field } from "formik";
import React, { FC } from "react";
import Error from "../Error";
import "./radioComponent.scss";

interface RadioComponentProps {
  label?: string;
  onChange: (value: any) => void;
  value: any;
  data: {
    value: string;
    label: string;
  }[];
  name: string;
  className?: string;
}

const RadioComponent: FC<RadioComponentProps> = (props) => {
  const { label, onChange, value, data, name, className } = props;

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className={`radio-component ${className}`}>
          {label ? <label className="label">{label}</label> : ""}
          <Radio.Group onChange={onChange} value={value} {...field}>
            {data?.map((value, index) => {
              return <Radio value={value?.value}>{value?.label}</Radio>;
            })}
          </Radio.Group>
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
};

export default RadioComponent;
