import React, { FC, ReactNode } from "react";
import CardSkeletonComponent from "../CardSkeletonComponent";
import "./sectionCardComponent.scss";

interface SectionCardComponentProps {
  section: ReactNode;
  content: ReactNode;
  className?: string;
  loading?: boolean;
}

const SectionCardComponent: FC<SectionCardComponentProps> = (props) => {
  const { section, content, className, loading } = props;

  return loading ? (
    <CardSkeletonComponent />
  ) : (
    <div className={`section-card-component ${className}`}>
      <div className="section-card-component__content">{content}</div>
    </div>
  );
};

export default SectionCardComponent;
