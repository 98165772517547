import React, { FC, Fragment, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { InternalUsersModel } from "../../../../models/InternalUsers/internalusers.model";
import { VendorModel } from "../../../../models/Vendor/vendor.model";
import VendorService from "../../../../services/VendorService/vendor.service";
import DocumentsCard from "../../../../shared/components/DocumentsCard";
import "./vendorDetails.scss";
import VendorDetailsCard from "./VendorDetailsCard";
import VendorInfoCard from "./VendorInfoCard";
import VendorPersonalDetailsCard from "./VendorPersonalDetailsCard";

interface VendorDetailsProps {}

const VendorDetails: FC<VendorDetailsProps> = (props) => {
  const {} = props;
  const [isVendorFormShown, setIsVendorFormShown] = useState(false);
  const [isBlacklistAlertShown, setIsBlacklistAlertShown] = useState(false);
  const { loading, fetchVendor, vendor } = VendorService();
  const navigate = useNavigate();
  useEffect(() => {
    fetchVendor();
  }, []);

  const handleRefresh = () => {
      fetchVendor()
  };

    return (
    <Fragment>
      <Row className="vendor-details" gutter={[24, 24]}>
        <Col span={6}>
          <VendorDetailsCard
            loading={loading}
            vendor={vendor?.vendorDetails}
            vendorType="approved"
            editHandler={() => setIsVendorFormShown(true)}
            blacklistHandler={() => setIsBlacklistAlertShown(true)}
          />
        </Col>
        <Col span={12}>
          <VendorInfoCard
            vendorType="approved"
            refreshList={handleRefresh}
            loading={loading}
            vendor={vendor?.vendorDetails as VendorModel}
            contactPerson={vendor?.contactPersonDetails as InternalUsersModel}
          />
        </Col>
        <Col span={6}>
          <DocumentsCard
            loading={loading}
            data={[
              {
                fileName: vendor?.supportDocuments?.commercialRegistration?.filename??"",
                fileUrl: vendor?.supportDocuments?.commercialRegistration?.s3Url ?? "",
                fileId: vendor?.supportDocuments?.commercialRegistration?.id ?? "",
                fileType: vendor?.supportDocuments?.commercialRegistration?.fileType ?? "",
                label: "COMMERCIAL REGISTRATION",
              },
              {
                fileName: vendor?.supportDocuments?.taxCard?.filename??"",
                fileUrl: vendor?.supportDocuments?.taxCard?.s3Url ?? "",
                fileId: vendor?.supportDocuments?.taxCard?.id ?? "",
                fileType: vendor?.supportDocuments?.taxCard?.fileType ?? "",
                label: "TAX CARD",
              }
            ]}
            className="vendor-details-documents"
          />
        </Col>
        <Col span={24}>
          <VendorPersonalDetailsCard loading={loading} vendor={vendor} />
        </Col>
      </Row>
    </Fragment>
  );
};

export default VendorDetails;
