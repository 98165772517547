import { Col, Row, Select } from "antd";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import React, { FC, useEffect, useState, useRef, ReactNode } from "react";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import ModalComponent from "../../../../shared/components/ModalComponent";
import SwitchComponent from "../../../../shared/components/SwitchComponent";
import "./deallUserForm.scss";
import { BadgeTypesDefinition } from "../../../../definitions/badgeTypesDefinition";
import InternalUsersService from "../../../../services/InternalUsersService/internalusers.service";
import { VendorTypes, VendorUsers } from "../../../../enums/vendorTypes";
import { useTranslation } from "react-i18next";
import { internalUserFormValidation } from "./internalUserFormValidation";
import { Option } from "antd/lib/mentions";
import { InternalUsersModel } from "../../../../models/InternalUsers/internalusers.model";
import MetaService from "../../../../services/MetaService/meta.service";
import { countryCodesInitialValue, generateCountryCodeOptions, getCountryIsdCode, generateCodeOptions } from "../../../../shared/utils/generateCountryCodeOptions";

interface InternalUserFormProps {
  visible: boolean;
  internalUser?: any;
  closeHandler: () => void;
  onUpdate: () => void;
}

type InitialValuesType = {
  firstName: string | null,
  lastName: string | null,
  email: string | null,
  password: string | null,
  role: string | null,
  mobileNumber: string | null,
  status: boolean,
  countryCodeId: string
}

const InternalUserForm: FC<InternalUserFormProps> = (props) => {
  const { visible, closeHandler, internalUser, onUpdate } = props;
  const { getCountryCodesMeta, countryCodesMeta } = MetaService()
  const [initialValues, setInitialValues] = useState<InitialValuesType>({
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    role: null,
    mobileNumber: null,
    status: false,
    countryCodeId: countryCodesInitialValue(countryCodesMeta)
  });
  const { createInternalUser, updateInternalUser, fetchInternalUserList } = InternalUsersService();
  const { t } = useTranslation()
  const formRef = useRef<FormikProps<FormikValues>>(null);
  useEffect(() => {
    getCountryCodesMeta()
  }, [])
  useEffect(() => {
    if (Object.entries(internalUser).length) {
      const { firstName, lastName, mobileNumber, email, fullName, id, role, status, countryCodeId, countryCode } = internalUser
      const deallUserObj = {
        firstName,
        lastName,
        mobileNumber,
        email,
        fullName,
        id,
        role,
        status: status === "approved",
        password: null,
        countryCodeId: countryCodeId ?? '',
        countryCode
      }
      setInitialValues({ ...deallUserObj })
    }
    else {
      setInitialValues({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        role: null,
        mobileNumber: null,
        status: false,
        countryCodeId: countryCodesInitialValue(countryCodesMeta)
      })
    }
  }, [internalUser])
  const handleClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }
  const onSubmit = (values: FormikValues) => {

    values.status = values.status ? "approved" : "inactive"

    if (Object.entries(internalUser).length) {
      updateInternalUser(values.id, values as InternalUsersModel, () => {
        onUpdate()
        formRef?.current?.resetForm({ values: {} });
      })
    }
    else {
      createInternalUser(values as InternalUsersModel, () => {
        formRef?.current?.resetForm({ values: {} });
        onUpdate()
      })
    }
    closeHandler()
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      innerRef={formRef}
      validationSchema={internalUserFormValidation}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <ModalComponent
          title={Object.entries(internalUser).length ? t("edit_internal_user") : t("add_new_internal_user")}
          visible={visible}
          closeHandler={handleClose}
          successProps={{
            title: Object.entries(internalUser).length ? t("update") : t("create"),
            clickHandler: handleSubmit,
          }}
          cancelProps={{
            title: t("discard"),
            clickHandler: handleClose
          }}
          type="large"
        >
          <div className="deall-user-form">
            <Form>
              <Row gutter={16}>
                <Col className="filter-field" span={12}>
                  <InputField
                    placeholder="Enter"
                    name="firstName"
                    label="First Name"
                  />
                </Col>
                <Col className="filter-field" span={12}>
                  <InputField
                    placeholder="Enter"
                    name="lastName"
                    label="Last Name"
                  />
                </Col>
                <Col className="filter-field" span={12}>
                  <InputField placeholder="Enter" name="email" label="Email" />
                </Col>
                <Col className="filter-field" span={12}>
                <InputField
                    addonBefore={<Select
                      value={values?.countryCodeId ?? ''}
                      onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                        if(typeof value !== "string"){
                          setFieldValue("countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                          setFieldValue("countryCodeId", value)
                        } 
                        setFieldValue("mobileNumber", undefined)
                      }}
                      options={generateCodeOptions(countryCodesMeta)}
                      />
                    }
                    name="mobileNumber"
                    label="Phone Number"
                    placeholder="Enter" />
                    {/* TODO: Needed for production */}
                  {/* <InputField prefix='+20' type="text" name="mobileNumber" label='Mobile Number' placeholder="Enter" /> */}
                </Col>
                {/* TODO required for next phase */}
                {/* <Col className="filter-field" span={12}>
                  <DropdownField
                    placeholder="Select"
                    name="role"
                    label="Role"
                    value={values?.role}
                    onChange={(value) => setFieldValue("role", value)}
                    options={[
                      {
                        value: VendorUsers.VENDOR_USER,
                        label: BadgeTypesDefinition[VendorUsers.VENDOR_USER],
                      },
                      {
                        value: VendorUsers.VENDOR_INTERNAL_USER,
                        label: BadgeTypesDefinition[VendorUsers.VENDOR_INTERNAL_USER],
                      },
                    ]}
                  />
                </Col> */}
                <Col className="filter-field" span={12}>
                  <InputField
                    placeholder="Enter"
                    name="password"
                    label="Password"
                    type="text"
                    className="password-field"
                  />
                </Col>
                <Col className="filter-field" span={12}>
                  <SwitchComponent
                    label="Status"
                    value={values?.status}
                    checkedTitle={t("active")}
                    unCheckedTitle={t("inactive")}
                    onSwitch={(value) => setFieldValue("status", value)}

                  />
                </Col>
              </Row>
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default InternalUserForm;
