
import {serializable, alias, object, list, primitive} from 'serializr';

export class VendorSettlementModel { 

	@serializable(alias('no_of_orders', primitive()))
	noOfOrders?: number;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

}