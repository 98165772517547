import * as Yup from "yup";
import { REGEX } from "../../../enums/regex";

export const businessDetailsValidation = Yup.object().shape({
  vendorDetails: Yup.object().shape({
    logoId: Yup.string()
      .required('Logo is required'),
    name: Yup.string()
      .required('Business name is required'),
    categoryId: Yup.string()
      .nullable()
      .required('Category is required'),
      // TODO required for next phase
    // subCategoryId: Yup.string()
    //   .nullable()
    //   .required('Sub Category is required'),
    type: Yup.string()
      .required('Type is required'),
    monthlyTransactionVolume: Yup.number()
      .required('Monthly transaction volume is required'),
  }),
  paymentDetails: Yup.object().shape({
    accountHolderName: Yup.string()
      .required('Account holder name is required'),
    address: Yup.string()
      .required('Address is required'),
    accountNumber: Yup.string()
      .required('Account number is required'),
    iban: Yup.string()
      .required('iban is required'),
    swiftCode: Yup.string()
      .required('Swift code is required'),
    branch: Yup.string()
      .required('Branch is required'),
    // TODO E-wallet integration
    // walletNumber: Yup.string()
    //   .typeError("Wallet number should be a alphanumeric"),
    // walletTypeId: Yup.string()
    //   .when("walletNumber", {
    //     is: (walletNumber) => walletNumber?.length > 0,
    //     then: Yup.string().required('Wallet type is required').typeError("Select a valid Wallet type")
    //   })
  })
})