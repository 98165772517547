import React, { FC, Fragment, useEffect, useState } from 'react'
import { Col, Divider, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BadgeTypes } from '../../../../../enums/badgeTypes'
import { AppRoutes } from '../../../../../routes/routeConstants/appRoutes'
import VendorService from '../../../../../services/VendorService/vendor.service'
import BadgeComponent from '../../../../../shared/components/BadgeComponent'
import ButtonComponent from '../../../../../shared/components/ButtonComponent'
import CardComponent from '../../../../../shared/components/CardComponent'
import CardSkeletonComponent from '../../../../../shared/components/CardSkeletonComponent'
import IconComponent from '../../../../../shared/components/IconComponent'
import MapComponent from '../../../../../shared/components/MapComponent'
import MenuComponent from '../../../../../shared/components/MenuComponent'
import ModalComponent from '../../../../../shared/components/ModalComponent'
import "./instoreView.scss"

interface InstoreViewProps {
}

const InstoreView: FC<InstoreViewProps> = () => {

    const { deleteVendorInstore, fetchVendorInstore, vendorInstore, loading } = VendorService()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { instoreId } = useParams()

    const [isVendorInstoreDeleteAlertShown, setIsVendorInstoreDeleteAlertShown] =
        useState(false);

    useEffect(() => {
        instoreId && fetchVendorInstore(instoreId)
    }, [instoreId])

    return (
        <div className="instore-view instore-form-view">
            <ModalComponent
                type="alert"
                visible={isVendorInstoreDeleteAlertShown}
                closeHandler={() => setIsVendorInstoreDeleteAlertShown(false)}
                successProps={{
                    title: t("delete"),
                    loading: false,
                    clickHandler: () => {
                        if (vendorInstore?.id) {
                            deleteVendorInstore(vendorInstore?.id)
                                ?.then(() => {
                                    setIsVendorInstoreDeleteAlertShown(false);
                                    navigate(-1)
                                })
                        }
                        setIsVendorInstoreDeleteAlertShown(false);
                    },
                }}
                cancelProps={{
                    title: t("close"),
                    clickHandler: () => {
                        setIsVendorInstoreDeleteAlertShown(false);
                    },
                }}
                alert={{
                    title: t("are_you_sure_you_want_to_delete_the_instore"),
                    buttonType: "negative"
                }}
            />
            <div className="header">
                <Row align="middle">
                    <Col span={20}>
                        <h2 className='title'>
                            <span className="back-icon" onClick={() => navigate(-1)}>
                                <IconComponent name="icon-back" />
                            </span>
                        </h2>
                    </Col>
                    {
                        vendorInstore &&
                        <Fragment>
                            <Col span={2} className="action-btn">
                                {vendorInstore?.status !== BadgeTypes.PENDING_APPROVAL &&
                                    <ButtonComponent
                                      type="primary"
                                      size="medium"
                                      icon={<IconComponent name="icon-edit-white" />}
                                      onClick={()=>navigate({ pathname: generatePath(AppRoutes.VENDOR_INSTORE_EDIT, { instoreId: `${vendorInstore?.id}` }) }, { state: vendorInstore })}
                                      className="vendor-form-edit"
                                    >
                                      <span className="text">
                                        Edit
                                      </span>
                                    </ButtonComponent>
                                }
                            </Col>
                            <Col span={1} className="action-btn">
                                <MenuComponent
                                    dropdownClassName="document-reupload"
                                    menu={[
                                        {
                                            title: "Delete store",
                                            icon: <IconComponent name="icon-trash"/>,
                                            clickHandler: () => setIsVendorInstoreDeleteAlertShown(true)
                                        },
                                    ]}
                                />
                            </Col>
                        </Fragment>
                    }
                </Row>
            </div>
            <div className="content vendor-instore-details-modal">
                {
                    loading ?
                        <Row gutter={[24, 24]}>
                            <Col span={10}>
                                <CardSkeletonComponent />
                            </Col>
                            <Col span={10}>
                                <CardSkeletonComponent />
                            </Col>
                        </Row> :
                        <div className="vendor-instore-details">
                            <Row gutter={[24, 24]}>
                                <Col span={10}>
                                    <CardComponent className='form-section'>
                                        <div className="vendor-instore-details">
                                            <Row align="top">
                                                <Col span={24}>
                                                    <div className="vendor-details__wrapper">
                                                        <div className="vendor-details">
                                                            <h2>{vendorInstore?.vendorName}</h2>
                                                        </div>
                                                        <BadgeComponent type={vendorInstore?.status === BadgeTypes.APPROVED ? BadgeTypes.ACTIVE : vendorInstore?.status as BadgeTypes} />
                                                        <div className="label">{t("address")}</div>
                                                        <div className="instore-address__wrapper">
                                                            <IconComponent name="icon-map-blue" />
                                                            <p className="instore-address">{vendorInstore?.address}</p>
                                                        </div>
                                                    </div>

                                                    {vendorInstore?.lat && vendorInstore?.long ? (
                                                        <div className="instore-map__wrapper" onClick={() => window.open(`https://maps.google.com/?q=${vendorInstore?.lat},${vendorInstore?.long}`)}>
                                                            <MapComponent
                                                                position={{
                                                                    lat: vendorInstore?.lat,
                                                                    lng: vendorInstore?.long,
                                                                }}
                                                                editable={false}
                                                                className="vendor-instore-details-map"
                                                            />
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Col>
                                            </Row>

                                            <Divider />
                                            <div className="instore-contact-details">
                                                <div className="label">{t("contact_person")}</div>
                                                <div className="name">{vendorInstore?.fullName}</div>
                                                <div className="contact">
                                                    <p className="phone-number">
                                                        <IconComponent name="icon-phone-blue" />
                                                        {vendorInstore?.countryCode?.isdCode} {vendorInstore?.mobileNumber}
                                                    </p>
                                                </div>
                                                <div className="contact">
                                                    <p className="email">
                                                        <IconComponent name="icon-email-blue" />
                                                        {vendorInstore?.email}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardComponent>
                                </Col>
                                <Col span={10}>
                                    <CardComponent className='form-section'>
                                        <div className="vendor-instore-terminals">
                                            <h3>POS Terminal ID</h3>
                                            <Row className='terminal-id-row'>
                                                {
                                                    vendorInstore?.fawryTerminalIds?.map((terminal) => (
                                                        <Fragment>
                                                            <Col span={10}>
                                                                <p className='terminal-name'>{terminal?.label}</p>
                                                            </Col>
                                                            <Col span={14}>
                                                                <p className='value'>{terminal?.terminalId}</p>
                                                            </Col>
                                                        </Fragment>
                                                    ))
                                                }
                                            </Row>
                                        </div>
                                    </CardComponent>
                                </Col>
                            </Row>
                        </div>
                }
            </div>
        </div>
    )
}

export default InstoreView