import React from "react";
import { Col, Dropdown, Row } from "antd";
import "./contact-us.scss"
import IconComponent from "../IconComponent";
import HummingBird from "./../../../assets/images/capa_1.svg"
import AppLogo from "../../../assets/images/logo_h.svg";
import manWithLaptop from "../../../assets/images/man-using-laptop.png";
import email from "../../../assets/images/email4x.png";
import phone from "../../../assets/images/phone4x.png";

const ContactUs = () => {
  const contactUsDetails = (
    <div className="contact-us__container">
      <div className="header">
        <div className="logo-container">
          <img src={HummingBird} alt="" className='humming-bird' />
          <img src={AppLogo} alt="app logo" className='app-logo' />
        </div>
      </div>
      <div className="floating-image">
        <img src={manWithLaptop} alt="man-with-laptop" className="man-with-laptop" />
      </div>
      <div className="content">
        <h3>Contact Rise</h3>
        <h4>Have any questions? We are happy to help you</h4>
        <p className="phone">
          <img src={phone} alt="phone" className="phone" />
          02 3333 5526
        </p>
        <p className="email">
          <img src={email} alt="email" className="email" />
          vendorsupport@riseco.ai
        </p>
      </div>
    </div>
  );
  return (
    <Dropdown
      className="contact-us"
      trigger={["click"]}
      overlay={contactUsDetails}
    >
      <IconComponent name="icon-contact-us-blue" />
    </Dropdown>
  );
};

export default ContactUs;
