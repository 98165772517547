import * as Yup from  "yup";
import { REGEX } from "../../../../enums/regex";

export const internalUserFormValidation = Yup.object().shape({
    firstName: Yup.string()
      .typeError("First name should be a string")
      .matches(REGEX.NAME.match, REGEX.NAME.error)
      .required('First name is required.'),
    lastName: Yup.string()
      .typeError("Last name should be a string")
      .matches(REGEX.NAME.match, REGEX.NAME.error)
      .required('Last name is required.'),
    email: Yup.string()
      .nullable()
      .email('Email is not valid.')
      .required('Email is required.'),
    password: Yup.string()
      .nullable()
      .matches(REGEX.PASSWORD.match,REGEX.PASSWORD.error)
      .min(8, 'Password has to be longer than 8 characters.'),  
    mobileNumber: Yup.string()
      .required('Phone number is required.')
      .when("countryCode.isdCode", {
        is: "+20",
        then: Yup.string()
          .matches(REGEX.PHONE.match, REGEX.PHONE.error)
      })
      .when("countryCode.isdCode", {
        is: "+972",
        then: Yup.string()
          .matches(REGEX.PHONE_ISRAEL.match, REGEX.PHONE_ISRAEL.error)
      })
      .when("countryCode.isdCode", {
        is: "+91",
        then: Yup.string()
          .matches(REGEX.PHONE_INDIA.match, REGEX.PHONE_INDIA.error)
      })
      .when("countryCode.isdCode", {
        is: "+1",
        then: Yup.string()
          .matches(REGEX.PHONE_US.match, REGEX.PHONE_US.error)
      }),
    // TODO required for next phase
    // role: Yup.string()
    //   .nullable()
    //   .required('Role is required!'),
  })