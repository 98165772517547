import convert from "xml-js" 

export function convertXMLToJSON(data: string, returnFormatedS3Key?: boolean) {
  if (returnFormatedS3Key)
    return formatToS3Object(convert.xml2js(data).elements);
  return convert.xml2js(data);
}
const formatToS3Object = (data: any[]) => {
  const response = data?.find((item) => item.name === "PostResponse")?.elements;
  const key = response?.find((item: any) => item.name === "Key")?.elements;
  return !!key?.length && key[0].text;
};
