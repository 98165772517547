import { Col, Divider, Row } from "antd";
import React, { FC, Fragment, useEffect } from "react";
import PageView from "../../../../../shared/components/PageView";
import "./pastSettlementsView.scss";
import { currencyString } from "../../..";
import TableComponent from "../../../../../shared/components/TableComponent";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import BadgeComponent from "../../../../../shared/components/BadgeComponent";
import { useParams } from "react-router-dom";
import CardSkeletonComponent from "../../../../../shared/components/CardSkeletonComponent";
import { VendorTypes } from "../../../../../enums/vendorTypes";
import { VendorTypesDefinition } from "../../../../../definitions/vendorTypesDefinition";
import moment from "moment";
import { SettlementCustomerPurchaseModel, SettlementModel, SettlementVendorModel } from "../../../../../models/Settlement/settlement.model";
import { deserialize } from "serializr";
import { SettlementService } from "../../../../../services/SettlementService/settlement.service";
import { useTranslation } from "react-i18next";
import { formatDate } from "./../../../../../shared/utils/formatDate"
import { convertToTwoDecimals } from "../../../../../shared/utils/convertToTwoDecimals";

interface PastSettlementsViewProps { }

const PastSettlementsView: FC<PastSettlementsViewProps> = (props) => {
  const { } = props;
  const { settlement, fetchSettlement, loading } = SettlementService();
  const { settlementId } = useParams();
  const { t } = useTranslation()

  useEffect(() => {
    if (settlementId) fetchSettlement(settlementId);
  }, [settlementId]);

  return (
    <PageView type="back-only">
      <div className="past-settlements-view">
        {loading ? (
          <CardSkeletonComponent
            className="vendor-details__container-skeleton"
            rows={7}
          />
        ) : (
          <div className="purchase-summary-card settlement-summary-card">
            <div className="purchase-summary-items-total__container">
              <div className="item">
                <div className="label">Total purchase amount</div>
                <div className="value">
                  {settlement?.totalAmount}
                </div>
              </div>
              <div className="item">
                <div className="label">Total Rise vendor fee <span>(2.9% of purchase amount)</span></div>
                <div className="value negative">
                  {"-"} {settlement?.vendorProcessingFee}
                </div>
              </div>
              <div className="item">
                <div className="label">Total VAT on Rise vendor fee <span>(14%)</span></div>
                <div className="value negative">
                  {"-"} {settlement?.vat}
                </div>
              </div>
              <div className="item">
                <div className="label">Total return amount</div>
                <div className="value negative">
                  {"-"} {settlement?.refundedTransactions}
                </div>
              </div>
            </div>
            <Divider dashed />
            <div className="purchase-summary-total__container">
              <div className="item bold">
                <div className="label">Total vendor settlement amount</div>
                <div className="value">
                  {settlement?.vendorSettlementAmount}
                </div>
              </div>
              <div className="item">
                <div className="label">Settlement ID</div>
                <div className="value">
                  {settlement?.transactionId}
                </div>
              </div>
              <div className="item">
                <div className="label">Settlement date</div>
                <div className="value">
                  {formatDate(settlement?.settledDate as string)}
                </div>
              </div>
            </div>
          </div>
        )}

        <TableComponent
          loading={loading}
          columns={[
            {
              dataIndex: "createdAt",
              key: "createdAt",
              title: t("purchase_date"),
              render: (text) => formatDate(text),
            },
            {
              dataIndex: "purchaseId",
              key: "purchaseId",
              title: t("purchase_id"),
            },
            {
              dataIndex: "mode",
              key: "mode",
              title: t("mode"),
              render: (text: string) => {
                return text
                  ? text == VendorTypes.ONLINE
                    ? VendorTypesDefinition[text]
                    : text == VendorTypes.INSTORE
                      ? text
                      : ""
                  : "";
              },
            },
            {
              dataIndex: "purchaseStatus",
              key: "purchaseStatus",
              title: t("purchase_status"),
              render: (text) => <BadgeComponent type={text} />,
            },
            {
              title: "Purchase Amount",
              dataIndex: "amount",
              key: "amount",
              render: (text, record: SettlementCustomerPurchaseModel) => (
                <span
                  className={`items-total ${record?.isAmountNegative && "payment-loss"
                    }`}
                >
                  {record?.isAmountNegative && "- "}
                  {currencyString}
                  {text}
                </span>
              ),
            },
            {
              title: "Vendor Fee",
              dataIndex: "vendorProcessingFee",
              key: "vendorProcessingFee",
              render: (text, record) =>
                record?.paymentTransactionType === "return"
                  ? "-"
                  : convertToTwoDecimals(+text),
              sorter: true,
            },
            {
              title: "VAT",
              dataIndex: "vat",
              key: "vat",
              render: (text, record) =>
                record?.paymentTransactionType === "return"
                  ? "-"
                  : convertToTwoDecimals(+text),
              sorter: true,
            },
            {
              title: "Settlement Amount",
              dataIndex: "vendorSettlementAmount",
              key: "vendorSettlementAmount",
              render: (text, record) => (
                <span className={record?.isAmountNegative && "payment-loss"}>
                  {record?.isAmountNegative && "-"}
                  {convertToTwoDecimals(+text)}
                </span>
              ),
              sorter: true,
              align: "right",
              width: 200,
            },
          ]}
          data={settlement?.customerPurchases}
        />
      </div>
    </PageView>
  );
};

export default PastSettlementsView;
