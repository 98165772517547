import React, { createRef, FC, RefObject, useRef } from "react";
import "./otpfield.scss";
import { Input, InputRef } from "antd";
interface OTPFieldProps {
  count: number;
  onChange?: (otp: string) => void;
}

let otp: any = {};

const OTPField: FC<OTPFieldProps> = (props) => {
  const { count, onChange } = props;
  const refs: any = Array(count)
    .fill("")
    .map(() => createRef());


  const handleKeyUp = (event: any, index: number) => {
    if (event?.key === "Delete" || event?.key === "Backspace") {
      if (index > -1) {
        refs[index - 1]?.current?.focus();
      }
    } else {
      if (index < count) {
        refs[index + 1]?.current?.focus();
      }
    }
  };

  const handleChange = (index: number, value: string) => {
    otp[index] = value
    onChange && onChange(Object.values(otp).join(""));
  };

  return (
    <div className="otp-field">
      {Array(count)
        .fill("")
        .map((_, index) => {
          return (
            <Input
              className="otp-input"
              type={"text"}
              ref={refs[index]}
              onKeyUp={(event) => handleKeyUp(event, index)}
              maxLength={1}
              placeholder="."
              onChange={(e) => handleChange(index, e?.target?.value)}
              onFocus={(e) => {
                const end = e?.target?.value?.toString()?.length;
                refs[index]?.current?.setSelectionRange(end, end);
              }}
            />
          );
        })}
    </div>
  );
};

export default OTPField;
