import { Col, Row, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import "./vendorStorePromotions.scss";
import { useParams } from "react-router-dom";
import { VendorStorePromotionCreationModel, VendorStorePromotionModel } from "../../../../models/Vendor/StorePromotion/storePromotion.model";
import SpinnerComponent from "../../../../shared/components/SpinnerComponent";
import UploadComponent from "../../../../shared/components/UploadComponent";
import IconComponent from "../../../../shared/components/IconComponent";
import VendorService from "../../../../services/VendorService/vendor.service";
import S3AttachmentService from "../../../../services/S3UploadService/s3upload.service";
import { useTranslation } from "react-i18next";

const VendorStorePromotions: FC = () => {
  const {
    vendorStorePromotions,
    loading,
    createVendorStorePromotion,
    fetchStorePromotionList,
    deleteVendorStorePromotion,
  } = VendorService();
  const { uploadAttachment } = S3AttachmentService()
  const [isUploading, setIsUploading] = useState(false);
  const [currentPromotion, setCurrentPromotion] =
    useState<VendorStorePromotionModel>();
  const { t } = useTranslation()
  useEffect(() => {
    fetchStorePromotionList();
  }, []);

  const uploadHandler = (payload: VendorStorePromotionCreationModel) => {
    setIsUploading(true);
    createVendorStorePromotion(payload, () => {
      setIsUploading(false);
    });
  };

  const deleteHandler = (vendorStorePromotionId: number) => {
    if (vendorStorePromotionId) {
      deleteVendorStorePromotion(vendorStorePromotionId, () =>
        setCurrentPromotion(undefined)
      );
    }
  };

  return loading ? (
    <SpinnerComponent tip="Loading..." />
  ) : (
    <Row className="vendor-store-promotions" gutter={[24, 24]}>
      {
        vendorStorePromotions?.length < 8 &&
        <Col span={8}>
          <UploadComponent
           fileName=""
           fileId=""
           fileType=""
           accept={"image/png, image/jpg, image/jpeg"}
            type="poster"
            isUploading={isUploading}
            disabled={isUploading}
            onUpload={async (file, fileUrl) => {
              const attachment = await uploadAttachment(file);
              uploadHandler({
                attachment,
              });
            }}
            title={t("upload_poster") + (t("335_200_px"))}
          />
        </Col>
      }
      {vendorStorePromotions?.map((value, index) => {
        return (
          <Col span={8}>
            <div
              className={`vendor-store-promotion__container ${value?.id == currentPromotion?.id && "is-deleting"
                }`}
            >
              {currentPromotion?.id == value?.id && (
                <SpinnerComponent
                  tip="Deleting..."
                  className="vendor-store-promotion-delete-spin"
                />
              )}
              <img
                className="vendor-store-promotion-img"
                src={value?.attachment?.s3Url}
              />
              <div
                className="vendor-store-promotion-close"
                onClick={() => {
                  setCurrentPromotion(value);
                  if (value?.id) deleteHandler(value?.id);
                }}
              >
                <IconComponent name="icon-close" />
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};
export default VendorStorePromotions;
