import React, { FC, useState, useEffect } from "react";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import "./internalusers.scss";
import { BadgeTypes } from "../../../enums/badgeTypes";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../shared/components/TableComponent";
import BadgeComponent from "../../../shared/components/BadgeComponent";
import { Form, Formik, FormikValues } from "formik";
import { Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import DeallUserForm from "./InternalUserForm";
import { BadgeTypesDefinition } from "../../../definitions/badgeTypesDefinition";
import { InternalUsersModel } from "../../../models/InternalUsers/internalusers.model";
import ModalComponent from "../../../shared/components/ModalComponent";
import { deserialize } from "serializr";
import { tableSorter } from "../../../shared/utils/tableSorter";
import InternalUsersService from "../../../services/InternalUsersService/internalusers.service";
import { useTranslation } from "react-i18next";
import { SortFilterModel } from "../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../shared/types/SortColumn.type";

interface DeallUsersProps { }

interface FilterProps extends InternalUsersModel {
  search?: string;
}

const InternalUsers: FC<DeallUsersProps> = (props) => {
  const { } = props;
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [isDeallUserFormShown, setIsDeallUserFormShown] = useState(false);
  const [isDeallUserAlertShown, setIsDeallUserAlertShown] = useState(false);
  const [selectVal, setSelectedVal] = useState<any>({});
  const { loading, fetchInternalUserList, internalUsers, deleteInternalUser, searchInternalUsers, paginations } = InternalUsersService();
  const [internalUser, setInternalUser] = useState({});
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<FilterProps>({});
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const {t} = useTranslation()
  
  useEffect(() => {
    fetchInternalUserList()
  }, [])

  useEffect(() => {
    fetchInternalUserList({...filters, ...sortOptions}, ()=>{})
    searchInternalUsers(filters);
  }, [filters]);

  const handleFilterSubmit = (values: FilterProps) => {
    setFilters(prev => ({ ...prev, ...values }));
    handleFilterClose();
  };
  const filterInitialValues = {
    role: undefined,
    status: undefined,
  };
  const handleFilterClose = () => {

    setIsFilterShown(false);
  };
  const filter = (
    <TableFilterDropdownComponent

      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent

            applyHandler={handleSubmit}
            clearHandler={() => {
              handleFilterClose();
              setFilters(() => ({ ...filterInitialValues, search: filters?.search }));
              resetForm({});
            }}
          >
            <Form>
              <Row gutter={16}>
                {/* TODO required for next phase */}
                {/* <Col className="filter-field" span={12}>
                  <DropdownField
                    label="Role"
                    options={[
                      {

                        value: BadgeTypes.VENDOR_USER,
                        label: BadgeTypesDefinition[BadgeTypes.VENDOR_USER],


                      },
                      {

                        value: BadgeTypes.VENDOR_INTERNAL_USER,
                        label: BadgeTypesDefinition[BadgeTypes.VENDOR_INTERNAL_USER],


                      },
                    ]}
                    name="role"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("role", value)}
                    value={values?.role}


                  />
                </Col> */}
                <Col className="filter-field" span={12}>
                  <DropdownField
                    label="Status"
                    options={[
                      {
                        value: BadgeTypes.APPROVED,
                        label: BadgeTypesDefinition[BadgeTypes.ACTIVE],
                      },
                      {
                        value: BadgeTypes.INACTIVE,
                        label: BadgeTypesDefinition[BadgeTypes.INACTIVE],
                      }
                    ]}
                    name="status"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("status", value)}
                    value={values?.status}
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  const handleRefresh = () => {
    searchInternalUsers();
  };
  const handlePageChange = (page:number) => {
    setPage(page)
    fetchInternalUserList({page, ...filters, ...sortOptions}, ()=>{})
  }
  return (
    <PageView
      title={{
        name: t("internal_users"),
        icon: <IconComponent name="icon-user-config" />,
      }}
    >
      <div className="deall-users">
        <DeallUserForm
          internalUser={internalUser}
          onUpdate={() => 
            fetchInternalUserList({}, ()=>{})
        }
          visible={isDeallUserFormShown}
          closeHandler={() => setIsDeallUserFormShown(false)}
        />
        <ModalComponent
          type="alert"
          visible={isDeallUserAlertShown}
          closeHandler={() => setIsDeallUserAlertShown(false)}
          successProps={{
            title: "Delete",
            loading: false,
            clickHandler: () => {
              deleteInternalUser(selectVal?.id, () => {
                fetchInternalUserList({}, () => {})
              })
              setIsDeallUserAlertShown(false)
            },
          }}
          cancelProps={{
            title: "Close",
            clickHandler: () => {
              setIsDeallUserAlertShown(false);
            },
          }}
          alert={{
            title: t("are_you_sure_you_want_to_delete_the_user"),
            buttonType: "negative"
          }}
        />
        <TableComponent
          paginationMeta={paginations}
          handlePageChange={handlePageChange}
          loading={loading}
          data={internalUsers}
          onChange={fetchInternalUserList}
          onSort={(data)=>{
            setSortOptions(data)
          }}
          filtersSorts={{page, ...filters, search}}
          search={{
            placeholder: t("search_internal_users_by_name_or_id"),
            onSearch: (text) => {
              setSearch(text)
              handleFilterSubmit({ search: text })
            },
          }}
          newBtn={{
            clickHandler: () => {
              setInternalUser({})
              setIsDeallUserFormShown(true);
            },
          }}
          filter={filter}
          columns={[
            {
              title: t("user_id"),
              dataIndex: "id",
              key: SortColumnTypes.USER_ID,
              sorter: true
            },
            {
              title: t("user_name"),
              dataIndex: "fullName",
              key: SortColumnTypes.USER_NAME,
              sorter: true
            },
            // TODO required for next pahse
            // {
            //   title: t("role"),
            //   dataIndex: "role",
            //   key: "role",
            //   render: (text: BadgeTypes) => BadgeTypesDefinition[text],
            //   sorter: (a, b) => tableSorter("alphabetical", a?.role, b?.role),
            // },
            {
              title: t("phone_number"),
              dataIndex: "mobileNumber",
              key: "mobileNumber",
            },
            {
              title: t("email"),
              dataIndex: "email",
              key: "email",
            },
            {
              title: t("status"),
              dataIndex: "status",
              key: SortColumnTypes.USER_STATUS,
              render: (text) => <BadgeComponent type={text=="approved"?"active":text} />,
              sorter: true
            },
            {
              title: "",
              key: "action",
              render: (data) => {

                return (
                  <div className="deall-users-actions">
                    <IconComponent
                      name="icon-edit-blue"
                      onClick={() => {
                        setInternalUser({...data})
                        setIsDeallUserFormShown(true);
                      }}
                    />
                    <IconComponent
                      name="icon-trash"
                      onClick={() => {
                        setSelectedVal(data)
                        setIsDeallUserAlertShown(true);
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </PageView>
  );
};

export default InternalUsers;
