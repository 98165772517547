import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { S3AttachementModel } from "../../models/S3Attachment/s3Attachment.model";
import Axios from "axios";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { convertJsonToFormData } from "../../shared/utils/convertJsonToFormData";
import { convertXMLToJSON } from "../../shared/utils/convertXmlToJson";

const S3AttachmentService = () => {
  const [error, setError] = useState<Error>();

  const [loading, setLoading] = useState(false);

  const uploadAttachment = async (file: File) => {
    setLoading(true);
    const presignedResponse = await axiosInstance
      .post(ApiRoutes.S3_PRESIGNED_URL,{"filename":file?.name})
      .then((response) => {
        return {
          ...response?.data?.data?.url_fields,
          url: response?.data?.data?.url,
          file,
        };
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    const jsonData = { ...presignedResponse };
    delete jsonData["url"];
    const formData = convertJsonToFormData(jsonData);
    if (presignedResponse?.url) {
      const xmlResponse = await Axios.post(presignedResponse?.url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((xmlResponse:any) => {
          return xmlResponse;
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
      const key = convertXMLToJSON(xmlResponse?.data, true);
      const attachmentId = await uploadS3Attachment(file?.name, file?.type, key);
      return attachmentId;
    } else {
      Notification({
        type: NotificationTypes.ERROR,
        message: "Failed while uploading to S3",
        description: "Please try again later.",
      });
    }
  };
  const uploadS3Attachment = (filename: string, fileType: string, s3Key: string) => {
    const payload = serialize(S3AttachementModel, {
      filename,
      s3Key,
      fileType
    });
    return axiosInstance
      .post(ApiRoutes.S3_ATTACHMENT, payload)
      .then((response) => {
        return response?.data?.["attachment"]?.id;
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    loading,
    uploadAttachment,
    uploadS3Attachment,
  };
};

export default S3AttachmentService;