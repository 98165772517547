import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  VendorStorePromotionCreationModel,
  VendorStorePromotionModel,
} from "../../models/Vendor/StorePromotion/storePromotion.model";
import { VendorPurchaseConfigModel } from "../../models/Vendor/PurchaseConfig/purchaseConfig.model";
import { VendorListModel, VendorModel } from "../../models/Vendor/vendor.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { InternalUsersModel } from "../../models/InternalUsers/internalusers.model";
import { PaginationModel } from "../../models/pagination.model";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";

const InternalUsersService = () => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const [internalUsers, setInternalUsers] = useState<InternalUsersModel[]>([]);
  const [vendor, setVendor] = useState<VendorModel>();

  const [purchaseConfig, setPurchaseConfig] = useState<
    VendorPurchaseConfigModel[]
  >([]);
  const [vendorStorePromotions, setVendorStorePromotions] = useState<
    VendorStorePromotionModel[]
  >([]);

  const [paginations, setPaginations] = useState<PaginationModel>()

  const fetchInternalUserList = (params?: SortFilterModel, onSuccess?:Function ) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    return axiosInstance
      .get(ApiRoutes.INTERNAL_USERS, {params: _params})
      .then((response) => {
        const list = deserialize(
          InternalUsersModel,
          response?.data?.vendor_users
        ) as InternalUsersModel[];
        const meta = deserialize(PaginationModel, response?.data?.meta)
        setPaginations(meta);
        setInternalUsers(list);
        onSuccess && onSuccess(list)
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createInternalUser = (deallUser: InternalUsersModel,onSucces:()=>void) => {
    setLoading(true);
    const payload = {
      vendor_user: serialize(InternalUsersModel, deallUser)
    } 
    return axiosInstance
      .post(ApiRoutes.INTERNAL_USERS, payload)
      .then((response) => {
        onSucces()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  const updateInternalUser = (userId: string, deallUser: InternalUsersModel,onSucces:()=>void) => {
    setLoading(true);
    const payload = {
      vendor_user: serialize(InternalUsersModel, deallUser)
    } 
    const API_URL = generatePath(ApiRoutes.INTERNAL_USER, { userId });
    return axiosInstance
      .put(API_URL, payload)
      .then((response) => {
        onSucces()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteInternalUser = (userId: any,onSucces:any) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.INTERNAL_USER, { userId });
    return axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSucces()
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const searchInternalUsers = (params?: { search?: string, status?: string, role?: string }) => {
    setLoading(true);
    return axiosInstance
      .get(ApiRoutes.INTERNAL_USERS, { params })
      .then((response) => {
        const list = deserialize(
          InternalUsersModel,
          response?.data?.vendor_users
        ) as InternalUsersModel[];
        setInternalUsers(list);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    internalUsers,
    purchaseConfig,
    vendor,
    vendorStorePromotions,
    fetchInternalUserList,
    createInternalUser,
    updateInternalUser,
    deleteInternalUser,
    searchInternalUsers,
    paginations
  };
};

export default InternalUsersService;
