import { BadgeTypes } from "../enums/badgeTypes";

export const BadgeTypesDefinition = {
    [BadgeTypes.ACTIVE]: "Active",
    [BadgeTypes.APPROVED]: "Approved",
    [BadgeTypes.INACTIVE]: "Inactive",
    [BadgeTypes.COMPLETED]: "Completed",
    [BadgeTypes.REFUND_COMPLETED]: "Refund Completed",
    [BadgeTypes.PURCHASED]: "Purchased",
    [BadgeTypes.PARTIAL_RETURN]: "Partial Return",
    [BadgeTypes.FAILED]: "Failed",
    [BadgeTypes.RETURN]: "Return",
    [BadgeTypes.PENDING_APPROVAL]:"Pending Approval",
    [BadgeTypes.PENDING_SETTLEMENT]: "Pending Settlement",
    [BadgeTypes.OUTSTANDING_PAYMENT]: "Outstanding Payment",
    [BadgeTypes.RETURNED]: "Returned",
    [BadgeTypes.RETURN_CANCELLED]: "Return Cancelled",
    [BadgeTypes.OVERDUE]: "Overdue",
    [BadgeTypes.OUTSTANDING]: "Outstanding",
    [BadgeTypes.SETTLED]: "Settled",
    [BadgeTypes.REJECTED]: "Rejected",
    [BadgeTypes.PENDING]: "Pending",
    [BadgeTypes.ADMIN]:"Admin",
    [BadgeTypes.INTERNAL_USER]:"Internal Users",
    [BadgeTypes.VENDOR]: "Vendor",
    [BadgeTypes.VENDOR_USER]: "Vendor User",
    [BadgeTypes.VENDOR_INTERNAL_USER]: "Vendor Internal User",
    [BadgeTypes.PENDING_SETTLEMENTS]: "Pending Settlements",
}