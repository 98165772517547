import React, { FC, useEffect, useState } from "react";
import "./mapComponent.scss";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import AutoComplete from "react-google-autocomplete";
import MapsAutocompleteComponent from "../MapsAutocompleteComponent";

interface AsyncMapProps {
  position: {
    lat: number;
    lng: number;
  };
  onChange: (position: AsyncMapProps["position"]) => void;
  editable?: boolean;
}

interface MapComponentProps {
  position: AsyncMapProps["position"] | undefined;
  onChange?: (position: AsyncMapProps["position"]) => void;
  editable?: boolean;
  className?: string;
}

const Map: any = withScriptjs(
  withGoogleMap((props: AsyncMapProps) => {
    return (
      <GoogleMap
        defaultZoom={10}
        center={props?.position}
        defaultOptions={
          !props?.editable
            ? {
                fullscreenControl: false,
                draggable: false
              }
            : {}
        }
      >
        <Marker
          position={props?.position}
          {...(props?.editable
            ? {
                draggable: true,
                onDragEnd: (location) => {
                  if (location?.latLng?.lat && location?.latLng?.lng)
                    props?.onChange({
                      lat: location?.latLng?.lat(),
                      lng: location?.latLng?.lng(),
                    });
                },
              }
            : {})}
        />
      </GoogleMap>
    );
  })
);

const MapComponent: FC<MapComponentProps> = (props) => {
  const { position, onChange, editable = true, className } = props;
  const [currentLocation, setCurrentLocation] = useState<
    AsyncMapProps["position"]
  >({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        setCurrentLocation({ lat, lng });
      }
    );
  }, []);

  return (
    <div className={`map-component ${className}`}>
      <Map
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`}
        loadingElement={<div className="map-loading" />}
        containerElement={<div className="map-container" />}
        mapElement={<div className="map" />}
        position={position ?? currentLocation}
        onChange={(position: AsyncMapProps["position"]) => {
          onChange && onChange(position);
        }}
        editable={editable}
      />
      {editable && (
        <MapsAutocompleteComponent
          onSearch={(position) => {
            onChange && onChange(position);
          }}
        />
      )}
    </div>
  );
};

export default MapComponent;
