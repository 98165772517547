import { useState } from 'react';
import { deserialize, serialize } from 'serializr';
import axiosInstance from '../../interceptor/axiosInstance';
import { ApiRoutes } from './../../routes/routeConstants/apiRoutes';
import { Dashboard, DashboardFilterModel } from '../../models/Dashboard/dashboard.model';
import { getRandomColor } from '../../shared/utils/generateRandomColor';

type graphDataType = {
    labels: string[];
    datasets: {
        data: number[];
        backgroundColor: string[];
    }[];
}

const DashboardService = () => {

    const [loading, setLoading] = useState<boolean>(false)
    const [keyMetricsLoading, setKeyMetricsLoading] = useState<boolean>(false)

    const [dashboardKeyMetrics, setDashboardKeyMetrics] = useState<Dashboard | null>()
    const [purchaseCountGraphData, setPurchaseCountGraphData] = useState<graphDataType>()
    const [purchaseValueGraphData, setPurchaseValueGraphData] = useState<graphDataType>()

    const generateGraphData = (rawData: object, reverse: boolean) => {
        let labels = Object.keys(rawData)
        let data = Object.values(rawData)
        labels = reverse?labels.reverse():labels
        data = reverse?data.reverse():data

        const bgColors = labels.map(label => getRandomColor())

        return {
            labels,
            datasets:[{
                data,
                backgroundColor: bgColors
            }]
        }
    }

    const fetchDashboardKeyMetrics = async (_params?: {
        instoreId?: string,
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setKeyMetricsLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_KEY_METRICS, { params })
            const data = deserialize(Dashboard, response.data)
            setDashboardKeyMetrics(data)
        } catch (error) {

        } finally {
            setKeyMetricsLoading(false)
        }
    }

    const fetchDashboardPurchaseCount = async (_params?: {
        instoreId?: string,
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setKeyMetricsLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_PURCHASE_COUNT, { params })
            setPurchaseCountGraphData(generateGraphData(response.data["purchase_count"], _params?.dateFilterKey === "annual") )
        } catch (error) {
            
        } finally {
            setKeyMetricsLoading(false)
        }
    }
    
    const fetchDashboardPurchaseValue = async (_params?: {
        instoreId?: string,
        dateFilterKey?: string,
        startDate?: string,
        endDate?: string
    }) => {
        try {
            setKeyMetricsLoading(true)
            const params = serialize(DashboardFilterModel, _params)
            const response = await axiosInstance.get(ApiRoutes.DASHBOARD_PURCHASE_VALUE, { params })
            setPurchaseValueGraphData(generateGraphData(response.data["purchase_value"], _params?.dateFilterKey === "annual") )
        } catch (error) {

        } finally {
            setKeyMetricsLoading(false)
        }
    }

    return {
        loading,
        dashboardKeyMetrics,
        fetchDashboardKeyMetrics,
        keyMetricsLoading,
        fetchDashboardPurchaseCount,
        fetchDashboardPurchaseValue,
        purchaseValueGraphData,
        purchaseCountGraphData,
    }
}

export default DashboardService