import { Tabs } from "antd";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import IconComponent from "../../../shared/components/IconComponent";
import PageView from "../../../shared/components/PageView";
import PastSettlements from "./PastSettlements";
import PendingSettlements from "./PendingSettlements";
import "./settlements.scss";
const { TabPane } = Tabs;

interface SettlementsProps {}

const Settlements: FC<SettlementsProps> = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation()

  useEffect(() => {
    if (!location?.hash)
      navigate(
        { pathname: AppRoutes.SETTLEMENTS, hash: "pending" },
        { replace: true }
      );
  }, [location]);

  return (
    <PageView
      title={{
        name: t("settlements"),
        icon: <IconComponent name="icon-settlements" />,
      }}
    >
      <Tabs
        activeKey={location.hash?.replace(/#/gi, "")}
        onTabClick={(value) => {
          navigate({ pathname: location.pathname, hash: value });
        }}
        className="settlements"
      >
        <TabPane key="pending" tab={t("pending")}>
          <PendingSettlements/>
        </TabPane>
        <TabPane key="past-settlements" tab={t("past_settlements")}>
          <PastSettlements/>
        </TabPane>
      </Tabs>
    </PageView>
  );
};

export default Settlements;
