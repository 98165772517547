import React, { FC } from 'react'
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

interface FileViewerProps {
  uri: string,
  fileType?: string
}

const FileViewer:FC<FileViewerProps> = (props) => {
  const {uri,fileType}=props
  return (
    <DocViewer
        pluginRenderers={DocViewerRenderers}
        style={{
          minHeight:500
        }}
        config={{
          header:{
            disableFileName:true
          }
        }}
        documents={[
          {
            uri,
            fileType
          },
        ]}
      />
  )
}

export default React.memo(FileViewer)