import { Form, Formik, FormikValues } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { serialize } from "serializr";
import { InternalUsersModel } from "../../../../models/InternalUsers/internalusers.model";
import VendorService from "../../../../services/VendorService/vendor.service";
import { convertJsonToFormData } from "../../../utils/convertJsonToFormData";
import { removeUndefined } from "../../../utils/removeUndefinied";
import InputField from "../../InputField";
import ModalComponent from "../../ModalComponent";
import "./changepasswordform.scss";
import { changePasswordFormValidation } from "./changePasswordFormValidation";

interface ChangePasswordFormProps {
  visible: boolean;
  closeHandler: () => void;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = (props) => {
  const { visible, closeHandler } = props;
  const [initialValues, setInitialValues] = useState<any>({
    password: "",
    confirmPassword: ""
  });
  const { loading, error, updateVendorProfile } = VendorService()
  const {t} = useTranslation()
  const onSubmit = (values: FormikValues) => {
    const jsonData = serialize(InternalUsersModel, { ...values });
    updateVendorProfile(jsonData, () => {
      closeHandler()
    }
      , () => { }, () => { })
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={changePasswordFormValidation}
    >
      {({ handleSubmit }) => (
        <ModalComponent
          type="small"
          visible={visible}
          closeHandler={closeHandler}
          successProps={{
            title: t("update"),
            clickHandler: handleSubmit,
            loading: loading
          }}
          cancelProps={{
            title: t("discard"),
            clickHandler: closeHandler,
          }}
          title={t("change_password")}
        >
          <div className="profile-edit-form">
            <Form>
              <InputField
                className="filter-field"
                name="password"
                label="New Password"
                placeholder="Enter"
                type="password"
              />
              <InputField
                className="filter-field"
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Enter"
                type="password"
              />
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
