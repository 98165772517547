import React, { FC, useState } from "react";
import "./purchaseSummaryCard.scss";
import { Col, Divider, Row, Space } from "antd";
import { currencyString } from "../../..";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";
import CardSkeletonComponent from "./../../../../../shared/components/CardSkeletonComponent";
import CardComponent from "./../../../../../shared/components/CardComponent";
import { useTranslation } from "react-i18next";
import { BadgeTypesDefinition } from "../../../../../definitions/badgeTypesDefinition";
import { BadgeTypes } from "../../../../../enums/badgeTypes";

interface PurchaseSummaryCardProps {
  purchase: PurchaseModel | undefined;
  loading: boolean;
}

const PurchaseSummaryCard: FC<PurchaseSummaryCardProps> = (props) => {
  const { purchase, loading } = props;
  const { t } = useTranslation()
  return loading ? (
    <CardSkeletonComponent className="purchase-summary-card-skeleton" rows={8} />
  ) : (
    <div className="purchase-summary-card">
      <div className="purchase-amount__container">
        <h3 className="title">
          Purchase Amount
        </h3>
        <h2 className="amount">
          {currencyString} {purchase?.totalAmount?.toLocaleString()}
        </h2>
      </div>
      <div className="purchase-summary-items-total__container">
        <div className="item">
          <div className="label">Purchase Amount</div>
          <div className="value">
            {purchase?.totalAmount}
          </div>
        </div>
        <div className="item">
          <div className="label">Rise vendor fee</div>
          <div className="value">
            {/* TODO data unavailable */}
            {"-"}
          </div>
        </div>
        <div className="item">
          <div className="label">14% VAT on Rise vendor fee</div>
          <div className="value">
            {/* TODO data unavailable */}
            {"-"}
          </div>
        </div>
      </div>
      <Divider dashed />
      <div className="purchase-summary-total__container">
        <div className="item">
          <div className="label">Settlement amount</div>
          <div className="value">
            {/* TODO data unavailable */}
            {"-"}
          </div>
        </div>
        <div className="item">
          <div className="label">Settlement ID</div>
          <div className="value status">
            {purchase?.vendorSettlementStatus === BadgeTypes.PENDING_SETTLEMENTS ? BadgeTypesDefinition[purchase?.vendorSettlementStatus as BadgeTypes] : "-"}
          </div>
        </div>
        <div className="item">
          <div className="label">Settlement date</div>
          <div className="value">
            {/* TODO data unavailable */}
            {"-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSummaryCard;
