import { serializable, alias, object, list, primitive } from "serializr";
import { BadgeTypes } from "../../enums/badgeTypes";
import { VendorTypes } from "../../enums/vendorTypes";
import { AttachmentModel } from "../Attachment/attachment.model";
import { MetaModel } from "../Meta/meta.model";
import { VendorInstoreModel } from "../Vendor/Instore/instore.model";

const {BOTH, ONLINE, INSTORE} = VendorTypes
class PurchaseListVendorModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("logo_url", primitive()))
  logoUrl?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("mode", primitive()))
  mode?: string;

  @serializable(alias("instore", object(VendorInstoreModel)))
  instore?: VendorInstoreModel;
}

export class PurchaseListFilterModel {

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("purchase_status", primitive()))
  purchaseStatus?: string;

  @serializable(alias("vendor_id", primitive()))
  vendorId?: string;

  @serializable(alias("vendor_name", primitive()))
  vendorName?: string;

  @serializable(alias("customer_payment_status", primitive()))
  customerPaymentStatus?: string;

  @serializable(alias("national_id", primitive()))
  nationalId?: number;

  @serializable(alias("search", primitive()))
  search?: string;

  @serializable(alias("page", primitive()))
  page?: number;
}

export class PurchaseListModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("purchase_id", primitive()))
  purchaseId?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("vendor", object(PurchaseListVendorModel)))
  vendor?: PurchaseListVendorModel;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;

  @serializable(alias("customer_payment_status", primitive()))
  customerPaymentStatus?: string;

  @serializable(alias("purchase_status", primitive()))
  purchaseStatus?: string;


}

export class PurchaseItemModel {

  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('no_of_items', primitive()))
  noOfItems?: number;

  @serializable(alias('amount', primitive()))
  amount?: number;

}
export class PurchasePaymentLogModel {

  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: string;

  @serializable(alias('payment_mode', primitive()))
  paymentMode?: string;

  @serializable(alias('transaction_id', primitive()))
  transactionId?: string;

  @serializable(alias('amount', primitive()))
  amount?: number;

  @serializable(alias('is_amount_negetive', primitive()))
  isAmountNegative?: boolean;
}

export class PurchaseVendorSettlementModel {

  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('amount', primitive()))
  amount?: number;

  @serializable(alias('purchase_status', primitive()))
  purchaseStatus?: BadgeTypes;

  @serializable(alias('purchase_id', primitive()))
  purchaseId?: string;

  @serializable(alias('transaction_id', primitive()))
  transactionId?: string;

  @serializable(alias('mode', primitive()))
  mode?: typeof BOTH|typeof ONLINE|typeof INSTORE;

  @serializable(alias('created_at', primitive()))
  createdAt?: string;

  @serializable(alias('is_amount_negetive', primitive()))
  isAmountNegative?: boolean;
}

export class PurchaseCustomerModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("nation_id_number", primitive()))
  nationalId?: number;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias('country_code', object(MetaModel)))
  countryCode?: MetaModel;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("customer_payment_status", primitive()))
  customerPaymentStatus?: BadgeTypes;

  @serializable(alias("profile_pic", object(AttachmentModel)))
  profilePic?: AttachmentModel;
}

export class PurchaseInstoreModel {

  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('name', primitive()))
  name?: string;

	@serializable(alias('address', primitive()))
	address?: string;

	@serializable(alias('lat', primitive()))
	lat?: string;

	@serializable(alias('long', primitive()))
	long?: string;

}

export class PurchaseVendorModel {

  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('logo', object(AttachmentModel)))
  logo?: AttachmentModel;

  @serializable(alias('logo_url', primitive()))
  logoUrl?: string;

  @serializable(alias('name', primitive()))
  name?: string;

  @serializable(alias('mode', primitive()))
  mode?: typeof BOTH|typeof ONLINE|typeof INSTORE;

  @serializable(alias('type', primitive()))
  type?: string;

  @serializable(alias('vendor_settlement_status', primitive()))
  vendorSettlementStatus?: BadgeTypes;

  @serializable(alias('instore', object(PurchaseInstoreModel)))
  instore?: PurchaseInstoreModel;

}

export class PurchaseOutstandingPaymentModel {

  @serializable(alias("due_date", primitive()))
  dueDate?: string;

  @serializable(alias("minimum_due", primitive()))
  minimumDue?: number;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;

}

export class PurchaseReturnSummaryModel {

  @serializable(alias("return_recipt", object(AttachmentModel)))
  returnReceipt?: AttachmentModel;

  @serializable(alias("return_recipt_id", primitive()))
  returnReceiptId?: string;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;

  @serializable(alias('return_items', list(object(PurchaseItemModel))))
  returnItems?: PurchaseItemModel[] = [];

  @serializable(alias('created_at', primitive()))
  createdAt?: string;

}

export class PurchaseModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("purchase_id", primitive()))
  purchaseId?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("customer", object(PurchaseCustomerModel)))
  customer?: PurchaseCustomerModel;

  @serializable(alias("vendor", object(PurchaseVendorModel)))
  vendor?: PurchaseVendorModel;

  @serializable(alias("purchase_items", list(object(PurchaseItemModel))))
  purchaseItems?: PurchaseItemModel[] = [];

  @serializable(alias("outstanding_payments", object(PurchaseOutstandingPaymentModel)))
  outstandingPayments?: PurchaseOutstandingPaymentModel;

  @serializable(alias("customer_processing_fee", primitive()))
  customerProcessingFee?: number;

  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;

  @serializable(alias("purchase_status", primitive()))
  purchaseStatus?: string;

  @serializable(alias("payment_logs", list(object(PurchasePaymentLogModel))))
  paymentLogs?: PurchasePaymentLogModel[] = [];

  @serializable(alias("vendor_settlements", list(object(PurchaseVendorSettlementModel))))
  vendorSettlements?: PurchaseVendorSettlementModel[] = [];

  @serializable(alias("return_summary", object(PurchaseReturnSummaryModel)))
  returnSummary?: PurchaseReturnSummaryModel;

	@serializable(alias('return_purchase_status', primitive()))
	returnPurchaseStatus?: string;

	@serializable(alias('vendor_settlement_status', primitive()))
	vendorSettlementStatus?: string;

}
