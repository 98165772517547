import { Dropdown, Menu, Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import React, { FC, ReactNode } from "react";
import { handleBeforeImageUpload } from "../../utils/imageHelper";
import IconComponent from "../IconComponent";
import "./menucomponent.scss";

interface MenuComponentProps {
  menu: {
    title: ReactNode;
    icon?: ReactNode;
    clickHandler?: () => void;
    upload?: {
      accept: string;
      handleChange?: (info: UploadChangeParam) => void;
    };
  }[];
  dropdownClassName?: string;
  overlayClassName?: string;
  iconColor?: "primary" | "secondary";
  type?: string;
}

interface UploadComponentProps {
  file: RcFile;
  fileUrl: string;
}

const MenuComponent: FC<MenuComponentProps> = (props) => {
  const {
    menu,
    dropdownClassName,
    overlayClassName,
    iconColor = "primary",
    children,
    type = ""
  } = props;

  const handleChange = (
    info: UploadChangeParam,
    menu: MenuComponentProps["menu"][0]
  ): any => {
    if (menu?.upload?.handleChange && info.file.status !== "uploading" && info.file.originFileObj) {
        menu?.upload?.handleChange(info);
    }
  };

  const handleBeforeUpload = (file: File) => {
    const acceptedTypes = menu?.flatMap(menu => menu?.upload?.accept || []).join(",").split(", ");
    return handleBeforeImageUpload(file, acceptedTypes, type)
  };

  return (
    <Dropdown
      getPopupContainer={(trigger:HTMLElement) => trigger?.parentNode as HTMLElement}
      className={`menu-component ${iconColor} ${dropdownClassName} `}
      trigger={["hover"]}
      overlay={
        <Menu className={`menu-component-overlay ${overlayClassName}`}>
          {menu?.map((value, index) => {
            return value?.upload ? (
              <Upload
                beforeUpload={handleBeforeUpload}
                accept={value?.upload?.accept}
                showUploadList={false}
                onChange={(info) => {
                  handleChange(info, value);
                }}
              >
                <Menu.Item icon={value?.icon}>{value?.title}</Menu.Item>
              </Upload>
            ) : (
              <Menu.Item icon={value?.icon} onClick={value?.clickHandler}>
                {value?.title}
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <div>{children ?? <IconComponent name="icon-ellipsis" pointer/>}</div>
    </Dropdown>
  );
};

export default MenuComponent;
