import { Col, Divider, Input, Row, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { ErrorMessage, FieldArray, Form, Formik } from 'formik'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BadgeTypes } from '../../../../../enums/badgeTypes'
import { VendorInstoreCreationModel, VendorInstoreModel } from '../../../../../models/Vendor/Instore/instore.model'
import MetaService from '../../../../../services/MetaService/meta.service'
import VendorService from '../../../../../services/VendorService/vendor.service'
import ButtonComponent from '../../../../../shared/components/ButtonComponent'
import CardComponent from '../../../../../shared/components/CardComponent'
import CardSkeletonComponent from '../../../../../shared/components/CardSkeletonComponent'
import Error from '../../../../../shared/components/Error'
import IconComponent from '../../../../../shared/components/IconComponent'
import InputField from '../../../../../shared/components/InputField'
import MapComponent from '../../../../../shared/components/MapComponent'
import TableComponent from '../../../../../shared/components/TableComponent'
import { countryCodesInitialValue, generateCodeOptions, getCountryIsdCode } from '../../../../../shared/utils/generateCountryCodeOptions'
import { isdCodes } from '../../../../../shared/utils/isdCodes'
import { vendorInstoreFormValidation } from '../VendorInstoreForm/vendorInstoreFormValidation'
import "./instoreFormView.scss"
import backIcon from "./../../../../../assets/images/back-icon.png"
import minusIcon from "./../../../../../assets/images/minus-icon.png"



const InstoreFormView: FC = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const location = useLocation()
    const data = location?.state as VendorInstoreModel
    const [initialValues, setInitialValues] =
        useState<VendorInstoreCreationModel>({} as VendorInstoreCreationModel);
    const { updateVendorInstore, createVendorInstore, loading, fetchVendorInstoreList } = VendorService();
    const { getCountryCodesMeta, countryCodesMeta, loading: metaLoading } = MetaService();
    const { vendorId } = useParams();
    const formRef = useRef<any>(null);
    const isEdit = Object.entries(data)?.length > 1
    useEffect(() => {
        setInitialValues(
            isEdit ? { ...data, countryCodeId: data?.countryCodeId ?? countryCodesInitialValue(countryCodesMeta) } : { ...new VendorInstoreCreationModel(), status: BadgeTypes.ACTIVE, countryCodeId: countryCodesInitialValue(countryCodesMeta) }
        );
    }, [countryCodesMeta]);

    const resetAndGoback = () => {
        formRef?.current?.resetForm({ values: {} });
        goBack()
    }

    const onSubmit = (values: VendorInstoreCreationModel) => {
        if (data?.id) {
            updateVendorInstore(data?.id, values, ()=>navigate(-1));
        } else {
            createVendorInstore(values, resetAndGoback);
        }
    };

    const goBack = () => navigate(-1)

    useEffect(() => {
        getCountryCodesMeta()
    }, [])


    return (
        <div className="instore-form-view">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={onSubmit}
                innerRef={formRef}
                validationSchema={vendorInstoreFormValidation}
            >
                {({ values, setFieldValue, handleSubmit, errors }) => (
                    <Form>
                        <div className="header">
                            <Row>
                                <Col span={18}>
                                    <h2 className='title'>
                                        <span className="back-icon" onClick={goBack}>
                                            <img src={backIcon} alt="back" className="back-icon-image" />
                                        </span>
                                        <span className="text">
                                            {isEdit ? "Edit Instore" : "Add new store"}
                                        </span>
                                    </h2>
                                </Col>
                                <Col span={6}>
                                    <div className="controllers">
                                        <ButtonComponent
                                            type="default"
                                            additionalTypes="secondary"
                                            size="fixed"
                                            onClick={goBack}
                                        >
                                            Discard
                                        </ButtonComponent>
                                        <ButtonComponent
                                            type={"primary"}
                                            htmlType={"submit"}
                                            size="fixed"
                                        >
                                            {isEdit ? "Update" : "Create"}
                                        </ButtonComponent>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="content">
                        {metaLoading ? <CardSkeletonComponent rows={20} /> : (
                            <div className='form-section'>
                                <div className="vendor-instore-form">
                                    <Row gutter={16}>
                                        <Col className="filter-field" span={24}>
                                            <InputField
                                                label="Instore Name"
                                                name="name"
                                                placeholder="Enter"
                                            />
                                        </Col>
                                        <Col className="filter-field" span={12}>
                                            <InputField
                                                label="Contact Person First Name"
                                                name="contactFirstName"
                                                placeholder="Enter"
                                            />
                                        </Col>
                                        <Col className="filter-field" span={12}>
                                            <InputField
                                                label="Contact Person Last Name"
                                                name="contactLastName"
                                                placeholder="Enter"
                                            />
                                        </Col>
                                        <Col className="filter-field" span={12}>
                                            <InputField label="Email" name="email" placeholder="Enter" />
                                        </Col>
                                        <Col className="filter-field" span={12}>
                                            <InputField
                                                addonBefore={<Select
                                                    value={values?.countryCodeId ?? ''}
                                                    onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                                                    if(typeof value !== "string"){
                                                        setFieldValue("countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                                                        setFieldValue("countryCodeId", value)
                                                    } 
                                                    setFieldValue("mobileNumber", undefined)
                                                    }}
                                                    options={generateCodeOptions(countryCodesMeta)}
                                                    />
                                                }
                                                name="mobileNumber"
                                                label="Phone Number"
                                                placeholder="Enter" />
                                            {/* TODO: Needed for production */}
                                            {/* <InputField prefix='+20' type="text" name="mobileNumber" label='Mobile Number' placeholder="Enter" /> */}
                                        </Col>
                                        <Col className="filter-field" span={24}>
                                            <InputField
                                                label="Address"
                                                name="address"
                                                placeholder="Enter"
                                            />
                                        </Col>
                                        <Col className="filter-field" span={24}>
                                            <MapComponent
                                                position={
                                                    values?.lat && values?.long
                                                        ? {
                                                            lat: values?.lat ?? 0,
                                                            lng: values?.long ?? 0,
                                                        }
                                                        : undefined
                                                }
                                                onChange={(position) => {
                                                    setFieldValue("lat", position?.lat);
                                                    setFieldValue("long", position?.lng);
                                                }}
                                            />
                                            <ErrorMessage name={"lat"}>
                                                {(message: string) => <Error message={message} />}
                                            </ErrorMessage>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )}
                        <Divider/>
                            <CardComponent className='terminal-section'>
                                <div className="vendor-terminal-id-container">
                                    <h3>POS Terminal ID</h3>
                                    <p>Enter the POS machine ID received from Fawry</p>
                                    <div className="ant-table">
                                        <div className="ant-table-container">
                                            <table>
                                                <thead className="ant-table-thead">
                                                    <tr>
                                                        <th className="ant-table-cell">LABEL</th>
                                                        <th className="ant-table-cell">POS TERMINAL ID</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                    <FieldArray name='fawryTerminalIds'
                                        render={(arrayHelpers) => (
                                            <div className="">
                                                {values?.fawryTerminalIds?.map((terminal, index) => (
                                                    <Row className={`input-row ${terminal?.id && "disabled"}`} align="top">
                                                        <Col span={7}>
                                                            <InputField name={`fawryTerminalIds.${index}.label`} placeholder='Enter Label' />
                                                        </Col>
                                                        <Col offset={1} span={7}>
                                                            <InputField name={`fawryTerminalIds.${index}.terminalId`} placeholder='Enter Terminal ID' />
                                                        </Col>
                                                        <Col offset={5} span={2}>
                                                            <p className='remove-icon' onClick={() => arrayHelpers.remove(index)}> <img src={minusIcon} alt="minus" className='minus-icon'/> </p>
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <p className='add-btn' onClick={() => arrayHelpers.push({ label: "", terminalId: "" })}>+ add</p>
                                            </div>
                                        )} />
                                </div>
                            </CardComponent>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default InstoreFormView