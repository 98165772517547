import { useMemo, useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { MetaModel } from "../../models/Meta/meta.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import countryList from 'react-select-country-list'

const MetaService = () => {
  const [error, setError] = useState<Error>();
  const [categories, setCategories] = useState<MetaModel[]>([]);
  const [subCategories, setSubCategories] = useState<MetaModel[]>([]);
  const [walletTypesMeta, setWalletTypesMeta] = useState<MetaModel[]>([])
  const [countryCodesMeta, setCountryCodesMeta] = useState<MetaModel[]>([])
  const [loading, setLoading] = useState(false);

  const allCountryCodes = useMemo(() => countryList().getData(), [])

  const setCountryCodeFlag = (countryCodes:MetaModel[]) => {
    const modifiedData = countryCodes.map((countryCode) =>{ return  {...countryCode, flag: countryCode.flag===allCountryCodes.find((value)=>value.label===countryCode.flag as string)} })
    return modifiedData
  }

  const fetchCategoryList = () => {
    setLoading(true);

    axiosInstance
      .get(ApiRoutes.META_CATEGORY)
      .then((response) => {
        const meta = deserialize(
            MetaModel,
            response?.data?.categories
          ) as MetaModel[];
        setCategories(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchSubCategoryList = (categoryId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.META_SUB_CATEGORY, {
      categoryId: categoryId?.toString(),
    });
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const meta = deserialize(
            MetaModel,
            response?.data?.categories
          ) as MetaModel[];
        setSubCategories(meta);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getWalletTypesMeta = () => {
		setLoading(true);
		return axiosInstance
			.get(ApiRoutes.META_WALLET_TYPES)
			.then((response) => {
				const walletTypes = deserialize(MetaModel, response.data["wallet_types"]);
				setWalletTypesMeta(walletTypes as MetaModel[])
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

  const getCountryCodesMeta = () => {
		setLoading(true);
		return axiosInstance
			.get(ApiRoutes.META_COUNTRY_CODES)
			.then((response) => {
				const countryCodes = deserialize(MetaModel, response.data["country_codes"]);
        // TODO needed for next phase
        // const data = setCountryCodeFlag(countryCodes as MetaModel[])
				setCountryCodesMeta(countryCodes as MetaModel[])
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

  return {
    loading,
    error,
    categories,
    subCategories,
    fetchCategoryList,
    fetchSubCategoryList,
    getWalletTypesMeta, 
    walletTypesMeta,
    getCountryCodesMeta,
    countryCodesMeta
  };
};

export default MetaService;
