import { Checkbox, Col, Divider, Radio, Row } from 'antd'
import { Form, Formik, FormikValues } from 'formik'
import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { VendorTypes } from '../../../enums/vendorTypes'
import { PaymentDetails, Vendor, VendorModel } from '../../../models/Vendor/vendor.model'
import AuthService from '../../../services/AuthService/auth.service'
import MetaService from '../../../services/MetaService/meta.service'
import S3AttachmentService from '../../../services/S3UploadService/s3upload.service'
import ButtonComponent from '../../../shared/components/ButtonComponent'
import ColorPickerComponent from '../../../shared/components/ColorPickerComponent'
import DropdownField from '../../../shared/components/DropdownField'
import InputColorField from '../../../shared/components/InputColorField'
import InputField from '../../../shared/components/InputField'
import UploadComponent from '../../../shared/components/UploadComponent'
import { businessDetailsValidation } from './businessDetailsValidation'

interface BusinessDetailsFormProps {
  nextHandler: any
}

const BusinessDetailsForm: FC<BusinessDetailsFormProps> = (props) => {
  const { nextHandler } = props
  const { loading, error, addVendorDetails } = AuthService()
  const { uploadAttachment } = S3AttachmentService()
  const { t } = useTranslation()
  const { fetchCategoryList, categories, fetchSubCategoryList, subCategories, getWalletTypesMeta, walletTypesMeta } = MetaService()
  const onSubmit = (values: FormikValues) => {
    addVendorDetails(values, () => {
      nextHandler()
    })
  }
  const initialValues = {
    paymentDetails: { ...new PaymentDetails() },
    vendorDetails: {
      ...new VendorModel(),
      type: "offline"
    }
  }

  useEffect(() => {
    fetchCategoryList()
    getWalletTypesMeta()
  }, [])

  return (
    <div className='business-details-form__wrappper'>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={businessDetailsValidation}
      >
        {({ values, setFieldValue, errors, touched, dirty, isValid }) => (
          <Form className='business-details-form'>
            <Row>
              <h2 className='business-details-form--header'>
                {t("business_details")}
              </h2>
              <Col span={24}>
                <div className="business-details-form--field">
                  <div className="" style={{ width: "fit-content" }}>
                    <UploadComponent
                      fileUrl={values?.vendorDetails?.logo?.s3Url}
                      fileId={values?.vendorDetails?.logoId ?? ""}
                      fileType={values?.vendorDetails?.logo?.fileType ?? ""}
                      fileName={values?.vendorDetails?.logo?.filename ?? ""}
                      type="logo"
                      title={"Logo"}
                      accept={"image/png, image/jpg, image/jpeg"}
                      onUpload={async (file, fileUrl) => {
                        setFieldValue("vendorDetails.logo.s3Url", fileUrl);
                        const _logo = await uploadAttachment(file);
                        setFieldValue("vendorDetails.logoId", _logo);
                      }}
                      onRemove={() => {
                        setFieldValue("vendorDetails.logo.s3Url", undefined);
                        setFieldValue("vendorDetails.logoId", undefined);
                      }}
                      className="upload_return_reciept"
                      error={(errors?.vendorDetails as VendorModel)?.logoId}
                    />
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Business Name' name='vendorDetails.name' />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <DropdownField
                    label="Category"
                    options={categories}
                    name="vendorDetails.categoryId"
                    placeholder="Select"
                    onChange={(value) => {
                      setFieldValue("vendorDetails.categoryId", value);
                      if (value !== values?.vendorDetails?.categoryId) {
                        setFieldValue("vendorDetails.subCategoryId", undefined);
                      }
                      fetchSubCategoryList(value)
                    }}
                    value={values?.vendorDetails?.categoryId}
                  />
                </div>
              </Col>
              {/* TODO: required for next phase */}
              {/* <Col span={24}>
                <div className="business-details-form--field">
                  <DropdownField
                    label="Sub Category"
                    options={subCategories}
                    name="vendorDetails.subCategoryId"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("vendorDetails.subCategoryId", value)}
                    value={values?.vendorDetails?.subCategoryId}
                  />
                </div>
              </Col> */}
              <Col span={24}>
                <div className="business-details-form--field">
                  <label className='label'>Type</label>
                  <Radio.Group onChange={(e) => setFieldValue("vendorDetails.type", e.target?.value)} value={values?.vendorDetails?.type}>
                    <Radio value={VendorTypes.ONLINE}>{VendorTypes.ONLINE}</Radio>
                    <Radio value={VendorTypes.INSTORE}>{VendorTypes.INSTORE}</Radio>
                    <Radio value={VendorTypes.BOTH}>{VendorTypes.BOTH}</Radio>
                  </Radio.Group>
                  {dirty && !values?.vendorDetails?.type && <div className="input__error mt-2">Type is required</div>}
                </div>
              </Col>
              {
                (values?.vendorDetails?.type == "both" || values?.vendorDetails?.type == "online") && (
                  <Col span={24}>
                    <div className="business-details-form--field">
                      <InputField placeholder='Enter' label='Website URL' name='vendorDetails.websiteUrl' />
                    </div>
                  </Col>
                )
              }
              {
                (values?.vendorDetails?.type == "both" || values?.vendorDetails?.type == "offline") && (
                  <Col span={24}>
                    <div className="business-details-form--field">
                      <InputField placeholder='Enter' label='# of Physical Stores' name='vendorDetails.noOfPhysicalStores' />
                    </div>
                  </Col>
                )
              }
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Monthly Transaction Volume' name='vendorDetails.monthlyTransactionVolume' />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <ColorPickerComponent
                    label="Brand Colour"
                    value={values?.vendorDetails?.brandColour ?? "#0b0a10"}
                    onChange={(hexCode) =>
                      setFieldValue("vendorDetails.brandColour", hexCode)
                    }
                  />
                </div>
              </Col>
              {/* TODO need confirmation as it is not there in new design */}
              {/* <Col span={24}>
                <div className="business-details-form--field">
                  <Checkbox onChange={(e) => setFieldValue("vendorDetails.isExclusivityWithDeall", e.target.checked)}>{t("interested_in_exclusivity_with_deall")}</Checkbox>
                </div>
              </Col> */}

              <Divider />

              <h2 className='business-details-form--header'>
                {t("payment_details")}
              </h2>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Account Holder Name' name='paymentDetails.accountHolderName' />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Account Holder Address' name='paymentDetails.address' />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Account Number' name='paymentDetails.accountNumber' />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='IBAN' prefix='EG' name='paymentDetails.iban' />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Swift Code' name='paymentDetails.swiftCode' />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Branch' name='paymentDetails.branch' />
                </div>
              </Col>
              {/* TODO E-wallet integration */}
              {/* <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Mobile Wallet Number' name='paymentDetails.walletNumber' onChange={(value)=>{
                        setFieldValue("paymentDetails.walletNumber", value)
                        !value && setFieldValue("paymentDetails.walletTypeId", "")
                      }}/>
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <DropdownField
                    disabled={!values?.paymentDetails?.walletNumber}
                    label="Wallet Type"
                    options={walletTypesMeta}
                    name="paymentDetails.walletTypeId"
                    placeholder="Select"
                    onChange={(value) => setFieldValue("paymentDetails.walletTypeId", value)}
                    value={values?.paymentDetails?.walletTypeId ?? values?.paymentDetails?.walletType?.id ?? null}
                  />
                </div>
              </Col> */}

              <Col span={24}>
                <div className="business-details-form--field">
                  <ButtonComponent
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="auth-form__btn"
                    size="wide"
                  > Next </ButtonComponent>
                </div>
              </Col>
            </Row>

          </Form>
        )}
      </Formik>
    </div>
  )
}

export default BusinessDetailsForm