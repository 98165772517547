import React from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import PasswordResetImage from "../../../assets/images/password_reset_link_sent.png";
import "./passwordResetLinkSent.scss"
import { useTranslation } from "react-i18next";

interface User {
    email: string;

}

const initialValue = {
    email: ""
};
const PasswordResetLink = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const onSubmit = (user: User) => {
        navigate(AppRoutes.LOGIN)
    };
    return (
        <div className="password-reset-link-sent">
            <div className='form_heading'>
                <h1>
                {t("password_reset_link_sent")}
                </h1>
            </div>
            <div className="info_para">
                <p className='info_para1'>{t("check_your_email_for_the_password_reset_link")}</p>
                <p className='info_para1'>{t("make_sure_to_check_spam")}</p>
            </div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
            >
                <Form>
                    <div className="password_reset_link_div">
                        <img src={PasswordResetImage} className="password-reset-link-img mail__img" />
                    </div>

                    <ButtonComponent type="primary"
                        htmlType="submit"
                        className="auth-form__btn"
                        size="wide"

                    > {t("proceed_to_login")} </ButtonComponent>


                </Form>
            </Formik>
        </div>
    )
}

export default PasswordResetLink;