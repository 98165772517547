import { Col, Row, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { Form, Formik, FormikValues } from 'formik'
import React, { FC, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { InternalUsersModel } from '../../../models/InternalUsers/internalusers.model'
import AuthService from '../../../services/AuthService/auth.service'
import MetaService from '../../../services/MetaService/meta.service'
import ButtonComponent from '../../../shared/components/ButtonComponent'
import InputField from '../../../shared/components/InputField'
import { countryCodesInitialValue, generateCountryCodeOptions } from '../../../shared/utils/generateCountryCodeOptions'
import { contactDetailsValidation } from './contactDetailsValidation'

interface ContactDetailsFormProps {
  nextHandler: any
}

const ContactDetailsForm: FC<ContactDetailsFormProps> = (props) => {
  const { nextHandler } = props
  const { getCountryCodesMeta, countryCodesMeta } = MetaService()
  const { loading, error, updateContactDetails } = AuthService()
  const { t } = useTranslation()
  const onSubmit = (values: FormikValues) => {
    updateContactDetails(values as InternalUsersModel, () => {
      nextHandler()
    })
  }
  const initialValues = {
    email: "",
    mobileNumber: "",
    countryCodeId: countryCodesInitialValue(countryCodesMeta),
    isdCode: "+20"
  }
  useEffect(() => {
    getCountryCodesMeta()
  }, [])
  return (
    <div className='contact-details-form__wrappper'>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={contactDetailsValidation}
        enableReinitialize
      >
        {({ values, setFieldValue, dirty, isValid }) => (
          <Form className='contact-details-form'>
            <Row>
              <h2 className='business-details-form--header'>
                {t("business_contact_details")}
              </h2>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField placeholder='Enter' label='Email' name='email' />
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field">
                  <InputField
                    addonBefore={<Select
                      onChange={(value:{ value: string, label: string[] }|string) => {
                        if(typeof value !== "string"){
                          setFieldValue("isdCode", value?.label?.[2])
                          setFieldValue("countryCodeId", value?.value)
                        }
                        setFieldValue("mobileNumber", undefined)
                    }}
                    labelInValue
                      value={values?.countryCodeId}>
                      {generateCountryCodeOptions(countryCodesMeta)}
                    </Select>}
                    name="mobileNumber"
                    label="Phone Number"
                    placeholder="Enter" />
                    {/* TODO: Needed for production */}
                  {/* <InputField prefix='+20' placeholder='Enter' label='Mobile Number' name='mobileNumber' /> */}
                </div>
              </Col>
              <Col span={24}>
                <div className="business-details-form--field contact-next-button">
                  <ButtonComponent
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="auth-form__btn"
                    size="wide"
                  > {t("next")} </ButtonComponent>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ContactDetailsForm