import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Row, Col } from 'antd';


import riseLogo from '../../assets/images/rise-logo4x.png';
import hummingBird from '../../assets/images/humminbird.png';
import registerImage from '../../assets/images/register-image.png';
import "./auth.scss"

const AuthRouter = () => {
  const location = useLocation()
  const isRegisterScreen = location?.pathname?.split('/')[2] === "register"
  const isOnBoardingScreen = location?.pathname?.split('/')[2] === "business-details"

  return (
    <div className="auth-router">
      <Row>
        <Col xs={24} xl={13} sm={13}>
          <div className="landing-image__container">
            <img src={riseLogo} alt="logo" className="logo"/>
            <div className="content">
              <img src={isRegisterScreen ? registerImage : hummingBird} alt="bird" className={`${isRegisterScreen ? "register" : "bird"}`}/>
            </div>
          </div>
        </Col>
        <Col xs={24} xl={11} sm={11}>
          <div className= {`auth_main_content ${(isOnBoardingScreen || isRegisterScreen) && "block"}`}>
            <Outlet />
          </div>
        </Col>
      </Row>


    </div>
  );
};

export default AuthRouter;
