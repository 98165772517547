import React from "react";
import { Formik, Form, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import { Link } from "react-router-dom";

import { Button, Select } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { AuthContext } from "../../../context/AuthContext";
import { DownloadOutlined } from '@ant-design/icons';
import ButtonComponent from "../../../shared/components/ButtonComponent";
import IconComponent from "../../../shared/components/IconComponent";

import "./newvendoraccount.scss"
import { newVendorAccountValidation } from "./newVendorAccountValidation";
import { useTranslation } from "react-i18next";
import { isdCodes } from "../../../shared/utils/isdCodes";
import { Option } from "antd/lib/mentions";

interface User {
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    mobileno: string;
}

const initialValue = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    isdCode: "+20"
};

const NewVendorAccount = () => {
    const { error, loading, createVendor } = AuthService();
    const navigate = useNavigate()
    const { t } = useTranslation()

    const onSubmit = (vendor: FormikValues) => {
        createVendor(vendor, () => {
            navigate(AppRoutes.VALIDATEOTPEMAIL)
        })
    };
    return (
        <div className="new-vendor-account">
            <div className='form_heading create_heading'>
                <h1>
                    {t("create_new_vendor_account")}
                </h1>
            </div>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={newVendorAccountValidation}
            >
                {({ handleSubmit, setFieldValue, values }) => (

                    <Form>
                        <div className='firstname'>

                            <InputField type="text" label='First Name' name="firstname" placeholder="Enter" />
                        </div>
                        <div className='lastname'>

                            <InputField type="text" label='Last Name' name="lastname" placeholder="Enter" />
                            <p className='para_info'><IconComponent name='icon-info' />  {t("enter_full_last_name_as_stated_in_your_national_id")}</p>
                        </div>
                        <div className='email_part'>

                            <InputField type="email" label='Email' name="email" placeholder="Enter" />
                        </div>
                        <div className='mobile'>
                            <InputField
                                addonBefore={<Select
                                    onChange={(value) => {
                                        setFieldValue("isdCode", value)
                                        setFieldValue("mobileNumber", undefined)
                                    }}
                                    value={values?.isdCode}>
                                    {isdCodes.map((option) => (
                                        <Option value={option.code}>{option.flag}{" "}{option.code}</Option>
                                    ))}
                                </Select>}
                                name="mobileNumber"
                                label="Phone Number"
                                placeholder="Enter" />
                                {/* TODO: Needed for production */}
                            {/* <InputField prefix='+20' type="text" name="mobileNumber" label='Mobile Number' placeholder="Enter" /> */}
                        </div>
                        <div className='password_part'>

                            <InputField
                                type="password"
                                name="password"
                                placeholder="Enter"
                                label='Password'
                            />
                        </div>
                        <div className='login_button'>
                            <ButtonComponent type="primary"
                                htmlType="submit"
                                className="auth-form__btn"
                                size="wide"

                            > {t("verify_email_mobile")} </ButtonComponent>
                        </div>

                        <div className='have_account'>
                            <p>{t("already_have_an_account")} <Link to="/auth/login">{t("login_here")}</Link> </p>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default NewVendorAccount;