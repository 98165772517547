import { Badge, Col, Divider, Dropdown, Row } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import "./notifications.scss";
import NotificationsReadIcon from "../../../assets/images/notifications.svg";
import NotificationsUnreadIcon from "../../../assets/images/notifications.svg";
import IconComponent from "../IconComponent";
import NotificationService from "../../../services/NotificationService/notification.service";
import { generatePath, useNavigate } from "react-router-dom";
import { NotificationModel } from "../../../models/notification.model";
import { InAppNotificationTypes } from "../../../enums/inAppNotificationTypes";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
interface NotificationsProps { }

const Notifications: FC<NotificationsProps> = (props) => {
  const { } = props;
  const navigate = useNavigate()
  const { loading, error, getNotifications, notifications, updateNotificationIsSeen } = NotificationService()

  const _updateNotification = (notification:NotificationModel) => {
    updateNotificationIsSeen(`${notification?.id}`).then(()=>{
      getNotifications()
    })
    switch (notification?.notificationType) {
      case InAppNotificationTypes.RETURN_STATUS: 
      case InAppNotificationTypes.INSTALLMENT_REMAINDER:
        const purchaseId=notification?.data?.customerPurchase?.id?.toString()
        navigate(generatePath(AppRoutes.PURCHASE_VIEW,{purchaseId}))
        break;
        
      case InAppNotificationTypes.PURCHASE_STATUS:
        navigate(AppRoutes.PURCHASES)
        break;

      case InAppNotificationTypes.INSTORE_STATUS:
        navigate({pathname:AppRoutes.VENDOR_PROFILE, search: "#stores"})
        break;

      default:
        break;
    } 
  }

  useEffect(() => {
    getNotifications()
  }, [])

  const notificationsList = (
    <div className="notifications-list">
      {notifications?.length ?
        notifications?.map((value, index) => {
          return (
            <Fragment>
              <Row
                className={`notification-item ${value?.isSeen ? "read" : "unread"}`}
                onClick={()=>_updateNotification(value)}
              >
                <Col className="notification-icon" span={3}>
                  <img
                    alt=""
                    src={
                      value?.isSeen
                        ? NotificationsReadIcon
                        : NotificationsUnreadIcon
                    }
                  />
                </Col>
                <Col className="notification-details" span={19}>
                  <div className="title">{value?.body}</div>
                  <div className="date">{value?.createdAt && new Date(value?.createdAt)?.toDateString()}</div>
                </Col>
              </Row>
              <Divider />
            </Fragment>
          );
        }) :
        <div className="empty-view">
          <img src={NotificationsReadIcon} alt="" className="empty-img" />
          <p className="empty-text">{"No notifications"}</p>
        </div>
      }
    </div>
  );
  return (
    <Dropdown
      className="notifications"
      trigger={["click"]}
      overlay={notificationsList}
    >
      <Badge className="notification-badge" count={notifications?.length ?? 0}>
        <IconComponent name="icon-bell-blue" />
      </Badge>
    </Dropdown>
  );
};

export default Notifications;
