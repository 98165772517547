import React, { FC, Fragment, useEffect, useState } from "react";
import "./vendorInstores.scss";
import { Space } from "antd";
import VendorInstoreForm from "./VendorInstoreForm";
import VendorInstoreDetails from "./VendorInstoreDetails";
import { generatePath, Navigate, useNavigate, useParams } from "react-router-dom";
import { VendorInstoreModel } from "../../../../models/Vendor/Instore/instore.model";
import { deserialize } from "serializr";
import ModalComponent from "../../../../shared/components/ModalComponent";
import TableComponent from "../../../../shared/components/TableComponent";
import { tableSorter } from "../../../../shared/utils/tableSorter";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import IconComponent from "../../../../shared/components/IconComponent";
import VendorService from "../../../../services/VendorService/vendor.service";
import { useTranslation } from "react-i18next";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
interface VendorInstoresProps { }

const VendorInstores: FC<VendorInstoresProps> = (props) => {
  const { } = props;
  const navigate = useNavigate()
  const [isVendorInstoreFormShown, setIsVendorInstoreFormShown] =
    useState(false);
  const [isVendorInstoreDetailsShown, setIsVendorInstoreDetailsShown] =
    useState(false);
  const [isVendorInstoreDeleteAlertShown, setIsVendorInstoreDeleteAlertShown] =
    useState(false);
  const [currentInstore, setCurrentInstore] = useState<VendorInstoreModel>();
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    fetchVendorInstoreList,
    vendorInstores,
    deleteVendorInstore,
    loading,
    paginations
  } = VendorService();
  const { t } = useTranslation()
  const { vendorId } = useParams();
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const [search, setSearch] = useState("")
  const [page, setPage] = useState(1)

  useEffect(() => {
    fetchVendorInstoreList();
  }, []);

  const handleRefresh = () => {
    fetchVendorInstoreList();
  }

  const handlePageChange = (page: number) => {
    setPage(page)
    fetchVendorInstoreList({ page, ...sortOptions, search })
  }

  return (
    <div className="vendor-instores">
      <ModalComponent
        type="alert"
        visible={isVendorInstoreDeleteAlertShown}
        closeHandler={() => setIsVendorInstoreDeleteAlertShown(false)}
        successProps={{
          title: t("delete"),
          loading: false,
          clickHandler: () => {
            if (currentInstore?.id) {
              setIsDeleting(true);
              deleteVendorInstore(currentInstore?.id)
                ?.then(() => {
                  setIsVendorInstoreDeleteAlertShown(false);
                  setCurrentInstore(undefined);
                  fetchVendorInstoreList()
                })
                .finally(() => {
                  setIsDeleting(false);
                });
            }
            setIsVendorInstoreDeleteAlertShown(false);
          },
        }}
        cancelProps={{
          title: t("close"),
          clickHandler: () => {
            setIsVendorInstoreDeleteAlertShown(false);
            setCurrentInstore(undefined);
          },
        }}
        alert={{
          title: t("are_you_sure_you_want_to_delete_the_instore"),
          buttonType: "negative"
        }}
      />
      <VendorInstoreForm
        data={currentInstore}
        visible={isVendorInstoreFormShown}
        closeHandler={() => {
          setIsVendorInstoreFormShown(false);
          setCurrentInstore(undefined);
        }}
        refreshList={handleRefresh}
      />
      <VendorInstoreDetails
        visible={isVendorInstoreDetailsShown}
        instore={currentInstore}
        closeHandler={() => {
          setIsVendorInstoreDetailsShown(false);
          setCurrentInstore(undefined);
        }}
      />
      <TableComponent
        paginationMeta={paginations}
        handlePageChange={handlePageChange}
        newBtn={{
          clickHandler: () => navigate({ pathname: AppRoutes.VENDOR_INSTORES_CREATE }, { state: { currentInstore } }),
          text: "New Store"
        }}
        onChange={fetchVendorInstoreList}
        onSort={(data) => setSortOptions(data)}
        filtersSorts={{ page, search }}
        loading={loading}
        data={vendorInstores}
        search={{
          placeholder: t("search_instore_by_name"),
          onSearch: (value) => {
            setSearch(value)
            fetchVendorInstoreList({ search: value });
          },
        }}
        columns={[
          {
            dataIndex: "name",
            key: SortColumnTypes.INSTORE_NAME,
            title: "Store name",
            sorter: true,
            render: (text, record) => (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setCurrentInstore(record);
                  navigate({ pathname: generatePath(AppRoutes.VENDOR_INSTORE_VIEW, { instoreId: `${record?.id}` }) }, { state: record })
                }}
              >
                {text}
              </div>
            ),
          },
          {
            dataIndex: "contactFullName",
            key: "contactFullName",
            title: t("contact_person"),
          },
          {
            dataIndex: "mobileNumber",
            key: "mobileNumber",
            title: t("phone_number"),
          },
          {
            dataIndex: "email",
            key: "email",
            title: t("email"),
          },
          {
            dataIndex: "noOfOrders",
            key: SortColumnTypes.INSTORE_NO_OF_ORDERS,
            title: t("#_of_orders"),
            sorter: true
          },
          {
            dataIndex: "status",
            key: SortColumnTypes.VENDOR_STATUS,
            title: t("status"),
            render: (text) => <BadgeComponent type={text === BadgeTypes.APPROVED ? BadgeTypes.ACTIVE : text} />,
            sorter: true
          },
          {
            title: "",
            key: "action",
            render: (_, record) => (
              <div className="vendor-instores-actions">
                {record?.status !== BadgeTypes.PENDING_APPROVAL &&
                  <IconComponent
                    name="icon-edit-blue"
                    onClick={() => {
                      setCurrentInstore(record);
                      navigate({ pathname: generatePath(AppRoutes.VENDOR_INSTORE_EDIT, { instoreId: `${record?.id}` }) }, { state: record })
                    }}
                  />}
                <IconComponent
                  name="icon-trash"
                  onClick={() => {
                    setCurrentInstore(record);
                    setIsVendorInstoreDeleteAlertShown(true);
                  }}
                />
              </div>

            ),
          },
        ]}
      />
    </div>
  );
};

export default VendorInstores;
