import { serializable, alias, primitive, object } from 'serializr';
import { countryCodesInitialValue } from '../../shared/utils/generateCountryCodeOptions';
import { AttachmentModel } from '../Attachment/attachment.model';
import { MetaModel } from '../Meta/meta.model';

export class InternalUsersModel {

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('mobile_number', primitive()))
    mobileNumber?: string;

    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('first_name', primitive()))
    firstName?: string;

    @serializable(alias('last_name', primitive()))
    lastName?: string;

    @serializable(alias("full_name", primitive()))
    fullName?: string;

    @serializable(alias('role', primitive()))
    role?: string;

    @serializable(alias('status', primitive()))
    status?: string;

    @serializable(alias('country_code_id', primitive()))
    countryCodeId?: string;

    @serializable(alias('country_code', object(MetaModel)))
    countryCode?: MetaModel;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

    @serializable(alias('password', primitive()))
    password?: string;

    @serializable(alias("contact_first_name", primitive()))
    contactFirstName?: string;

    @serializable(alias("contact_last_name", primitive()))
    contactLastName?: string;

    @serializable(alias("contact_email", primitive()))
    contactEmail?: string;

    @serializable(alias("contact_mobile_number", primitive()))
    contactMobileNumber?: string;

    @serializable(alias('logo', object(AttachmentModel)))
    logo?: AttachmentModel;

}