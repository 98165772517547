import * as Yup from "yup";
import { REGEX } from "../../../enums/regex";

export const contactDetailsValidation = Yup.object().shape({
  email: Yup.string()
    .email('Email is not valid.')
    .required('Email is required.'),
  mobileNumber: Yup.string()
    .when("isdCode", {
      is: "+20",
      then: Yup.string()
        .matches(REGEX.PHONE.match, REGEX.PHONE.error)
        .required('Phone number is required.')
    })
    .when("isdCode", {
      is: "+91",
      then: Yup.string()
        .matches(REGEX.PHONE_INDIA.match, REGEX.PHONE_INDIA.error)
        .required('Phone number is required.')
    })
    .when("isdCode", {
      is: "+01",
      then: Yup.string()
        .matches(REGEX.PHONE_US.match, REGEX.PHONE_US.error)
        .required('Phone number is required.')
    })
    .when("isdCode", {
      is: "+972",
      then: Yup.string()
        .matches(REGEX.PHONE_ISRAEL.match, REGEX.PHONE_ISRAEL.error)
        .required('Phone number is required.')
    })
})