import React from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { Link } from "react-router-dom";
import { validationSchema } from "./LoginValidation";
import { Button } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { AuthContext } from "../../../context/AuthContext";
import { DownloadOutlined } from '@ant-design/icons';
import ButtonComponent from "../../../shared/components/ButtonComponent";
import { LocalStorage } from "../../../shared/utils/localStorage";
import { useTranslation } from "react-i18next";

interface User {
  email: string;
  password: string;
}

const initialValue = {
  email: "",
  password: "",
};

const LoginForm = (props: any) => {
  const { error, loading, login } = AuthService();
  const navigate = useNavigate()
  const { setAuthenticated, user } = AuthContext();
  const { t } = useTranslation()


  const onSubmit = (user: User) => {
    login(user);
  };

  return (
    <div>
      <div className='form_heading'>
        <h1>
          {t("vendor_login")}
        </h1>
      </div>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>

          <div className='email_part'>
            <InputField type="email" label='Email' name="email" placeholder="Enter email" />
          </div>
          <div className='password_part'>
            <InputField
              label='Password'
              type="password"
              name="password"
              placeholder="Enter password"
            />
          </div>
          <div className='login_button'>
            <ButtonComponent type="primary"
              htmlType="submit"
              className="auth-form__btn"
              size="wide"
              loading={loading}>
              {t("login")}
            </ButtonComponent>
          </div>
          <div className='forgot_password'>
            <p>{t("forgot_password_1")} <Link to={AppRoutes.FORGOT}>{t("click_here")}</Link></p>
          </div>
          <div className='have_account'>
            <p>{t("dont_have_an_account")} <Link to={AppRoutes.REGISTER}>{t("create_one")}</Link> </p>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default LoginForm;
