import { Col, Row } from "antd";
import React, { FC } from "react";
import "./purchaseReturnSummaryCard.scss";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";
import { useTranslation } from "react-i18next";
import CardSkeletonComponent from "../../../../../shared/components/CardSkeletonComponent";
import refundIcon from "./../../../../../assets/images/return-icon.png"
import moment from "moment";
interface PurchaseReturnSummaryCardProps {
  purchase: PurchaseModel | undefined;
  loading: boolean;
}

const PurchaseReturnSummaryCard: FC<PurchaseReturnSummaryCardProps> = (props) => {
  const { purchase, loading } = props;
  const { t } = useTranslation()

  return (
    loading ? (
      <CardSkeletonComponent
        className="purchase-customer-details-card-skeleton"
        rows={8}
      />
    ) :
      <div className="purchase-return-summary-card">
        <Row gutter={[12, 0]} className="return-details details-row">
          <Col className="image__container">
            <img src={refundIcon} alt="refund-icon" className="refund-icon" />
          </Col>
          <Col span={22} className="details__container">
            <p className="header">Return details</p>
            {/* TODO data unavailable */}
            <h2 className="name">{"-"}</h2>

            <div className="info">
              <Row className="key-values" gutter={[56, 24]}>
                <Col className='left' span={12}>
                  <div className="info-column">
                    <h3>Refund Receipt ID</h3>
                    <p>
                    {/* TODO data unavailable */} {"-"}
                    </p>
                  </div>
                  <div className="info-column">
                    <h3>Returned Date</h3>
                    <p>
                    {/* TODO data unavailable */} {"-"}
                    </p>
                  </div>
                  <div className="info-column">
                    <h3>Refund Receipt</h3>
                    {/* TODO file view card */} {"-"}
                  </div>
                </Col>
                <Col className='right' span={12}>
                  <div className="info-column">
                    <h3>Refund settlement</h3>
                    <p className="refund">
                    {/* TODO data unavailable */} {"-"}
                    </p>
                  </div>
                  <div className="info-column">
                    <h3>Settlement ID</h3>
                    <p>
                    {/* TODO data unavailable */} {"-"}
                    </p>
                  </div>
                  <div className="info-column">
                    <h3>Settlement date</h3>
                    <p>
                    {/* TODO data unavailable */} {"-"}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
  );
};

export default PurchaseReturnSummaryCard;
