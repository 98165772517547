import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../shared/components/Navbar";
import "./home.scss"

export const currencyString = <span>LE &nbsp;</span>;

const Home = (props: any) => {
    return (
        <div className="home">
            <Navbar />
            <div className="home-right__container">
                <Outlet />
            </div>
        </div>
    )
}

export default Home;
