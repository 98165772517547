import * as Yup from "yup";
import { REGEX } from "../../../../enums/regex";

export const profileEditFormValidation = Yup.object().shape({
  firstName: Yup.string()
    .matches(REGEX.NAME.match, REGEX.NAME.error)
    .required('First name is required.'),
  lastName: Yup.string()
    .matches(REGEX.NAME.match, REGEX.NAME.error)
    .required('Last name is required.'),
  email: Yup.string()
    .email('Email is not valid.')
    .required('Email is required.'),
  isdCode: Yup.string(),
  mobileNumber: Yup.string()
  .required('Phone number is required.')
  .when("countryCode.isdCode", {
    is: "+20",
    then: Yup.string()
      .matches(REGEX.PHONE.match, REGEX.PHONE.error)
  })
  .when("countryCode.isdCode", {
    is: "+972",
    then: Yup.string()
      .matches(REGEX.PHONE_ISRAEL.match, REGEX.PHONE_ISRAEL.error)
  })
  .when("countryCode.isdCode", {
    is: "+91",
    then: Yup.string()
      .matches(REGEX.PHONE_INDIA.match, REGEX.PHONE_INDIA.error)
  })
  .when("countryCode.isdCode", {
    is: "+1",
    then: Yup.string()
      .matches(REGEX.PHONE_US.match, REGEX.PHONE_US.error)
  }),
})