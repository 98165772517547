import { Select } from "antd";
import { Form, Formik, FormikState } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InternalUsersModel } from "../../../../models/InternalUsers/internalusers.model";
import MetaService from "../../../../services/MetaService/meta.service";
import VendorService from "../../../../services/VendorService/vendor.service";
import { generateCodeOptions, getCountryIsdCode } from "../../../utils/generateCountryCodeOptions";
import InputField from "../../InputField";
import ModalComponent from "../../ModalComponent";
import "./profileeditform.scss";
import { profileEditFormValidation } from "./profileEditFormValidation";

interface ProfileEditFormProps {
  visible: boolean;
  closeHandler: () => void;
  profile: InternalUsersModel;
}

const ProfileEditForm: FC<ProfileEditFormProps> = (props) => {
  const { visible, closeHandler, profile } = props;
  const { updateVendorProfile, loading } = VendorService()
  const { getCountryCodesMeta, countryCodesMeta } = MetaService();
  const [initialValues, setInitialValues] = useState(profile);
  const { t } = useTranslation()
  const onSubmit = (values: InternalUsersModel) => {
    updateVendorProfile(values as InternalUsersModel, () => {
      closeHandler()
    },
      () => { },
      () => { })
  };
  useEffect(() => {
    setInitialValues({ ...profile, isdCode: profile?.countryCode?.isdCode })
  }, [profile])

  const resetAndClose = (resetForm: (nextState?: Partial<FormikState<InternalUsersModel>> | undefined) => void) => {
    resetForm()
    closeHandler()
  }

  useEffect(() => {
    getCountryCodesMeta()
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={profileEditFormValidation}
    >
      {({ handleSubmit, errors, setFieldValue, values, resetForm }) => (
        <ModalComponent
          type="small"
          visible={visible}
          closeHandler={()=>resetAndClose(resetForm)}
          successProps={{
            title: t("update"),
            clickHandler: handleSubmit,
            loading
          }}
          cancelProps={{
            title: t("discard"),
            clickHandler: ()=>resetAndClose(resetForm),
          }}
          title={t("edit_profile")}
        >
          <div className="profile-edit-form">
            <Form>
              <InputField className="filter-field"
                name="firstName"
                label="First Name"
                placeholder="Enter"
              />
              <InputField className="filter-field"
                name="lastName"
                label="Last Name"
                placeholder="Enter"
              />
              <InputField className="filter-field" name="email" label="Email" placeholder="Enter" />
              <InputField
                  addonBefore={<Select
                    value={values?.countryCodeId ?? ''}
                    onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                      if(typeof value !== "string"){
                        setFieldValue("countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                        setFieldValue("countryCodeId", value)
                      } 
                      setFieldValue("mobileNumber", undefined)
                    }}
                    options={generateCodeOptions(countryCodesMeta)}
                    />
                  }
                  name="mobileNumber"
                  label="Phone Number"
                placeholder="Enter" />
              {/* TODO: Needed for production */}
              {/* <InputField prefix='+20' placeholder='Enter' label='Mobile Number' name='mobileNumber' /> */}
            </Form>
          </div>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default ProfileEditForm;
