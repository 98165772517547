export enum BadgeTypes {
    ACTIVE = "active",
    APPROVED = "approved",
    INACTIVE = "inactive",
    COMPLETED = "completed",
    REFUND_COMPLETED = "refund_completed",
    PURCHASED = "purchased",
    PENDING = "pending",
    PARTIAL_RETURN = "partial_return",
    PENDING_APPROVAL = "waiting_for_approval",
    PENDING_SETTLEMENT = "pending_settlement",
    RETURNED = "returned",
    RETURN_CANCELLED = "return_cancelled",
    OVERDUE = "overdue",
    OUTSTANDING = "outstanding",
    OUTSTANDING_PAYMENT = "outstanding_payment",
    SETTLED = "settled",
    REJECTED = "rejected",
    ADMIN= "admin",
    INTERNAL_USER    =   "internal_user",
    VENDOR= "vendor",
    VENDOR_USER = "vendor_user",
    VENDOR_INTERNAL_USER = "vendor_internal_user",
    FAILED = "failed",
    RETURN = "return",
    PENDING_SETTLEMENTS = "pending_settlements",
}