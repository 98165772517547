import { Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { Field, Form, Formik, useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../ButtonComponent";
import { DocumentItemCard } from "../DocumentsCard";
import Error from "../Error";
import IconComponent from "../IconComponent";
import MenuComponent from "../MenuComponent";
import SpinnerComponent from "../SpinnerComponent";
import { handleBeforeImageUpload } from "../../utils/imageHelper";
import "./uploadcomponent.scss";

interface UploadComponentProps {
  fileUrl?: string;
  fileId?: string;
  fileType?: string;
  fileName?: string;
  onUpload: (file: File, fileUrl: string) => void;
  type: "cover-picture" | "logo" | "document" | "poster" | "image";
  title: string;
  label?: string;
  onRemove?: () => void;
  accept: string;
  isUploading?: boolean;
  disabled?: boolean;
  className?: string;
  viewType?: "modal" | "route";
  error?: string;
}

const UploadComponent: FC<UploadComponentProps> = (props) => {
  const { fileUrl, fileId, fileType, fileName, onUpload, type, title, label, onRemove, accept, isUploading, disabled, className, viewType = "route", error } =
    props;
  const { t } = useTranslation()

  function getBase64(file: RcFile, callback: Function) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  }
  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status !== "uploading" && info.file.originFileObj) {
      getBase64(info.file.originFileObj, (fileUrl: string) => {
        if (info.file.originFileObj) onUpload(info.file.originFileObj, fileUrl);
      });
    }
  };

  const handleBeforeUpload = (file: File) => {
    const acceptedTypes = accept?.split(", ");
    return handleBeforeImageUpload(file, acceptedTypes, type)
  };

  return (
    <Formik initialValues={{}} onSubmit={()=>{}}>
    <Field as={Upload} name={Field?.name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="upload-component__wrapper">
          {label && <label>{label}</label>}
          {fileUrl &&
            type !== "poster" ? (
            type == "image" ? (
              <div className="image-uploaded-component">
                <img src={fileUrl} className="uploaded-image" />
                <MenuComponent
                  menu={[
                    {
                      title: t("reupload"),
                      icon: <IconComponent name="icon-upload" />,
                      upload: {
                        handleChange: handleChange,
                        accept: accept,
                      },
                    },
                  ]}
                />
              </div>
            ) :
              type == "cover-picture" ? (
                <div className="cover-picture-uploaded-component">
                  <img className="uploaded-image" src={fileUrl} />
                  <MenuComponent
                    dropdownClassName="uploaded-image__action"
                    menu={[
                      {
                        title: t("remove"),
                        clickHandler: () => onRemove && onRemove(),
                        icon: <IconComponent name="icon-trash-black" />,
                      },
                      {
                        title: t("reupload"),
                        icon: <IconComponent name="icon-upload-black" />,
                        upload: { handleChange: handleChange, accept: accept },
                      },
                    ]}
                    type={type}
                  >
                    <ButtonComponent
                      size="medium"
                      type="primary"
                      icon={<IconComponent name="icon-camera" />}
                    >
                      {t("upload_cover_picture")}
                    </ButtonComponent>
                  </MenuComponent>
                </div>
              ) : type == "document" ? (
                <div className="document-uploaded-component">
                  <DocumentItemCard
                    accept={accept}
                    fileType={fileType}
                    fileId={fileId}
                    fileName={fileName}
                    fileUrl={fileUrl}
                    uploadHandler={onUpload}
                    isUpload
                    handleChange={handleChange}
                    viewType={viewType}
                  />
                </div>
              ) : type == "logo" ? (
                <div className="logo-uploaded-component">
                  <img src={fileUrl} />
                  <MenuComponent
                    dropdownClassName="logo-reupload"
                    menu={[
                      {
                        title: t("remove"),
                        clickHandler: () => onRemove && onRemove(),
                        icon: <IconComponent name="icon-trash-black" />,
                      },
                      {
                        title: t("reupload"),
                        icon: <IconComponent name="icon-upload-black" />,
                        upload: { handleChange, accept },
                      },
                    ]}
                    type={type}
                  >
                    <div className="logo-upload-camera-icon">
                      <IconComponent name="icon-camera" />
                    </div>
                  </MenuComponent>
                </div>
              ) : (
                ""
              )
          ) : (
            <Upload
              beforeUpload={handleBeforeUpload}
              accept={accept}
              className={`upload-component ${type}`}
              name="avatar"
              showUploadList={false}
              onChange={handleChange}
              disabled={disabled}
            >
              {isUploading ? (
                <SpinnerComponent className="upload-component-spin" tip="Uploading" />
              ) : (
                <div className="upload-component-button">
                  <IconComponent name="icon-upload" />
                  <div className="upload-component-button-title">{title}</div>
                </div>
              )}
            </Upload>
          )}
          {
            error && !fileUrl &&
            <Error message={error as string}/>
          }
        </div>
      )}
    </Field>
    </Formik>
  );
};

export default UploadComponent;
