import { Divider, Spin } from 'antd';
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AppLogo from "../../../assets/images/logo_h.svg";
import "./termsandconditions.scss"
import ChangeLanguageForm from '../../../shared/components/ChangeLanguageForm';
import { useSearchParams } from 'react-router-dom';
import { getCookies } from '../../../shared/utils/cookiesHelper';
import i18next from 'i18next';
import HummingBird from "./../../../assets/images/capa_1.svg"

const TermsAndConditions = () => {
    const { t } = useTranslation()
    const language = getCookies().i18next || i18next.language
    const [searchParams] = useSearchParams()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        i18next.changeLanguage(searchParams.get("lang") as string)
    }, [searchParams])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 250);
    }, [language])

    return (
        <div className={`terms-and-conditions ${language == "ar" ? 'align_right' : ''}`}>
            {
                loading ?
                    <Spin /> :
                    <Fragment>
                        <div className="terms-and-conditions--header">
                            <div className="logo-container">
                                <img src={HummingBird} alt="" className='humming-bird' />
                                <img src={AppLogo} alt="app logo" className='app-logo' />
                            </div>
                            <div className="language-change-container">
                                <ChangeLanguageForm color='blue' />
                            </div>
                        </div>
                        <div className='terms-and-conditions--body'>
                            <Fragment>
                                <h1 className='main-header'>
                                    {t('deall_merchant')}
                                </h1>
                                <Divider />

                                <h3 className='sub-header'>
                                    {t("intro")}
                                </h3>
                                <p className='text'>
                                    {t("intro_1")}
                                </p>

                                <h3 className="sub-header-2">
                                    {t("services")}
                                </h3>
                                <p className="text">
                                    {t("services_1")}
                                </p>
                                <p className="text">
                                    {t("services_2")}
                                </p>
                                <p className="text">
                                    {t("services_3")}
                                </p>
                                <p className="text">
                                    {t("services_4")}
                                </p>

                                <h3 className="sub-header-2">
                                    {t("changes")}
                                </h3>
                                <p className="text">
                                    {t("changes_1")}
                                </p>

                                <h3 className="sub-header">
                                    {t("auth")}
                                </h3>
                                <h3 className="sub-header-2">
                                    {t("consent")}
                                </h3>
                                <p className="text">
                                    {t("consent_1")}
                                </p>
                                <p className="text">
                                    {t("consent_2")}
                                </p>
                                <p className="text">
                                    {t("consent_3")}
                                </p>
                                <p className="text">
                                    {t("consent_4")}
                                </p>


                                <h3 className="sub-header">
                                    {t("obligations")}
                                </h3>
                                <div className="sub-header-2">
                                    {t('general')}
                                </div>
                                <p className="text">
                                    {t('general_1')}
                                </p>
                                <p className="text">
                                    {t('general_2')}
                                </p>
                                <p className="text">
                                    {t('general_3')}
                                </p>
                                <p className="text">
                                    {t('general_4')}
                                </p>
                                <p className="text">
                                    {t('general_5')}
                                </p>
                                <p className="text">
                                    {t('general_6')}
                                </p>
                                <p className="text">
                                    {t('general_7')}
                                </p>
                                <p className="text">
                                    {t('general_8')}
                                </p>

                                <p className="text">
                                    {t('general_9')}
                                </p>
                                <p className="text">
                                    {t('general_10')}
                                </p>

                                <p className="text">
                                    {t('general_11')}
                                </p>

                                <p className="text">
                                    {t('general_12')}
                                </p>

                                <p className="text">
                                    {t('general_13')}
                                </p>

                                <p className="text">
                                    {t('general_14')}
                                </p>

                                <p className="text">
                                    {t('general_15')}
                                </p>

                                <p className="text">
                                    {t('general_16')}
                                </p>

                                <p className="text">
                                    {t('general_17')}
                                </p>

                                <p className="text">
                                    {t('general_18')}
                                </p>
                                <p className="text">
                                    {t('general_19')}
                                </p>

                                <p className="text">
                                    {t('general_20')}
                                </p>
                                <p className="text">
                                    {t('general_21')}
                                </p>
                                <p className="text">
                                    {t('general_22')}
                                </p>


                                <h3 className="sub-header-2">
                                    {t('respect')}
                                </h3>
                                <p className="text">
                                    {t('respect_1')}
                                </p>
                                <p className="text">
                                    {t('respect_2')}
                                </p>
                                <p className="text">
                                    {t('respect_3')}
                                </p>


                                <p className="text">
                                    {t('respect_4')}
                                </p>
                                <p className="text">
                                    {t('respect_5')}
                                </p>
                                <p className="text">
                                    {t('respect_6')}
                                </p>
                                <p className="text">
                                    {t('respect_7')}
                                </p>
                                <p className="text">
                                    {t('respect_8')}
                                </p>

                                <p className="text">
                                    {t('respect_9')}
                                </p>

                                <p className="text">
                                    {t('respect_10')}
                                </p>
                                <p className="text">
                                    {t('respect_11')}
                                </p>


                                <h3 className="sub-header-2">
                                    {t("intellectual")}
                                </h3>
                                <p className="text">
                                    {t("intellectual_1")}
                                </p>
                                <p className="text">
                                    {t("intellectual_2")}
                                </p>
                                <p className="text">
                                    {t("intellectual_3")}
                                </p>
                                <p className="text">
                                    {t("intellectual_4")}
                                </p>
                                <p className="text">
                                    {t("intellectual_5")}
                                </p>
                                <p className="text">
                                    {t("intellectual_6")}
                                </p>
                                <p className="text">
                                    {t("intellectual_7")}
                                </p>

                                <h3 className="sub-header">
                                    {t('fees')}
                                </h3>
                                <p className="text">
                                    {t('fees_1')}
                                </p>
                                <p className="text">
                                    {t('fees_2')}
                                </p>
                                <p className="text">
                                    {t('fees_3')}
                                </p>
                                <p className="text">
                                    {t('fees_4')}
                                </p>
                                <p className="text">
                                    {t('fees_5')}
                                </p>
                                <p className="text">
                                    {t('fees_6')}
                                </p>
                                <p className="text">
                                    {t('fees_7')}
                                </p>


                                <h3 className="sub-header">
                                    {t('payment')}
                                </h3>
                                <h3 className="sub-header-2">
                                    {t('payment_return')}
                                </h3>
                                <p className="text">
                                    {t('payment_return_1')}
                                </p>
                                <p className="text">
                                    {t('payment_return_2')}
                                </p>
                                <p className="text">
                                    {t('payment_return_3')}
                                </p>
                                <p className="text">
                                    {t('payment_return_4')}
                                </p>
                                <p className="text">
                                    {t('payment_return_5')}
                                </p>


                                <h3 className="sub-header">
                                    {t('confidential')}
                                </h3>
                                <p className="text">
                                    {t('confidential_1')}
                                </p>
                                <p className="text">
                                    {t('confidential_2')}
                                </p>
                                <p className="text">
                                    {t('confidential_3')}
                                </p>
                                <p className="text">
                                    {t('confidential_4')}
                                </p>


                                <h3 className="sub-header">
                                    {t("data")}
                                </h3>
                                <p className="text">
                                    {t("data_1")}
                                </p>

                                <h3 className="sub-header">
                                    {t("disputes")}
                                </h3>
                                <p className="text">
                                    {t("disputes_1")}
                                </p>

                                <h3 className="sub-header">
                                    {t("suspension")}
                                </h3>
                                <p className="text">
                                    {t("suspension_1")}
                                </p>
                                <p className="text">
                                    {t("suspension_2")}
                                </p>


                                <h3 className="sub-header">
                                    {t('termination')}
                                </h3>
                                <h3 className="sub-header-2">
                                    {t('cause')}
                                </h3>
                                <p className="text">
                                    {t('cause_1')}
                                </p>
                                <p className="text">
                                    {t('cause_2')}
                                </p>
                                <h3 className="sub-header-2">
                                    {t("convenience")}
                                </h3>
                                <p className="text">
                                    {t("convenience_1")}
                                </p>

                                <h3 className="sub-header-2">
                                    {t('consequence')}
                                </h3>
                                <p className="text">
                                    {t('consequence_1')}
                                </p>
                                <p className="text">
                                    {t('consequence_2')}
                                </p>
                                <p className="text">
                                    {t('consequence_3')}
                                </p>
                                <p className="text">
                                    {t('consequence_4')}
                                </p>


                                <h3 className="sub-header">
                                    {t('warranties')}
                                </h3>
                                <h3 className="sub-header-2">
                                    {t('reps')}
                                </h3>
                                <p className="text">
                                    {t('reps_1')}
                                </p>
                                <p className="text">
                                    {t('reps_2')}
                                </p>
                                <p className="text">
                                    {t('reps_3')}
                                </p>
                                <p className="text">
                                    {t('reps_4')}
                                </p>
                                <p className="text">
                                    {t('reps_5')}
                                </p>
                                <p className="text">
                                    {t('reps_6')}
                                </p>
                                <p className="text">
                                    {t('reps_7')}
                                </p>
                                <p className="text">
                                    {t('reps_8')}
                                </p>


                                <h3 className="sub-header-2">
                                    {t('limits')}
                                </h3>
                                <p className="text">
                                    {t('limits_1')}
                                </p>
                                <p className="text">
                                    {t('limits_2')}
                                </p>
                                <p className="text">
                                    {t('limits_3')}
                                </p>
                                <p className="text">
                                    {t('limits_4')}
                                </p>

                                <h3 className="sub-header-2">
                                    {t("indemnification")}
                                </h3>
                                <p className="text">
                                    {t("indemnification_1")}
                                </p>


                                <h3 className="sub-header">
                                    {t("_general")}
                                </h3>
                                <h3 className="sub-header-2">
                                    {t("notices")}
                                </h3>
                                <p className="text">
                                    {t("notices_1")}
                                </p>
                                <p className="text">
                                    {t("notices_2")}
                                </p>
                                <p className="text">
                                    {t("notices_3")}
                                </p>


                                <h3 className="sub-header-2">
                                    {t("parties")}
                                </h3>
                                <p className="text">
                                    {t("parties_1")}
                                </p>


                                <h3 className="sub-header-2">
                                    {t('law')}
                                </h3>
                                <p className="text">
                                    {t('law_1')}
                                </p>
                                <p className="text">
                                    {t('law_2')}
                                </p>

                                <h3 className="sub-header-2">
                                    {t("misc")}
                                </h3>
                                <p className="text">
                                    {t("misc_1")}
                                </p>
                                <p className="text">
                                    {t("misc_2")}
                                </p>
                                <p className="text">
                                    {t("misc_3")}
                                </p>
                                <p className="text">
                                    {t("misc_4")}
                                </p>
                                <p className="text">
                                    {t("misc_5")}
                                </p>


                                <h3 className="sub-header">
                                    {t("definitions")}
                                </h3>
                                <p className="text">
                                    {t("d_1")}
                                </p>

                                <p className="text">
                                    {t("d_2")}
                                </p>
                                <p className="text">
                                    {t("d_3")}
                                </p>
                                <p className="text">
                                    {t("d_4")}
                                </p>
                                <p className="text">
                                    {t("d_5")}
                                </p>
                                <p className="text">
                                    {t("d_6")}
                                </p>
                                <p className="text">
                                    {t("d_7")}
                                </p>
                                <p className="text">
                                    {t("d_8")}
                                </p>
                                <p className="text">
                                    {t("d_9")}
                                </p>
                                <p className="text">
                                    {t("d_10")}
                                </p>
                                <p className="text">
                                    {t("d_11")}
                                </p>
                                <p className="text">
                                    {t("d_12")}
                                </p>
                                <p className="text">
                                    {t("d_13")}
                                </p>
                                <p className="text">
                                    {t("d_14")}
                                </p>
                                <p className="text">
                                    {t("d_15")}
                                </p>
                                <p className="text">
                                    {t("d_16")}
                                </p>
                                <p className="text">
                                    {t("d_17")}
                                </p>
                                <p className="text">
                                    {t("d_18")}
                                </p>
                                <p className="text">
                                    {t("d_19")}
                                </p>
                                <p className="text">
                                    {t("d_20")}
                                </p>
                                <p className="text">
                                    {t("d_21")}
                                </p>
                                <p className="text">
                                    {t("d_22")}
                                </p>
                                <p className="text">
                                    {t("d_23")}
                                </p>
                                <p className="text">
                                    {t("d_24")}
                                </p>
                                <p className="text">
                                    {t("d_25")}
                                </p>
                                <p className="text">
                                    {t("d_26")}
                                </p>
                                <p className="text">
                                    {t("d_27")}
                                </p>
                                <p className="text">
                                    {t("d_28")}
                                </p>
                                <p className="text">
                                    {t("d_29")}
                                </p>
                                <p className="text">
                                    {t("d_30")}
                                </p>
                                <p className="text">
                                    {t("d_31")}
                                </p>
                                <p className="text">
                                    {t("d_32")}
                                </p>
                                <p className="text">
                                    {t("d_33")}
                                </p>
                                <p className="text">
                                    {t("d_34")}
                                </p>
                                <p className="text">
                                    {t("d_35")}
                                </p>
                                <p className="text">
                                    {t("d_36")}
                                </p>
                                <p className="text">
                                    {t("d_37")}
                                </p>
                                <p className="text">
                                    {t("d_38")}
                                </p>
                                <p className="text">
                                    {t("d_39")}
                                </p>

                                <p className="text">
                                    <b>
                                        {t("fee")}
                                    </b>
                                </p>
                                <p className="text">
                                    {t("fee_1")}
                                </p>

                                <p className="text">
                                    <b>
                                        {t("cross")}
                                    </b>
                                </p>
                                <p className="text">
                                    {t("cross_1")}
                                </p>
                                <p className="text">
                                    {t("cross_2")}
                                </p>
                                <p className="text">
                                    {t("cross_3")}
                                </p>
                                <p className="text">
                                    {t("cross_4")}
                                </p>
                                <p className="text">
                                    {t("cross_5")}
                                </p>
                                <p className="text">
                                    {t("cross_6")}
                                </p>
                                <p className="text">
                                    {t("cross_7")}
                                </p>
                                <p className="text">
                                    {t("cross_8")}
                                </p>
                                <p className="text">
                                    {t("cross_9")}
                                </p>
                                <p className="text">
                                    {t("cross_10")}
                                </p>
                                <p className="text">
                                    {t("cross_11")}
                                </p>
                                <p className="text">
                                    {t("cross_12")}
                                </p>
                                <p className="text">
                                    {t("cross_13")}
                                </p>
                                <p className="text">
                                    {t("cross_14")}
                                </p>
                                <p className="text">
                                    {t("cross_15")}
                                </p>
                                <p className="text">
                                    {t("cross_16")}
                                </p>
                                <p className="text">
                                    {t("cross_17")}
                                </p>
                                <p className="text">
                                    {t("cross_18")}
                                </p>
                                <p className="text">
                                    {t("cross_19")}
                                </p>
                                <p className="text">
                                    {t("cross_20")}
                                </p>
                                <p className="text">
                                    {t("cross_21")}
                                </p>


                                <p className="text">
                                    <b>
                                        {t('card')}
                                    </b>
                                </p>
                                <p className="text">
                                    <b>
                                        {t('card_intro')}
                                    </b>
                                </p>
                                <p className="text">
                                    {t('card_intro_1')}
                                </p>
                                <p className="text">
                                    {t('card_intro_2')}
                                </p>

                                <p className="text">
                                    <b>
                                        {t("desc")}
                                    </b>
                                </p>
                                <p className="text">
                                    {t("desc_1")}
                                </p>
                                <p className="text">
                                    {t("desc_2")}
                                </p>
                                <p className="text">
                                    {t("desc_3")}
                                </p>
                                <p className="text">
                                    {t("desc_4")}
                                </p>
                                <p className="text">
                                    {t("desc_5")}
                                </p>
                                <p className="text">
                                    {t("desc_6")}
                                </p>

                                <p className="text">
                                    <b>
                                        {t("card_fees")}
                                    </b>
                                </p>
                                <p className="text">
                                    {t("card_fees_1")}
                                </p>
                                <p className="text">
                                    {t("card_fees_1")}
                                </p>


                                <p className="text">
                                    <b>
                                        {t('display')}
                                    </b>
                                </p>
                                <p className="text">
                                    {t('display_1')}
                                </p>
                                <p className="text">
                                    {t('display_2')}
                                </p>


                                <p className="text">
                                    <b>
                                        {t('training')}
                                    </b>
                                </p>
                                <p className="text">
                                    {t('training_1')}
                                </p>
                                <p className="text">
                                    {t('training_2')}
                                </p>
                                <p className="text">
                                    {t('training_3')}
                                </p>
                                <p className="text">
                                    <b>
                                        {t('specific')}
                                    </b>
                                </p>
                                <p className="text">
                                    {t('specific_1')}
                                </p>
                                <p className="text">
                                    {t('specific_2')}
                                </p>
                                <p className="text">
                                    {t('specific_3')}
                                </p>
                                <p className="text">
                                    {t('specific_4')}
                                </p>
                                <p className="text">
                                    {t('specific_5')}
                                </p>

                                <p className="text">
                                    <b>
                                        {t("appendix_schedule")}
                                    </b>
                                </p>
                                <p className="text">
                                    {t("appendix_schedule_1")}
                                </p>
                                <p className="text">
                                    {t("appendix_schedule_2")}
                                </p>
                            </Fragment>
                        </div>
                    </Fragment>
            }
        </div>
    )
}

export default TermsAndConditions