import { alias, custom, object, primitive, serializable } from "serializr";
import { AttachmentModel } from "../../Attachment/attachment.model";

export class VendorStorePromotionModel {
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('vendor_id', primitive()))
	vendorId?: number;

	@serializable(alias('image_url', primitive()))
	imageUrl?: string;

	@serializable(alias('attachment_url', primitive()))
	attachmentUrl?: string;

	@serializable(alias('attachment', object(AttachmentModel)))
	attachment?: AttachmentModel;
}

export class VendorStorePromotionCreationModel {

	@serializable(alias('image', custom((file) => file, () => { })))
	image?: File;

	@serializable(alias('attachment', primitive()))
	attachment?: number;

}