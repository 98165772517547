import React from 'react';
import AppRoutes from './routes';
import { AuthProvider } from './context/AuthContext';
import 'antd/dist/antd.css';
import "./styles/_main.scss";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

const App = () => {
  return (
      <AuthProvider>
        <Router>
        <AppRoutes />
        </Router>
      </AuthProvider>
  );
}

export default App;
