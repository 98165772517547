import React, { FC, useEffect, useState } from "react";
import BadgeComponent from "../../../../shared/components/BadgeComponent";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../shared/components/TableComponent";
import "./pendingSettlements.scss";
import { currencyString } from "../..";
import { Form, Formik, FormikValues } from "formik";
import { Col, Row } from "antd";
import DatePickerField from "../../../../shared/components/DatePickerField";
import DropdownField from "../../../../shared/components/DropdownField";
import NoTransactionsImg from "../../../../assets/empty/no_transactions_data.svg";
import moment from "moment";
import { serialize } from "serializr";
import { BadgeTypes } from "../../../../enums/badgeTypes";
import { BadgeTypesDefinition } from "../../../../definitions/badgeTypesDefinition";
import { VendorTypes } from "../../../../enums/vendorTypes";
import { VendorTypesDefinition } from "../../../../definitions/vendorTypesDefinition";
import { tableSorter } from "../../../../shared/utils/tableSorter";
import { PendingSettlementFilterModel, PendingSettlementModel } from "../../../../models/Settlement/settlement.model";
import { removeUndefined } from "../../../../shared/utils/removeUndefinied";
import { SettlementService } from "../../../../services/SettlementService/settlement.service";
import { useTranslation } from "react-i18next";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { convertToTwoDecimals } from "../../../../shared/utils/convertToTwoDecimals";
import { formatDate } from "../../../../shared/utils/formatDate";
interface PendingSettlementsProps {}

const PendingSettlements: FC<PendingSettlementsProps> = (props) => {
  const {} = props;
  const [isFilterShown, setIsFilterShown] = useState(false);
  const {t} = useTranslation()

  const { fetchPendingSettlementList, loading, pendingSettlements, paginations } =
    SettlementService();
  const [filters, setFilters] = useState<PendingSettlementFilterModel>({});
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const filterInitialValues = new PendingSettlementFilterModel();

  const handleFilterSubmit = (values: PendingSettlementFilterModel) => {
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  const handlePageChange = (page:number) => {
    setPage(page)
    fetchPendingSettlementList({...filters,page, ...sortOptions})
  }

  useEffect(() => {
    fetchPendingSettlementList({...filters, ...sortOptions});
  }, [filters]);

  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              handleFilterClose();
              setFilters(() => ({
                ...filterInitialValues,
                search: filters?.search,
              }));
              resetForm({});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col className="filter-field" span={12}>
                  <DatePickerField
                    name="createdAt"
                    placeholder="Enter"
                    label="Purchase Date"
                    value={values?.createdAt}
                    onChange={(date, dateString) => {
                      setFieldValue("createdAt", dateString);
                    }}
                  />
                </Col>
                <Col className="filter-field" span={12}>
                  <DropdownField
                    label="Purchase Status"
                    options={[
                      {
                        value: BadgeTypes.COMPLETED,
                        label: BadgeTypesDefinition[BadgeTypes.COMPLETED],
                      },
                      {
                        value: BadgeTypes.FAILED,
                        label: BadgeTypesDefinition[BadgeTypes.FAILED],
                      },
                      {
                        value: BadgeTypes.PARTIAL_RETURN,
                        label: BadgeTypesDefinition[BadgeTypes.PARTIAL_RETURN],
                      },
                      {
                        value: BadgeTypes.RETURN,
                        label: BadgeTypesDefinition[BadgeTypes.RETURNED],
                      },
                    ]}
                    name="purchaseStatus"
                    placeholder="Enter"
                    onChange={(value) => setFieldValue("purchaseStatus", value)}
                    value={values?.purchaseStatus}
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  return (
    <div className="pending-settlements">
      <TableComponent
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      onChange={fetchPendingSettlementList}
        onSort={(data)=>{
         setSortOptions(data)
        }}
        filtersSorts={{page, ...filters, search}}
        search={{
          placeholder: t("search_purchase_by_id"),
          onSearch: (value) => {
            setSearch(value)
            handleFilterSubmit({ search: value });
          },
        }}
        filter={filter}
        columns={[
          {
            title: t("purchase_date"),
            dataIndex: "createdAt",
            key: SortColumnTypes.PURCHASE_DATE,
            render: (text) => formatDate(text),
            sorter: true
          },
          {
            title: t("purchase_id"),
            dataIndex: "purchaseId",
            key: SortColumnTypes.PURCHASE_ID,
            sorter: (a, b) =>
              tableSorter("alphabetical", a?.purchaseId, b?.purchaseId),
          },
          {
            title: t("mode"),
            dataIndex: "",
            key: "",
            render: (record: PendingSettlementModel) =>
              record?.mode == VendorTypes.ONLINE
                ? VendorTypesDefinition[VendorTypes.ONLINE]
                : record?.vendor?.instore?.name,
          },
          {
            title: t("purchase_status"),
            dataIndex: "purchaseStatus",
            key: SortColumnTypes.PURCHASE_STATUS,
            render: (text) => <BadgeComponent type={text === BadgeTypes.RETURN ? BadgeTypes.RETURNED : text} />,
            sorter: true,
          },
          {
            title: "Purchase amount",
            dataIndex: "totalAmount",
            key: SortColumnTypes.TOTAL_AMOUNT,
            render: (text, record) => (
              <span>
                {currencyString}
                {text}
              </span>
            ),
            sorter: true,
          },
          {
            title: "Vendor Fee",
            dataIndex: "vendorProcessingFee",
            key: "vendorProcessingFee",
            render: (text, record) =>
              record?.paymentTransactionType === "return"
                ? "-"
                : convertToTwoDecimals(+text),
          },
          {
            title: "VAT",
            dataIndex: "vat",
            key: "vat",
            render: (text, record) =>
              record?.paymentTransactionType === "return"
                ? "-"
                : convertToTwoDecimals(+text),
          },
          {
            title: "Settlement Amount",
            dataIndex: "vendorSettlementAmount",
            key: "vendorSettlementAmount",
            render: (text, record) => (
              <span className={record?.isAmountNegative && "payment-loss"}>
                {record?.isAmountNegative && "-"}
                {convertToTwoDecimals(+text)}
              </span>
            ),
            align: "right",
            width: 200,
          },
        ]}
        loading={loading}
        empty={{
          text: "No pending settlements",
          img: NoTransactionsImg,
        }}
        data={pendingSettlements}
      />
    </div>
  );
};

export default PendingSettlements;
