import {serializable, alias, object, list, primitive} from 'serializr';

class Purchase {
	@serializable(alias('key', primitive()))
	key?: string;
}

export class DashboardFilterModel { 

	@serializable(alias('instore_id', primitive()))
	instoreId?: string;

	@serializable(alias('date_filter_key', primitive()))
	dateFilterKey?: string;

	@serializable(alias('start_date', primitive()))
	startDate?: string;

	@serializable(alias('end_date', primitive()))
	endDate?: string;

}
export class Dashboard { 

	@serializable(alias('number_of_purchases', primitive()))
	numberOfPurchases?: string;

	@serializable(alias('total_purchase_amount', primitive()))
	totalPurchaseAmount?: string;

	@serializable(alias('total_refund_amount', primitive()))
	totalRefundAmount?: string;

	@serializable(alias('number_of_active_instores', primitive()))
	numberOfActiveInstores?: string;

	@serializable(alias('purchase_count', object(Purchase)))
	purchaseCount?: Purchase;

	@serializable(alias('purchase_value', object(Purchase)))
	purchaseValue?: Purchase;

	@serializable(alias('total_pending_settlement_amount', primitive()))
	totalPendingSettlementAmount?: string;

}