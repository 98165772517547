import { Col, Row } from "antd";
import React, { FC, Fragment, useState } from "react";
import "./vendorInfoCard.scss";
import { serialize } from "serializr";
import { VendorModel } from "../../../../../models/Vendor/vendor.model";
import { convertJsonToFormData } from "../../../../../shared/utils/convertJsonToFormData";
import { removeUndefined } from "../../../../../shared/utils/removeUndefinied";
import SectionCardComponent from "../../../../../shared/components/SectionCardComponent";
import IconComponent from "../../../../../shared/components/IconComponent";
import { useTranslation } from "react-i18next";
import { InternalUsersModel } from "../../../../../models/InternalUsers/internalusers.model";

interface VendorInfoCardProps {
  vendorType: "approved" | "not-approved";
  loading?: boolean;
  vendor: VendorModel;
  contactPerson: InternalUsersModel;
  refreshList: () => void;
}

const VendorInfoCard: FC<VendorInfoCardProps> = ({
  vendorType,
  vendor,
  loading,
  refreshList,
  contactPerson
}) => {
  const [showProcessingFeeForm, setProcessingFeeFormVisibility] =
    useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {t} = useTranslation()

  const handleUpdate = (values: VendorModel) => {
    const formData = convertJsonToFormData({ vendor: removeUndefined(serialize(VendorModel, values)) });
  };

  const setHttp = (link: string) => (link?.search(/^http[s]?\:\/\//) == -1) ? 'http://'+ link : link;

  return (
    <Fragment>
      <SectionCardComponent
        loading={loading}
        className="vendor-info-card"
        section={
          <></>
        }
        content={
          <div className="vendor-info-card-content">
            <div className="item">
              <p className="title">Business Phone</p>
              <p className="value">{vendor?.countryCode?.isdCode} {vendor?.mobileNumber}</p>
            </div>
            <div className="item">
              <p className="title">Business Email</p>
              <p className="value">{vendor?.email}</p>
            </div>
            <div className="item">
              <p className="title">Contact Person</p>
              <p className="value">{contactPerson?.firstName}</p>
            </div>
            <div className="item">
              <p className="title">Contact Phone</p>
              <p className="value">{contactPerson?.countryCode?.isdCode} {contactPerson?.mobileNumber}</p>
            </div>
            <div className="item">
              <p className="title">Contact Email</p>
              <p className="value">{contactPerson?.email}</p>
            </div>
            <div className="item">
              <p className="title">Vendor ID</p>
              <p className="value">{vendor?.id}</p>
            </div>
            <div className="item category">
              <p className="title">{t("category")}</p>
              <p className="value">{vendor?.category}</p>
            </div>
            {/* TODO: required for next phase */}
            {/* <div className="sub-category">
              <p className="title">{t("sub_category")}</p>
              <p className="value">{vendor?.subCategory ?? "-"}</p>
            </div> */}
            <div className="item type">
              <p className="title">{t("type")}</p>
              <p className="value text-capitalize">{vendor?.type}</p>
            </div>
            <div className="item website">
              <p className="title">{t("website")}</p>
              <a className="value" href={setHttp(vendor?.websiteUrl??"")} target={"_blank"}>
                {vendor?.websiteUrl
                  ? vendor?.websiteUrl?.replace(/www.|https|http|[://]/gi, "")
                  : ""}
              </a>
            </div>
            <div className="item">
              <p className="title">{t("#_of_instores")}</p>
              <p className="value">{vendor?.noOfInstores}</p>
            </div>
          </div>
        }
      />
    </Fragment>
  );
};

export default VendorInfoCard;
