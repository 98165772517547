import React, { FC } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Vendor } from "../../../../../models/Vendor/vendor.model";
import CardSkeletonComponent from "../../../../../shared/components/CardSkeletonComponent";
import "./vendorPersonalDetailsCard.scss";
import bankIcon from "./../../../../../assets/images/bank-icon.png"
import walletIcon from "./../../../../../assets/images/wallet-icon.png"
interface VendorPersonalDetailsCardProps {
  vendor: Vendor | undefined;
  loading: boolean;
}
const VendorPersonalDetailsCard: FC<VendorPersonalDetailsCardProps> = ({
  vendor,
  loading,
}) => {
  const { t } = useTranslation()
  return loading ? (
    <CardSkeletonComponent className="card-wide-skeleton-component" rows={3} />
  ) : (
    <div className="vendor-personal-details-card">
      <Row gutter={[18, 50]}>
        <Col span={12}>
          <div className="vendor-personal-details-card-item">
            <Row>
              <Col className="image__container">
                <div className="icon-container">
                  <img src={bankIcon} alt="bank" className="bank-icon" />
                </div>
              </Col>
              <Col span={21}>
                <div className="label">{t("BANK_DETAILS")}</div>
                <div className="header">
                  {vendor?.paymentDetails?.accountNumber?.match(/.{1,4}/g)?.join(" ")}
                </div>
                <div>
                  <Row className="details">
                    <Col span={6} className="details-label">{t("name")}</Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.branch}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">{t("address")}</Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.address}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">{t("swift_code")}</Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.swiftCode}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">{t("branch")}</Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.branch}
                    </Col>
                  </Row>
                  <Row className="details">
                    <Col span={6} className="details-label">{t("iban")}</Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.iban}
                    </Col>
                  </Row>
                </div>
              </Col>

            </Row>

          </div>
        </Col>
        <Col span={12}>
          <div className="vendor-personal-details-card-item">
            <Row>
              <Col className="image__container">
                <div className="icon-container">
                  <img src={walletIcon} alt="bank" className="bank-icon" />
                </div>
              </Col>
              <Col span={21}>
                <div className="label">{t("MOBILE_WALLET")}</div>
                <div className="header">{vendor?.paymentDetails?.walletNumber}</div>
                <div>
                  <Row className="details">
                    <Col span={6} className="details-label">Type</Col>
                    <Col span={18} className="details-value">
                      {vendor?.paymentDetails?.walletType?.name}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>

        </Col>
      </Row>
    </div>
  );
};

export default VendorPersonalDetailsCard;
