import { Form, Formik } from 'formik'
import i18next from 'i18next'
import React, { FC, useEffect, useState } from 'react'
import { getCookies } from '../../utils/cookiesHelper'
import DropdownField from '../DropdownField'
import IconComponent from '../IconComponent'
import "./changelanguageform.scss"
import languageIconBlue from "./../../../assets/images/GlobeSimpleBlue.png"

interface ChangeLanguageFormProps {
    color: "blue"|"grey"
}

const ChangeLanguageForm: FC<ChangeLanguageFormProps> = (props) => {
    const {color} = props
    const languageInCookie = getCookies().i18next
    const [language, setLanguage] = useState<string>(languageInCookie);
    const handleChange = (_language: string) => {
        i18next.changeLanguage(_language);
        setLanguage(_language)
    };

    useEffect(() => {
        setLanguage(i18next.language);
    }, [i18next.language]);
    return (
        <div className="change-language-form__wrapper">
            <Formik
                enableReinitialize
                initialValues={{ lang: language }}
                onSubmit={() => { }}
            >
                {({ setFieldValue, values, errors, handleSubmit }) => {
                    return (
                        <Form className={`change-language-form ${language === "ar" && 'arabic'} ${color === "blue" && "blue"}`}>
                            {
                                color === "blue" ?
                                <img className={`language-icon`} src={languageIconBlue} alt="" /> :
                                <IconComponent name="icon-language-grey" />
                            }
                            <DropdownField
                                color='blue'
                                label=""
                                options={[
                                    {
                                        value: "en",
                                        label: "EN",
                                    },
                                    {
                                        value: "ar",
                                        label: "عربي",
                                    },
                                ]}
                                name="language"
                                placeholder="Select"
                                onChange={(value) => handleChange(value)}
                                value={languageInCookie||values?.lang}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default ChangeLanguageForm