import React, { FC, useEffect, useState } from "react";
import "./vendorprofile.scss";
import { Tabs } from "antd";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import PageView from "../../../shared/components/PageView";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import IconComponent from "../../../shared/components/IconComponent";
import VendorDetails from "./VendorDetails";
import VendorStorePromotions from "./VendorStorePromotions";
import VendorInstores from "./VendorInstores";
import { useTranslation } from "react-i18next";
import VendorService from "../../../services/VendorService/vendor.service";
import VendorForm from "./VendorForm";
interface VendorProfileProps {}
const { TabPane } = Tabs;

const VendorProfile: FC<VendorProfileProps> = (props) => {
  const {} = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { vendorId } = useParams();
  const {t} = useTranslation()
  const { loading, fetchVendor, vendor } = VendorService();
  const [isVendorFormShown, setIsVendorFormShown] = useState(false);
  
  useEffect(() => {
    fetchVendor();
  }, []);
  useEffect(() => {
    if (!location?.hash)
      navigate(
        { pathname: AppRoutes.VENDOR_PROFILE, hash: "details" },
        { replace: true }
      );
  }, [location]);

  return (
    <PageView
      type="default"
      vendorFormHandler={()=>setIsVendorFormShown(true)}
      title={{
        name: `${loading ? "Loading..." : vendor?.vendorDetails?.name}`,
        icon: <IconComponent name="icon-vendors" />
      }}
    >
      <Tabs
        className="vendor-profile"
        activeKey={location.hash?.replace(/#/gi, "")}
        onTabClick={(value) => {
          navigate({ pathname: location.pathname, hash: value });
        }}
      >
        <TabPane key={"details"} tab={t("details")}>
          <VendorDetails/>
        </TabPane>
        <TabPane key={"store-promotions"} tab={t("store_promotions")}>
          <VendorStorePromotions/>
        </TabPane>
        <TabPane key={"stores"} tab="Stores">
          <VendorInstores/>
        </TabPane>
      </Tabs>
      <VendorForm
        successHandler={(value) => {
          fetchVendor()
          setIsVendorFormShown(false);
        }}
        visible={isVendorFormShown}
        data={vendor}
        closeHandler={() => setIsVendorFormShown(false)}
      />
    </PageView>
  );
};

export default VendorProfile;
