import React, { FC, useContext, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useRoutes,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import Home from "../views/Home";
import { RouterProps } from "../shared/types/route.type";
import AppComponents from "../views/AppComponents";
import { AppRoutes } from "./routeConstants/appRoutes";
import RegisterForm from "../views/Auth/RegisterForm";
import ForgotPassword from "../views/Auth/ForgotPassword";
import LoginForm from "../views/Auth/LoginForm";
import NewVendorAccount from "../views/Auth/NewVendorAccount";
import PasswordResetLink from "../views/Auth/PasswordResetLink";
import NewPassword from "../views/Auth/NewPassword";
import AccountVerify from "../views/Auth/AccountVerify";
import ValidateOtpEmail from "../views/Auth/ValidateOtpEmail";
import BusinessDetails from "../views/BusinessDetails";
import Purchases from "../views/Home/Purchases";
import PurchaseView from "../views/Home/Purchases/PurchaseView";
import Settlements from "../views/Home/Settlements";
import PastSettlementsView from "../views/Home/Settlements/PastSettlements/PastSettlementsView";
import InternalUsers from "../views/Home/InternalUsers";
import VendorProfile from "../views/Home/VendorProfile";
import TermsAndConditions from "../views/Home/TermsAndConditions";
import ActionCable from "actioncable"
import { LocalStorage } from "../shared/utils/localStorage";
import { AuthContext } from "../context/AuthContext";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";
import { NotificationModel } from "../models/notification.model";
import FileView from "../shared/components/FileView";
import Dashboard from "../views/Home/Dashboard";
import InstoreFormView from "../views/Home/VendorProfile/VendorInstores/InstoreFormView";
import InstoreView from "../views/Home/VendorProfile/VendorInstores/InstoreView";

export const appHistory = createBrowserHistory();

const AppRouter = () => {
  const {user} = AuthContext()
  let routes = useRoutes([
    {
      path: AppRoutes.AUTH,
      element: <AuthWrapper />,
      children: [
        { path: AppRoutes.REGISTER, element: <RegisterForm /> },
        { path: AppRoutes.FORGOT, element: <ForgotPassword /> },
        { path: AppRoutes.LOGIN, element: <LoginForm /> },
        { path: AppRoutes.ONBOARD, element: <NewVendorAccount /> },
        { path: AppRoutes.PASSWORDRESETLINK, element: <PasswordResetLink /> },
        { path: AppRoutes.NEWPASSWORD, element: <NewPassword /> },
        { path: AppRoutes.VALIDATEOTPEMAIL, element: <ValidateOtpEmail /> },
        { path: AppRoutes.BUSINESSDETAILS, element: <BusinessDetails /> },
        { path: AppRoutes.VERIFYACCOUNT, element: <AccountVerify /> }
      ],
    },
    {
      path: AppRoutes.TERMS_AND_CONDITIONS,
      element: <TermsAndConditions/>
    },
    {
      path: AppRoutes.HOME,
      element: isAuthenticated(<Navigate to={AppRoutes.DASHBOARD}/>)
    },
    {
      path: AppRoutes.HOME,
      element: isAuthenticated(<Home/>),
      children: [
        { path: AppRoutes.DASHBOARD, element: <Dashboard/>},
        { path: AppRoutes.FILE_VIEW, element: <FileView/> },
        { path: AppRoutes.PURCHASES, element: <Purchases/> },
        { path: AppRoutes.PURCHASE_VIEW, element: <PurchaseView/> },
        { path: AppRoutes.SETTLEMENTS, element: <Settlements/> },
        { path: AppRoutes.PAST_SETTLEMENTS_VIEW, element: <PastSettlementsView/> },
        { path: AppRoutes.INTERNAL_USERS, element: <InternalUsers/> },
        { path: AppRoutes.VENDOR_PROFILE, element: <VendorProfile/> },
        { path: AppRoutes.VENDOR_INSTORES_CREATE, element: <InstoreFormView/> },
        { path: AppRoutes.VENDOR_INSTORE_VIEW, element: <InstoreView/> },
        { path: AppRoutes.VENDOR_INSTORE_EDIT, element: <InstoreFormView/> }
      ]
    },
    {
      ...(Boolean(process.env.REACT_APP_UNDER_DEVELOPMENT)
        ? {
          path: AppRoutes.APP_COMPONENTS,
          element: <AppComponents />,
        }
        : {}),
    },
  ]);

  const connectToCable = () => {
    const cable = ActionCable.createConsumer(
      `${process.env.REACT_APP_CABLE_URL}?access_token=${LocalStorage.getItem("ACCESS_TOKEN")}`
    );
    cable?.subscriptions?.create(
      {
        channel: "VendorNotificationChannel",
        room: `vendor_notification_${user?.id}`,
      },
      {
        received: (data: NotificationModel) => {
          Notification({
            message: "New notification",
            type: NotificationTypes.OPEN,
            description:"DEALL"
          });
        },
      }
    );
  };

  useEffect(() => {
    connectToCable()
  }, [user])

  return (
    <div className="app-router">
      {routes}
    </div>
  );
};

export default AppRouter;
