
import { serializable, alias, object, list, primitive } from 'serializr';
import { SortColumnTypes } from '../../shared/types/SortColumn.type';
import { PurchaseListFilterModel } from '../Purchase/purchase.model';

export class SortFilterModel extends PurchaseListFilterModel {

	@serializable(alias('search', primitive()))
	search?: string;

	@serializable(alias('page', primitive()))
	page?: number;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('sort_column', primitive()))
	sortColumn?: SortColumnTypes;

	@serializable(alias('sort_by', primitive()))
	sortBy?: "asc" | "desc" | "";

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('role', primitive()))
	role?: string;

}