import React, { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./fileView.scss";
import PageView from "../../../shared/components/PageView";
import CardSkeletonComponent from "../../../shared/components/CardSkeletonComponent";
import FileViewer from "../../../shared/components/FileViewer";
import IconComponent from "../../../shared/components/IconComponent";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { Col, Row } from "antd";
import S3AttachmentService from "../../../services/S3UploadService/s3upload.service";

interface FileViewProps { }

const FileView: FC<FileViewProps> = (props) => {
  const navigate = useNavigate()
  const { state }: any = useLocation();
  const { documentId } = useParams();
  const { loading } = S3AttachmentService();

  return (
    <>
      {loading ? (
        <CardSkeletonComponent rows={20} className="document__skeleton" />
      ) : (
        <div className="approve-documents-view">
          <div className="header">
            <Row className="header__container">
              <Col span={24}>
                <h2>
                  <span className="back-icon" onClick={() => navigate(-1)}>
                    <IconComponent name="icon-back" />
                  </span>
                  {state?.fileName}
                </h2>
              </Col>
            </Row>
          </div>
          <div className="document__container">
            <FileViewer fileType={state?.fileType} uri={state?.fileUrl} />
          </div>
        </div>
      )}
    </>
  );
};

export default FileView;
