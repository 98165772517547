export const AppRoutes = {
  AUTH: "/auth",
  REGISTER: "/auth/register",
  LOGIN: "/auth/login",
  FORGOT:"/auth/forgot-password",
  APP_COMPONENTS:"/app-components",
  ONBOARD:"/auth/new-vendor-account",
  PASSWORDRESETLINK:"/auth/password-reset-link",
  NEWPASSWORD:"/auth/set-newpassword",
  VALIDATEOTPEMAIL: "/auth/validate-otp-email",
  VERIFYACCOUNT:"/auth/account-verify",
  BUSINESSDETAILS:"/auth/business-details",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",

  HOME: "/",
  FILE_VIEW: "/file/:fileId",
  DASHBOARD: "/dashboard",
  PURCHASES: "/purchases",
  PURCHASE_VIEW: "/purchases/:purchaseId",
  
  SETTLEMENTS: "/settlements",
  PENDING_SETTLEMENTS: "/settlements/pending",
  PAST_SETTLEMENTS: "/settlements/past-settlements",
  PAST_SETTLEMENTS_VIEW: "/settlements/past-settlements/:settlementId",
  
  INTERNAL_USERS: "/internal-users",

  VENDOR_PROFILE: "/vendor-profile",
  VENDOR_DETAILS: "/vendor-profile/details",
  VENDOR_STORE_PROMOTIONS: "/vendor-profile/store-promotions",
  VENDOR_INSTORES: "/vendor-profile/stores",
  VENDOR_INSTORES_CREATE: "/vendor-profile/stores/create",
  VENDOR_INSTORE_VIEW: "/vendor-profile/stores/:instoreId",
  VENDOR_INSTORE_EDIT: "/vendor-profile/stores/:instoreId/edit"
};
