import { serializable, alias, object, list, primitive } from 'serializr';
import { DocumentTypes } from '../../enums/documentTypes';

const { BANK_STATEMENT, PROFILE_PIC, NATIONAL_ID_BACK, NATIONAL_ID_FRONT, EMPLOYMENT_LETTER, TAX_CARD, COMMERCIAL_REGISTRATION } = DocumentTypes

export class AttachmentModel {

    @serializable(alias("file_type", primitive()))
    fileType?: typeof BANK_STATEMENT | typeof PROFILE_PIC | typeof NATIONAL_ID_BACK | typeof NATIONAL_ID_FRONT | typeof EMPLOYMENT_LETTER | typeof TAX_CARD | typeof COMMERCIAL_REGISTRATION;

    @serializable(alias("created_at", primitive()))
    createdAt?: string;

    @serializable(alias("updated_at", primitive()))
    updatedAt?: string;

    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("filename", primitive()))
    filename?: string;

    @serializable(alias("s3_key", primitive()))
    s3Key?: string;

    @serializable(alias("s3_url", primitive()))
    s3Url?: string;

    @serializable(alias("attachment_status", primitive()))
    attachmentStatus?: string;
}