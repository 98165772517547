import { Col, Divider, Row, Select } from "antd";
import React, { ReactNode } from "react";
import { ErrorMessage, Form, Formik, FormikProps, FormikValues } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { vendorInstoreFormValidation } from "./vendorInstoreFormValidation";
import { VendorInstoreCreationModel, VendorInstoreModel } from "../../../../../models/Vendor/Instore/instore.model";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import InputField from "../../../../../shared/components/InputField";
import MapComponent from "../../../../../shared/components/MapComponent";
import Error from "../../../../../shared/components/Error";
import "./vendorInstoreForm.scss"
import SwitchComponent from "../../../../../shared/components/SwitchComponent";
import VendorService from "../../../../../services/VendorService/vendor.service";
import { useTranslation } from "react-i18next";
import { isdCodes } from "../../../../../shared/utils/isdCodes";
import { Option } from "antd/lib/mentions";
import MetaService from "../../../../../services/MetaService/meta.service";
import { countryCodesInitialValue, generateCodeOptions, getCountryIsdCode } from "../../../../../shared/utils/generateCountryCodeOptions";
interface VendorFormProps {
  visible: boolean;
  closeHandler: () => void;
  data?: VendorInstoreModel;
  refreshList: () => void;
}
const VendorInstoreForm: FC<VendorFormProps> = (props) => {
  const { visible, closeHandler, data, refreshList } = props;
  const { t } = useTranslation()
  const [initialValues, setInitialValues] =
    useState<VendorInstoreCreationModel>({} as VendorInstoreCreationModel);
  const { updateVendorInstore, createVendorInstore, loading } = VendorService();
  const { getCountryCodesMeta, countryCodesMeta } = MetaService();
  const { vendorId } = useParams();
  const formRef = useRef<FormikProps<FormikValues>>(null);
  useEffect(() => {
    setInitialValues(
      data ? {...data, countryCodeId: data?.countryCodeId ?? countryCodesInitialValue(countryCodesMeta) } : { ...new VendorInstoreCreationModel(), status: BadgeTypes.ACTIVE, countryCodeId: countryCodesInitialValue(countryCodesMeta), isdCode: "+20" }
    );
  }, [data, countryCodesMeta]);

  useEffect(() => {
    getCountryCodesMeta()
  }, [])
  

  const onSubmit = (values: VendorInstoreCreationModel) => {
    if (data?.id) {
      updateVendorInstore(data?.id, values, () => {
        refreshList();
        closeHandler()
        formRef?.current?.resetForm({ values: {} });
      });
    } else {
      createVendorInstore(values, () => {
        refreshList();
        closeHandler()
        formRef?.current?.resetForm({ values: {} });
      });
    }
  };

  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={vendorInstoreFormValidation}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <ModalComponent
          visible={visible}
          closeHandler={resetFormAndClose}
          type="large"
          title={data ? t("edit_instore") : t("add_new_instore")}
          successProps={{
            title: data ? t("update") : t("create"),
            clickHandler: handleSubmit,
            loading: false,
          }}
          cancelProps={{
            title: t("discard"),
            clickHandler: resetFormAndClose,
          }}
          className="vendor-instore-form-modal"
          destroyOnClose={true}
        >
          <Form>
            <div className="vendor-instore-form">
              <Row gutter={16}>
                <Col className="filter-field" span={24}>
                  <InputField
                    label="Instore Name"
                    name="name"
                    placeholder="Enter"
                  />
                </Col>
                <Col className="filter-field" span={12}>
                  <InputField
                    label="Contact Person First Name"
                    name="contactFirstName"
                    placeholder="Enter"
                  />
                </Col>
                <Col className="filter-field" span={12}>
                  <InputField
                    label="Contact Person Last Name"
                    name="contactLastName"
                    placeholder="Enter"
                  />
                </Col>
                <Col className="filter-field" span={12}>
                  <InputField label="Email" name="email" placeholder="Enter" />
                </Col>
                <Col className="filter-field" span={12}>
                  <InputField
                      addonBefore={<Select
                          value={values?.countryCodeId ?? ''}
                          onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                          if(typeof value !== "string"){
                              setFieldValue("countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                              setFieldValue("countryCodeId", value)
                          } 
                          setFieldValue("mobileNumber", undefined)
                          }}
                          options={generateCodeOptions(countryCodesMeta)}
                          />
                      }
                      name="mobileNumber"
                      label="Phone Number"
                      placeholder="Enter" />
                    {/* TODO: Needed for production */}
                  {/* <InputField prefix='+20' type="text" name="mobileNumber" label='Mobile Number' placeholder="Enter" /> */}
                </Col>
                <Col className="filter-field" span={24}>
                  <InputField
                    label="Address"
                    name="address"
                    placeholder="Enter"
                  />
                </Col>
                <Col className="filter-field" span={24}>
                  <MapComponent
                    position={
                      values?.lat && values?.long
                        ? {
                          lat: values?.lat ?? 0,
                          lng: values?.long ?? 0,
                        }
                        : undefined
                    }
                    onChange={(position) => {
                      setFieldValue("lat", position?.lat);
                      setFieldValue("long", position?.lng);
                    }}
                  />
                  <ErrorMessage name={"lat"}>
                    {(message: string) => <Error message={message} />}
                  </ErrorMessage>
                </Col>
                <Divider />
              </Row>
            </div>
          </Form>
        </ModalComponent>
      )}
    </Formik>
  );
};

export default VendorInstoreForm;
