import React, { FC, useEffect } from "react";
import PageView from "../../../../shared/components/PageView";
import "./purchasesView.scss";
import { Col, Row } from "antd";
import PurchaseCustomerDetailsCard from "./PurchaseCustomerDetailsCard";
import PurchaseSummaryCard from "./PurchaseSummaryCard";
import PurchaseReturnSummaryCard from "./PurchaseReturnSummaryCard";
import { useParams } from "react-router-dom";
import PurchaseService from "../../../../services/PurchaseService/purchase.service";

interface PurchaseViewProps { }

const PurchaseView: FC<PurchaseViewProps> = (props) => {
  const { loading, error, fetchPurchase, purchase, challengePurchaseReturn } = PurchaseService()
  const { } = props;

  const { purchaseId } = useParams();

  useEffect(() => {
    if (purchaseId) fetchPurchase(purchaseId);
  }, [purchaseId]);

  return (
    <PageView
      type="back-only"
    >
      <Row className="purchases-view" gutter={[24, 24]}>
        <Col span={8}>
          <PurchaseCustomerDetailsCard purchase={purchase} loading={loading} />
        </Col>
        <Col span={9}>
          <PurchaseSummaryCard purchase={purchase} loading={loading} />
        </Col>
        {purchase?.returnSummary?.createdAt && (
          <Col span={17}>
            <PurchaseReturnSummaryCard purchase={purchase} loading={loading} />
          </Col>
        )}
      </Row>
    </PageView>
  );
};

export default PurchaseView;
