import { Radio, Space } from "antd";
import i18next from "i18next";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LanguageTypes } from "../../../../enums/languageTypes";
import ButtonComponent from "../../ButtonComponent";
import ModalComponent from "../../ModalComponent";
import "./appLanguageModal.scss";

interface AppLanguageModalProps {
  visible: boolean;
  closeHandler: () => void;
}

const AppLanguageModal: FC<AppLanguageModalProps> = (props) => {
  const { visible, closeHandler } = props;
  const { t } = useTranslation();
  const [language, setLanguage] = useState("");
  const handleChange = () => {
    i18next.changeLanguage(language);
    closeHandler();
  };

  useEffect(() => {
    setLanguage(i18next.language);
  }, [i18next.language]);

  return (
    <div className="app_language_change_container">
      <ModalComponent
        type="no-controllers"
        title={t("app_language_t1")}
        visible={visible}
        closeHandler={() => {
          closeHandler();
        }}
        className="app-language-modal"
      >
        <Radio.Group
          className="app-language-radio"
          onChange={(e) => setLanguage(e?.target?.value)}
          value={language}
        >
          <Space direction="vertical">
            <Radio value={LanguageTypes.ENGLISH}>English</Radio>
            <Radio value={LanguageTypes.ARABIC}>عربى</Radio>
          </Space>
        </Radio.Group>
        <ButtonComponent size="wide" type="primary" onClick={handleChange}>
          {t("confirm")}
        </ButtonComponent>
      </ModalComponent>
    </div>
  );
};

export default AppLanguageModal;
