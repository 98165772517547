import { Col, Divider, Dropdown, Row } from "antd";
import React, { FC, Fragment, useEffect, useState } from "react";
import "./profiledetails.scss";
import ProfileEditForm from "./ProfileEditForm";
import ChangePasswordForm from "./ChangePasswordForm";
import IconComponent from "../IconComponent";
import { LocalStorage } from "../../utils/localStorage";
import AuthService from "../../../services/AuthService/auth.service";
import VendorService from "../../../services/VendorService/vendor.service";
import AppLanguageModal from "./AppLanguageModal";
import { useTranslation } from "react-i18next";
import { InternalUsersModel } from "../../../models/InternalUsers/internalusers.model";

interface ProfileDetailsProps {}

const ProfileDetails: FC<ProfileDetailsProps> = (props) => {
  const {} = props;
  const { t } = useTranslation();
  const vendor = LocalStorage.getItem("VENDOR")
  const [showProfileEditForm, setProfileEditFormVisibility] = useState(false);
  const [showPasswordChangeForm, setPasswordChangeFormVisibility] =
    useState(false);
    const [isAppLanguageModalShown, setIsAppLanguageModalShown] = useState(false);
  const { logout,loading, error } = AuthService();
  const { fetchVendorProfile, vendorProfile } = VendorService()
  useEffect(() => {
    fetchVendorProfile()
  }, [])
  
  return (
    <Fragment>
       <AppLanguageModal
        visible={isAppLanguageModalShown}
        closeHandler={() => setIsAppLanguageModalShown(false)}
      />
      <ProfileEditForm
        visible={showProfileEditForm}
        closeHandler={() =>
          setProfileEditFormVisibility((visibility) => !visibility)
        }
        profile={vendor ?? vendorProfile as InternalUsersModel}
      />
      <ChangePasswordForm
        visible={showPasswordChangeForm}
        closeHandler={() =>
          setPasswordChangeFormVisibility((visibility) => !visibility)
        }
      />
      <Dropdown
        className="profile-details"
        trigger={["click"]}
        overlay={
          <div className="profile-details__overlay">
            <Row className="profile-details-name__container" align="middle">
              <Col span={6}>
                <IconComponent name="icon-user-profile-black"/>
              </Col>
              <Col span={18}>
                <p className="name">{vendor?.fullName ?? vendorProfile?.fullName}</p>
              </Col>
            </Row>
            <div className="profile-details-contact__container">
              <p className="phone">
                <IconComponent name="icon-phone-blue" />
                {vendor?.countryCode?.isdCode ?? vendorProfile?.countryCode?.isdCode} {vendor?.mobileNumber ?? vendorProfile?.mobileNumber}
              </p>
              <p className="email">
                <IconComponent name="icon-email-blue" />
                {vendor?.email ?? vendorProfile?.email}
              </p>
            </div>
            <Divider />
            <div className="profile-details-options__container">
              <div
                className="edit-profile"
                onClick={() => setProfileEditFormVisibility(true)}
              >
                <IconComponent name="icon-edit-black" />
                {t("edit_profile")}
              </div>
              <div
                className="change-password"
                onClick={() => setPasswordChangeFormVisibility(true)}
              >
                <IconComponent name="icon-password-black" />
                {t("change_password")}
              </div>
              {/* TODO: reuired for next phase */}
              {/* <div
                className="edit-profile"
                onClick={() => setIsAppLanguageModalShown(true)}
              >
                <IconComponent name="icon-language-black" />
                {t("app_language")}
              </div> */}
              <div
                className="logout"
                onClick={(e) => {
                    logout()
                    localStorage.clear()
                    window.location.reload()
                    e.stopPropagation()
                }}
              >
                <IconComponent name="icon-logout" />
                {t("logout")}
              </div>
            </div>
          </div>
        }
      >
        <div>
          <IconComponent name="icon-user-profile-blue" />
        </div>
      </Dropdown>
    </Fragment>
  );
};

export default ProfileDetails;
