import React from "react";
import { Menu } from "antd";
import "./navbar.scss";
import Notification from "../Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import AppIcon from "../../../assets/images/app-logo-white.svg";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import IconComponent from "../IconComponent";
import logo from '../../../assets/images/logo-nav-footer.png'
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context/AuthContext";

const Navbar = (props: any) => {
  const handleClick = () => { };
  const navigate = useNavigate();
  const location = useLocation();
  const {user} = AuthContext()
  const {t} = useTranslation()
  const navbarItems = [
    {
      title: t("dashboard"),
      key: "dashboard",
      path: AppRoutes.DASHBOARD,
      icon: <IconComponent name="icon-dashboard" />,
    },
    {
      title: t("purchases"),
      key: "purchases",
      path: AppRoutes.PURCHASES,
      icon: <IconComponent name="icon-purchases" />,
    },
    {
      title:  t("settlements"),
      key: "settlements",
      hash: "pending",
      path: AppRoutes.SETTLEMENTS,
      icon: <IconComponent name="icon-settlements" />,
    },
    {
      title: t("internal_users"),
      key: "internal-users",
      path: AppRoutes.INTERNAL_USERS,
      icon: <IconComponent name="icon-user-config" />,
    },
    {
      title: t("vendor_profile"),
      key: "vendor-profile",
      path: AppRoutes.VENDOR_PROFILE,
      icon: <IconComponent name="icon-payments" />,
    },
  ];

  return (
    <div className="navbar__container">
      <img src={user?.logo?.s3Url} className="navbar-vendor-logo__img" />
      <Menu
        onClick={handleClick}
        className="navbar"
        selectedKeys={[location?.pathname?.split("/")[1]]}
      >
        {navbarItems?.map((value, index) => {
          return (
            <Menu.Item
              className="navbar-item"
              key={value?.key}
              onClick={() =>
                navigate({ pathname: value?.path, hash: value?.hash })
              }
            >
              <div className="navbar-item__title">
                <div className="navbar-item__icon">
                {value?.icon}
                </div>
                {value?.title}
              </div>
            </Menu.Item>
          );
        })}
      </Menu>
      <div className="navbar-footer">
        <p>{t("powered_by")}</p>
        <img
          className='app-logo'
          src={logo}
        />
      </div>
    </div>
  );
};

export default Navbar;
