import * as Yup from "yup";
import { REGEX } from "../../../enums/regex";

export const newPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .matches(REGEX.PASSWORD.match, REGEX.PASSWORD.error)
    .min(8, 'Password has to be longer than 8 characters.')
    .required('Password is required.'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Your passwords do not match."
  )
    .matches(REGEX.PASSWORD.match, REGEX.PASSWORD.error)
    .required("Confirm Password is required."),
});
