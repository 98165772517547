import { notification, message as MobileNotification } from 'antd';
import { ArgsProps } from "antd/lib/message";
import React from "react";
import { NotificationTypes } from '../../../enums/notificationTypes';
import IconComponent from '../IconComponent';
interface INotification {
    message?: string;
    description: string;
    type: string
}
const successIcon = () => {
    return (
        <IconComponent name="icon-info"/>
    )
}

type notificationType = "success" | "error" | "warning" | "info" | "open";

const Notification = ({ message, description, type }: INotification) => {
    if (window.innerWidth <= 768) {
        return MobileNotification[type as notificationType]({
            content: message
        } as ArgsProps);
    }
    return notification[type as notificationType]({
        message,
        description,
        icon: type == NotificationTypes.SUCCESS && successIcon(),
        closeIcon: <IconComponent name="icon-close-black" />
  })
};

export default Notification; 