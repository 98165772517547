import React, { FC, useEffect, useState } from "react";
import "./pastSettlements.scss";
import TableComponent, {
  TableFilterComponent,
  TableFilterDropdownComponent,
} from "../../../../shared/components/TableComponent";
import { currencyString } from "../..";
import { Form, Formik, FormikValues } from "formik";
import { Col, Row } from "antd";
import DatePickerField from "../../../../shared/components/DatePickerField";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import { tableSorter } from "../../../../shared/utils/tableSorter";
import NoTransactionsImg from "../../../../assets/empty/no_transactions_data.svg";
import moment from "moment";
import { serialize } from "serializr";
import { PastSettlementFilterModel } from "../../../../models/Settlement/settlement.model";
import { removeUndefined } from "../../../../shared/utils/removeUndefinied";
import { SettlementService } from "../../../../services/SettlementService/settlement.service";
import { useTranslation } from "react-i18next";
import { SortFilterModel } from "../../../../models/SortFilterModel/SortFilterModel.model";
import { SortColumnTypes } from "../../../../shared/types/SortColumn.type";
import { formatDate } from "../../../../shared/utils/formatDate";
interface PastSettlementsProps {}

const PastSettlements: FC<PastSettlementsProps> = (props) => {
  const {} = props;
  const [isFilterShown, setIsFilterShown] = useState(false);
  const navigate = useNavigate();
  const { pastSettlements, fetchPastSettlementList, loading, paginations } =
    SettlementService();
  const [filters, setFilters] = useState<PastSettlementFilterModel>({});
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")
  const [sortOptions, setSortOptions] = useState<SortFilterModel>()
  const filterInitialValues = new PastSettlementFilterModel();
  const {t} = useTranslation()

  const handleFilterSubmit = (values: PastSettlementFilterModel) => {
    setFilters((prev) => ({ ...prev, ...removeUndefined(values) }));
    handleFilterClose();
  };

  const handlePageChange = (page:number) => {
    setPage(page)
    fetchPastSettlementList({...filters,page, ...sortOptions})
  }

  useEffect(() => {
    fetchPastSettlementList({...filters, ...sortOptions});
  }, [filters]);

  const handleFilterClose = () => {
    setIsFilterShown(false);
  };

  const filter = (
    <TableFilterDropdownComponent
      visibilityHandler={(visible) => setIsFilterShown(visible)}
      visible={isFilterShown}
      count={
        Object.entries(filters)?.filter(
          (value) => value?.[0] !== "search" && value?.[1]
        )?.length
      }
    >
      <Formik initialValues={filterInitialValues} onSubmit={handleFilterSubmit}>
        {({ values, handleSubmit, setFieldValue, resetForm }) => (
          <TableFilterComponent
            applyHandler={handleSubmit}
            clearHandler={() => {
              handleFilterClose();
              setFilters(() => ({
                ...filterInitialValues,
                search: filters?.search,
              }));
              resetForm({});
            }}
          >
            <Form>
              <Row gutter={16}>
                <Col className="filter-field" span={24}>
                  <DatePickerField
                    name="createdAt"
                    placeholder="Enter"
                    label="Settled Date"
                    value={values?.createdAt}
                    onChange={(date, dateString) => {
                      setFieldValue("createdAt", dateString);
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </TableFilterComponent>
        )}
      </Formik>
    </TableFilterDropdownComponent>
  );
  return (
    <div className="past-settlements">
      <TableComponent
      paginationMeta={paginations}
      handlePageChange={handlePageChange}
      onChange={fetchPastSettlementList}
        onSort={(data)=>{
         setSortOptions(data)
        }}
        filtersSorts={{page, ...filters, search}}
        search={{
          placeholder: "Search settlement by transaction id",
          onSearch: (value) => {
            setSearch(value)
            handleFilterSubmit({ search: value });
          },
        }}
        empty={{
          text: "No past settlements",
          img: NoTransactionsImg,
        }}
        filter={filter}
        rowClassName={() => "cursor-pointer"}
        onRow={(data) =>
          navigate(
            generatePath(AppRoutes.PAST_SETTLEMENTS_VIEW, {
              settlementId: data?.id,
            })
          )
        }
        columns={[
          {
            title: t("settled_date"),
            dataIndex: "settledDate",
            key: SortColumnTypes.VENDOR_SETTLEMENT_SETTLED_AT,
            render: (text) => formatDate(text),
            sorter: true,
          },

          {
            title: "Settlement id",
            dataIndex: "transactionId",
            key: "transactionId",
          },
          {
            title: "Purchases Time Period",
            dataIndex: "purchaseTimePeriod",
            render: (text, record) => (
            <span>
              {formatDate(record?.minimumPurchaseDate)} - {formatDate(record?.maximumPurchaseDate)}
            </span>
            ),
          },
          {
            title: "# of Purchases",
            dataIndex: "noOfOrders",
            key: SortColumnTypes.SETTLEMENT_NO_OF_ORDERS,
            sorter: true,
          },
          {
            title: "Total settlement amount",
            dataIndex: "totalAmount",
            key: SortColumnTypes.SETTLEMENT_AMOUNT,
            render: (text, record) => (
              <span>
                {currencyString}
                {text}
              </span>
            ),
            sorter: false,
          },
        ]}
        data={pastSettlements}
        loading={loading}
      />
    </div>
  );
};

export default PastSettlements;
