import { useState } from "react";
import { generatePath } from "react-router-dom";
import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  VendorStorePromotionCreationModel,
  VendorStorePromotionModel,
} from "../../models/Vendor/StorePromotion/storePromotion.model";
import { VendorPurchaseConfigModel } from "../../models/Vendor/PurchaseConfig/purchaseConfig.model";
import { Vendor, VendorListModel, VendorModel } from "../../models/Vendor/vendor.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import {
  VendorInstoreCreationModel,
  VendorInstoreModel,
} from "../../models/Vendor/Instore/instore.model";
import { VendorSettlementModel } from "../../models/Vendor/Settlement/settlement.model";
import { InternalUsersModel } from "../../models/InternalUsers/internalusers.model";
import { PaginationModel } from "../../models/pagination.model";
import { LocalStorage } from "../../shared/utils/localStorage";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { SortFilterModel } from "../../models/SortFilterModel/SortFilterModel.model";

const VendorService = () => {
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const [vendor, setVendor] = useState<Vendor>({});
  const [vendorProfile, setVendorProfile] = useState<InternalUsersModel>();
  const [vendorStorePromotions, setVendorStorePromotions] = useState<
    VendorStorePromotionModel[]
  >([]);
  const [vendorInstores, setVendorInstores] = useState<VendorInstoreModel[]>(
    []
  );
  const [vendorInstore, setVendorInstore] = useState<VendorInstoreModel>();

  const [paginations, setPaginations] = useState<PaginationModel>()

  const updateVendor = (
    vendor: FormData,
    onSuccess: () => void,
    onError?: Function,
    onFinal?: () => void,
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_DETAILS);

    return axiosInstance
      .put(API_URL, vendor)
      .then((response) => {
        const vendor = deserialize(VendorModel, response?.data?.vendor);
        onSuccess();
      })
      .catch((error) => {
        setError(error);
        onError && onError()
      })
      .finally(() => {
        setLoading(false);
        onFinal && onFinal()
      });
  };

  const fetchVendor = () => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_DETAILS);
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const vendor = deserialize(Vendor, response?.data?.vendor);
        setVendor(vendor);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchStorePromotionList = () => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_STORE_PROMOTIONS);
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          VendorStorePromotionModel,
          response?.data?.["vendor_attachments"]
        ) as VendorStorePromotionModel[];
        setVendorStorePromotions(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createVendorStorePromotion = (
    data: VendorStorePromotionCreationModel,
    callback: (vendorStorePromotion?: VendorStorePromotionModel) => void
  ) => {
    const requestPayload = {
      vendor_attachment: serialize(VendorStorePromotionCreationModel, data),
    };
    const API_URL = generatePath(ApiRoutes.VENDOR_STORE_PROMOTIONS);
    return axiosInstance
      .post(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(
          VendorStorePromotionModel,
          response?.data?.["vendor_attachment"]
        );
        setVendorStorePromotions((prev) => [...prev, data]);
        callback(response?.data?.vendor_attacment);
      })
      .catch((error) => {
        setError(error);
        callback();
      })
      .finally(callback);
  };

  const deleteVendorStorePromotion = (
    storePromotionId: number,
    callback: () => void
  ) => {
    const API_URL = generatePath(ApiRoutes.VENDOR_STORE_PROMOTION, {
      storePromotionId: storePromotionId?.toString(),
    });
    return axiosInstance
      .delete(API_URL)
      .then(() => {
        setVendorStorePromotions((prev) =>
          prev?.filter((data) => data?.id !== storePromotionId)
        );
        callback();
      })
      .catch((error) => {
        setError(error);
        callback();
      })
      .finally(callback);
  };

  const fetchVendorInstoreList = (
    params?: SortFilterModel
  ) => {
    setLoading(true);
    const _params = params && serialize(SortFilterModel, params)
    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORES);
    return axiosInstance
      .get(API_URL, { params: _params })
      .then((response) => {
        const data = deserialize(
          VendorInstoreModel,
          response?.data?.instores
        ) as VendorInstoreModel[];
        const meta = deserialize(PaginationModel, response?.data?.meta)
        setPaginations(meta);
        setVendorInstores(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchVendorInstore = (
    instoreId: string
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE, {instoreId});
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          VendorInstoreModel,
          response?.data?.instore
        );
        setVendorInstore(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createVendorInstore = (
    data: VendorInstoreCreationModel,
    onSuccess: () => void
  ) => {
    setLoading(true);

    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORES);
    const requestPayload = {
      instore: serialize(VendorInstoreCreationModel, data),
    };
    return axiosInstance
      .post(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(VendorInstoreModel, response?.data?.instore);
        setVendorInstores((prev) => [...prev, data]);
        onSuccess();
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVendorInstore = (
    vendorInstoreId: number,
    data: VendorInstoreCreationModel,
    onSuccess: () => void,
    onError?: Function
  ) => {
    setLoading(true);

    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE, {
      instoreId: vendorInstoreId?.toString(),
    });
    const requestPayload = {
      instore: serialize(VendorInstoreCreationModel, data),
    };
    return axiosInstance
      .put(API_URL, requestPayload)
      .then((response) => {
        const data = deserialize(VendorInstoreModel, response?.data?.instore);
        setVendorInstores((prev) =>
          prev?.map((instore) => (instore?.id == data?.id ? data : instore))
        );
        onSuccess();
      })
      .catch((error) => {
        setError(error);
        onError && onError();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteVendorInstore = (instoreId: number) => {
    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE, {
      instoreId: instoreId?.toString(),
    });
    return axiosInstance.delete(API_URL);
  };

  const fetchVendorSettlementList = (vendorId: string) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.VENDOR_INSTORE, {
      vendorId,
    });
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const data = deserialize(
          VendorSettlementModel,
          response?.data?.vendor_settlements
        ) as VendorSettlementModel[];
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchVendorProfile = () => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CURRENT_VENDOR_DETAILS);
    return axiosInstance
      .get(API_URL)
      .then((response) => {
        const vendorProfile = deserialize(InternalUsersModel, response?.data?.vendor_user);
        setVendorProfile(vendorProfile);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateVendorProfile = (
    vendorProfile: FormData|InternalUsersModel,
    onSuccess: () => void,
    onError?: Function,
    onFinal?: () => void,
  ) => {
    setLoading(true);
    const API_URL = generatePath(ApiRoutes.CURRENT_VENDOR_DETAILS);

    return axiosInstance
      .put(API_URL, {"vendor_user" : {
        ...serialize(InternalUsersModel,vendorProfile)
      }})
      .then((response) => {
        const vendorProfile = deserialize(InternalUsersModel, response?.data?.vendor_user);
        LocalStorage.setItem("VENDOR",vendorProfile)
        setVendorProfile(vendorProfile);
        Notification({
          description: "Your profile has been updated successfully.",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        setError(error);
        onError && onError()
      })
      .finally(() => {
        setLoading(false);
        onFinal && onFinal()
      });
  };

  return {
    loading,
    error,
    vendor,
    vendorStorePromotions,
    vendorInstores,
    fetchVendor,
    updateVendor,
    fetchStorePromotionList,
    createVendorStorePromotion,
    deleteVendorStorePromotion,
    fetchVendorInstoreList,
    createVendorInstore,
    updateVendorInstore,
    deleteVendorInstore,
    fetchVendorSettlementList,
    paginations,
    fetchVendorInstore,
    vendorInstore,

    vendorProfile,
    fetchVendorProfile,
    updateVendorProfile
  };
};

export default VendorService;
