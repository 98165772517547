import { Col, Divider, Row, Select } from "antd";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { serialize } from "serializr";
import { SupportDocuments, Vendor, VendorModel } from "../../../../models/Vendor/vendor.model";
import VendorService from "../../../../services/VendorService/vendor.service";
import MetaService from "../../../../services/MetaService/meta.service";
import ColorPickerComponent from "../../../../shared/components/ColorPickerComponent";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import ModalComponent from "../../../../shared/components/ModalComponent";
import RadioComponent from "../../../../shared/components/RadioComponent";
import UploadComponent from "../../../../shared/components/UploadComponent";
import "./vendorForm.scss";
import { vendorFormValidation } from "./vendorFormValidation";
import S3AttachmentService from "../../../../services/S3UploadService/s3upload.service";
import { VendorTypes } from "../../../../enums/vendorTypes";
import { generateCodeOptions, getCountryIsdCode } from "../../../../shared/utils/generateCountryCodeOptions";

interface VendorFormProps {
  data?: Vendor;
  visible: boolean;
  closeHandler: () => void;
  successHandler: (values?: Vendor) => void;
}

const { BOTH, ONLINE, INSTORE } = VendorTypes

const VendorForm: FC<VendorFormProps> = (props) => {
  const { visible, closeHandler, data, successHandler } = props;
  const [initialValues, setInitialValues] = useState<Vendor>({});
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const { loading, updateVendor } = VendorService();
  const { uploadAttachment, loading: attachmentLoading } = S3AttachmentService()
  const { fetchCategoryList, fetchSubCategoryList, categories, subCategories, getWalletTypesMeta, walletTypesMeta, getCountryCodesMeta, countryCodesMeta } = MetaService()
  const { t } = useTranslation()

  const onSubmit = (values: Vendor) => {
    let payload = { ...values };
    delete payload?.vendorDetails?.["coverPicUrl"];
    delete payload?.vendorDetails?.["logoUrl"];
    delete payload?.supportDocuments?.["commercialRegistrationUrl"];
    delete payload?.supportDocuments?.["taxCardUrl"];

    const jsonData: any = {
      vendor: {
        ...serialize(Vendor, payload)
      }
    }
    updateVendor(jsonData, () => {
      successHandler(payload);
      formRef?.current?.resetForm({ values: {} });
    });
  };

  useEffect(() => {
    if (data)
      setInitialValues({
        ...data,
        paymentDetails: {
          ...data?.paymentDetails,
          walletTypeId: data?.paymentDetails?.walletType?.id as unknown as string,
        },
        vendorDetails: {
          ...data?.vendorDetails,
          logoId: data?.vendorDetails?.logo?.id,
          coverPicId: data?.vendorDetails?.coverPic?.id,
          maxCreditPerOrder: data?.vendorDetails?.maxCreditPerOrder ?? 0
        },
        supportDocuments: {
          ...data?.supportDocuments,
          commercialRegistrationId: data?.supportDocuments?.commercialRegistration?.id,
          taxCardId: data?.supportDocuments?.taxCard?.id,
        }
      });
  }, [data, visible]);

  useEffect(() => {
    fetchCategoryList();
    getWalletTypesMeta();
    getCountryCodesMeta();
  }, []);

  const resetFormAndClose = () => {
    formRef?.current?.resetForm({ values: {} });
    closeHandler()
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={vendorFormValidation}
    >
      {({ values, setFieldValue, handleSubmit, touched, errors }) => {
        return (
          <Form>
            <ModalComponent
              visible={visible}
              closeHandler={resetFormAndClose}
              type="large"
              successProps={{
                title: t("update"),
                clickHandler: handleSubmit,
                loading: loading || attachmentLoading,
              }}
              cancelProps={{
                title: t("discard"),
                clickHandler: resetFormAndClose,
              }}
              className="vendor-form-modal"
              destroyOnClose={true}
            >
              <div className="vendor-form">
                <UploadComponent
                  accept={"image/png, image/jpg, image/jpeg"}
                  fileUrl={values?.vendorDetails?.coverPic?.s3Url}
                  fileId={values?.vendorDetails?.coverPicId ?? values?.vendorDetails?.coverPic?.id ?? ""}
                  fileType={values?.vendorDetails?.coverPic?.fileType ?? ""}
                  fileName={values?.vendorDetails?.coverPic?.filename ?? ""}
                  type="cover-picture"
                  title={"Cover Picture"}
                  onRemove={() => {
                    setFieldValue("vendorDetails.coverPic.s3Url", "");
                    setFieldValue("vendorDetails.coverPicId", null);
                  }}
                  onUpload={async (file, fileUrl) => {
                    setFieldValue("vendorDetails.coverPic.s3Url", fileUrl);
                    const _cover = await uploadAttachment(file);
                    setFieldValue("vendorDetails.coverPicId", _cover);
                  }}
                  error={(errors?.vendorDetails as VendorModel)?.coverPicId}
                />
                <div className="vendor-form-details__container">
                  <div className="vendor-display-picture-name__container">
                    <UploadComponent
                      accept={"image/png, image/jpg, image/jpeg"}
                      fileUrl={values?.vendorDetails?.logo?.s3Url}
                      fileId={values?.vendorDetails?.logoId ?? values?.vendorDetails?.logo?.id}
                      fileType={values?.vendorDetails?.logo?.fileType}
                      fileName={values?.vendorDetails?.logo?.filename}
                      type="logo"
                      title={"Logo"}
                      onUpload={async (file, fileUrl) => {
                        setFieldValue("vendorDetails.logo.s3Url", fileUrl);
                        const _logo = await uploadAttachment(file);
                        setFieldValue("vendorDetails.logoId", _logo);
                      }}
                      onRemove={() => {
                        setFieldValue("vendorDetails.logo.s3Url", undefined);
                        setFieldValue("vendorDetails.logoId", undefined);
                      }}
                      error={(errors?.vendorDetails as VendorModel)?.logoId}
                    />
                    <InputField
                      name="vendorDetails.name"
                      className="vendor-name__input"
                      placeholder="Vendor Name"
                    />
                  </div>
                  <h4>{t("contact_person_details")}</h4>
                  <Row gutter={16}>
                    <Col className="filter-field" span={12}>
                      <InputField
                        label="First Name"
                        placeholder="Enter"
                        name="contactPersonDetails.firstName"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        label="Last Name"
                        placeholder="Enter"
                        name="contactPersonDetails.lastName"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        disabled
                        label="Email"
                        placeholder="Enter"
                        name="contactPersonDetails.email"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        addonBefore={<Select
                          disabled
                          getPopupContainer={trigger => trigger.parentNode}
                          value={values?.contactPersonDetails?.countryCodeId ?? ''}
                          onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                            if(typeof value !== "string"){
                              setFieldValue("contactPersonDetails.countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                              setFieldValue("contactPersonDetails.countryCodeId", value)
                            } 
                            setFieldValue("contactPersonDetails.mobileNumber", undefined)
                          }}
                          options={generateCodeOptions(countryCodesMeta)}
                          />
                        }
                        name="contactPersonDetails.mobileNumber"
                        label="Phone Number"
                        disabled
                        placeholder="Enter" />
                        {/* TODO: Needed for production */}
                      {/* <InputField prefix='+20' type="text" name="contactPersonDetails.mobileNumber" label='Mobile Number' placeholder="Enter" /> */}
                    </Col>
                  </Row>
                  <Divider />
                  <h4>{t("vendor_details")}</h4>
                  <Row gutter={16}>
                    <Col className="filter-field" span={12}>
                      <InputField
                        disabled
                        label="Email"
                        placeholder="Enter"
                        name="vendorDetails.email"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                        <InputField
                          addonBefore={<Select
                            disabled
                            getPopupContainer={trigger => trigger.parentNode}
                            value={values?.vendorDetails?.countryCodeId ?? ''}
                            onSelect={(value: string | number | undefined, options: { value: string | number | undefined, label: string }) => {
                              if(typeof value !== "string"){
                                setFieldValue("vendorDetails.countryCode.isdCode", getCountryIsdCode(countryCodesMeta, value))
                                setFieldValue("vendorDetails.countryCodeId", value)
                              } 
                              setFieldValue("vendorDetails.mobileNumber", undefined)
                            }}
                            options={generateCodeOptions(countryCodesMeta)}
                            />
                          }
                          name="vendorDetails.mobileNumber"
                          label="Phone Number"
                          disabled
                          placeholder="Enter" />
                        {/* TODO: Needed for production */}
                      {/* <InputField prefix='+20' type="text" name="vendorDetails.mobileNumber" label='Mobile Number' placeholder="Enter" /> */}
                    </Col>
                    <Col className="filter-field" span={12}>
                      <DropdownField
                        label="Category"
                        placeholder="Select"
                        name="vendorDetails.categoryId"
                        value={values?.vendorDetails?.categoryId}
                        options={categories}
                        onChange={(value) => {
                          setFieldValue("vendorDetails.categoryId", value);
                          if (value !== values?.vendorDetails?.categoryId) {
                            setFieldValue("vendorDetails.subCategoryId", undefined);
                          }
                          fetchSubCategoryList(value);
                        }}
                      />
                    </Col>
                    {/* TODO: required for next phase */}

                    {/* <Col className="filter-field" span={12}>
                      <DropdownField
                        label="Sub Category"
                        placeholder="Select"
                        name="vendorDetails.subCategoryId"
                        value={values?.vendorDetails?.subCategoryId}
                        options={subCategories}
                        onChange={(value) =>
                          setFieldValue("vendorDetails.subCategoryId", value)
                        }
                      />
                    </Col> */}
                    <Col className="filter-field" span={24}>
                      <RadioComponent
                        name="vendorDetails.type"
                        label="Category"
                        value={values?.vendorDetails?.type}
                        data={[
                          { value: "online", label: "Online" },
                          { value: "offline", label: "Instore" },
                          { value: "both", label: "Both" },
                        ]}
                        onChange={(value) => setFieldValue("vendorDetails.type", value)}
                      />
                    </Col>
                    {(values?.vendorDetails?.type == BOTH || values?.vendorDetails?.type == ONLINE) && (
                      <Col className="filter-field" span={12}>
                        <InputField
                          label="Website URL"
                          placeholder="Enter"
                          name="vendorDetails.websiteUrl"
                        />
                      </Col>
                    )}

                    {(values?.vendorDetails?.type == BOTH || values?.vendorDetails?.type == INSTORE) && (
                      <Col className="filter-field" span={12}>
                        <InputField
                          label="# of Physical Stores"
                          placeholder="Enter"
                          name="vendorDetails.noOfPhysicalStores"
                        />
                      </Col>
                    )}

                    <Col className="filter-field" span={12}>
                      <InputField
                        label="Monthly Transaction Volume"
                        placeholder="Enter"
                        name="vendorDetails.monthlyTransactionVolume"
                        type="number"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField
                        label="Max Credit Per Order"
                        placeholder="Enter"
                        name="vendorDetails.maxCreditPerOrder"
                      />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <ColorPickerComponent
                        label="Brand Colour"
                        value={values?.vendorDetails?.brandColour ?? "#0b0a10"}
                        onChange={(hexCode) =>
                          setFieldValue("vendorDetails.brandColour", hexCode)
                        }
                      />
                    </Col>
                  </Row>
                  <Divider />
                  <h4>{t("vendor_payment_details")}</h4>
                  <Row gutter={16}>
                    <Col className="filter-field" span={24}>
                      <InputField placeholder='Enter' label='Account Holder Name' name='paymentDetails.accountHolderName' />
                    </Col>
                    <Col className="filter-field" span={24}>
                      <InputField placeholder='Enter' label='Account Holder Address' name='paymentDetails.address' />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField placeholder='Enter' label='Account Number' name='paymentDetails.accountNumber' />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField placeholder='Enter' label='IBAN' prefix='EG' name='paymentDetails.iban' />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField placeholder='Enter' label='Swift Code' name='paymentDetails.swiftCode' />
                    </Col>
                    <Col className="filter-field" span={12}>
                      <InputField placeholder='Enter' label='Branch' name='paymentDetails.branch' />
                    </Col>
                    {/* TODO - Required for E-wallet integration */}
                    {/* <Col className="filter-field" span={12}>
                      <InputField placeholder='Enter' label='Mobile Wallet Number' name='paymentDetails.walletNumber' onChange={(value)=>{
                        setFieldValue("paymentDetails.walletNumber", value)
                        !value && setFieldValue("paymentDetails.walletTypeId", "")
                      }}/>
                    </Col>
                    <Col className="filter-field" span={12}>
                      <DropdownField
                        disabled={!values?.paymentDetails?.walletNumber}
                        label="Wallet Type"
                        options={walletTypesMeta}
                        name="paymentDetails.walletTypeId"
                        placeholder="Select"
                        onChange={(value) => setFieldValue("paymentDetails.walletTypeId", value)}
                        value={values?.paymentDetails?.walletTypeId ?? values?.paymentDetails?.walletType?.id ?? null}
                      />
                    </Col> */}
                  </Row>
                  <Divider />
                  <h4>{t("support_documents")}</h4>
                  <Row>
                    <Col className="filter-field" span={12}>
                      <UploadComponent
                        viewType="modal"
                        accept={"image/png, image/jpg, image/jpeg, application/pdf"}
                        fileUrl={values?.supportDocuments?.commercialRegistration?.s3Url}
                        fileId={values?.supportDocuments?.commercialRegistrationId ?? values?.supportDocuments?.commercialRegistration?.id ?? ""}
                        fileType={values?.supportDocuments?.commercialRegistration?.fileType ?? ""}
                        fileName={values?.supportDocuments?.commercialRegistration?.filename ?? ""}
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("supportDocuments.commercialRegistration.s3Url", fileUrl);
                          setFieldValue("supportDocuments.commercialRegistration.filename", file?.name);
                          const _commercial = await uploadAttachment(file);
                          setFieldValue("supportDocuments.commercialRegistrationId", _commercial);
                        }}
                        title="Upload Document"
                        type="document"
                        label="Commercial Registration"
                        error={(errors?.supportDocuments as SupportDocuments)?.commercialRegistrationId}
                      />
                    </Col>
                    <Col className="filter-field" span={12} />
                    <Col className="filter-field" span={12}>
                      <UploadComponent
                        viewType="modal"
                        accept={"image/png, image/jpg, image/jpeg, application/pdf"}
                        fileUrl={values?.supportDocuments?.taxCard?.s3Url}
                        fileId={values?.supportDocuments?.taxCardId ?? values?.supportDocuments?.taxCard?.id ?? ""}
                        fileType={values?.supportDocuments?.taxCard?.fileType ?? ""}
                        fileName={values?.supportDocuments?.taxCard?.filename ?? ""}
                        onUpload={async (file, fileUrl) => {
                          setFieldValue("supportDocuments.taxCard.s3Url", fileUrl);
                          setFieldValue("supportDocuments.taxCard.filename", file?.name);
                          const _tax = await uploadAttachment(file);
                          setFieldValue("supportDocuments.taxCardId", _tax);
                        }}
                        title="Upload Document"
                        type="document"
                        label="Tax Card"
                        error={(errors?.supportDocuments as SupportDocuments)?.taxCardId}
                      />
                    </Col>
                    <Divider />
                    <Col className="filter-field" span={12} />
                  </Row>
                </div>
              </div>
            </ModalComponent>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VendorForm;
