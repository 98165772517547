import { serializable, alias, object, list, primitive } from "serializr";

export class S3AttachementModel {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("filename", primitive()))
  filename?: string;

  @serializable(alias("file_type", primitive()))
  fileType?: string;

  @serializable(alias("s3_key", primitive()))
  s3Key?: string;
}

export class S3PresignedUrlFieldsModel {
  @serializable(alias("key", primitive()))
  key?: string;

  @serializable(alias("success_action_status", primitive()))
  successActionStatus?: string;

  @serializable(alias("policy", primitive()))
  policy?: string;

  @serializable(alias("x-amz-credential", primitive()))
  xAmzCredential?: string;

  @serializable(alias("x-amz-algorithm", primitive()))
  xAmzAlgorithm?: string;

  @serializable(alias("x-amz-date", primitive()))
  xAmzDate?: string;

  @serializable(alias("x-amz-signature", primitive()))
  xAmzSignature?: string;
}

export class S3PresignedUrlModel {
  @serializable(alias("url", primitive()))
  url?: string;

  @serializable(alias("url_fields", object(S3PresignedUrlFieldsModel)))
  urlFields?: S3PresignedUrlFieldsModel;
}
