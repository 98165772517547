import React, { useContext, createContext, useMemo, useState, Dispatch, SetStateAction, Context } from "react";
import { VendorModel } from "../../models/Vendor/vendor.model";
import { LocalStorage } from "../../shared/utils/localStorage";
import { VendorProfileStatusTypes } from "../../enums/vendorTypes";

export interface AuthState {
  authenticated?: boolean;
  user?: VendorModel;
}

type SetAuthState = Dispatch<SetStateAction<AuthState>>;

type AuthContentProps = [AuthState, SetAuthState];

// Define the default context state
const initialValues: AuthState = {
  authenticated: LocalStorage.getItem("VENDOR")?.status === VendorProfileStatusTypes.APPROVED,
  user: LocalStorage.getItem("VENDOR") ?? new VendorModel(),
};

// Create the context
const AuthContent = createContext([[],() => {}]);

// Create method to use context
const AuthContext = () => {
  const context = useContext<AuthContentProps>(AuthContent as unknown as Context<AuthContentProps>);
  if (!context) {
    throw new Error(`useMeContext must be used within a MeContextProvider`);
  }
  const [auth, setAuth] = context;
  
  const setAuthenticated = (user?: VendorModel) => {
    setAuth((auth) => ({
      ...auth,
      authenticated: user?.status === VendorProfileStatusTypes.APPROVED,
      user,
    }));
  };

  const setUnauthenticated = () => {
    setAuth((auth) => ({
      ...auth,
      authenticated: false,
      user: undefined,
    }));
  };

  return {
    ...auth,
    setAuthenticated,
    setUnauthenticated
  };
};

// Create the context provider
const AuthProvider = (ownProps: any) => {
  const [auth, setAuth] = useState<AuthState>(initialValues);
  const value = useMemo(() => [auth, setAuth], [auth]);
  return <AuthContent.Provider value={value} {...ownProps} />;
}

export { AuthProvider, AuthContext };
