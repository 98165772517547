import React from "react";
import { Formik, Form, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import { Link } from "react-router-dom";

import { Button } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { AuthContext } from "../../../context/AuthContext";
import { DownloadOutlined } from '@ant-design/icons';
import ButtonComponent from "../../../shared/components/ButtonComponent";
import "./forgotpassword.scss"
import { ForgotPasswordRequestModel } from "../../../models/Auth/auth.model";
import { forgotPasswordValidationSchema } from "./forgotPasswordValidation";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const { loading, forgotPasswordRedirectUrl } = AuthService();
    const onSubmit = (values: FormikValues) => {
      const payload: ForgotPasswordRequestModel = {
        email: values?.email,
        redirectUrl:
          window.location.origin +  AppRoutes.NEWPASSWORD,
      };
      forgotPasswordRedirectUrl(payload, () => {
        navigate(AppRoutes.PASSWORDRESETLINK)
      });
    };

    const initialValue = {
      email: "",
    };

    return (
        <div className="forgot-password-view">
            <div className='form_heading'>
                <h1>
                    {t("forgot_password_2")}
                </h1>
            </div>
            <p className='info_para'>{t("enter_email_to_recover_password")}</p>
            <Formik
                initialValues={initialValue}
                onSubmit={onSubmit}
                validationSchema={forgotPasswordValidationSchema}
            >
                <Form>
                    <div className='email_part'>
                        <InputField type="email" name="email" label="Email" placeholder="Enter email" />
                    </div>
                    <div className='password_part'>
                        <ButtonComponent type="primary"
                            htmlType="submit"
                            className="auth-form__btn"
                            size="wide"

                        > {t("proceed")} </ButtonComponent>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default ForgotPassword;