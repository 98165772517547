import React, { FC } from "react";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import BadgeComponent from "../../../../../shared/components/BadgeComponent";
import "./purchaseCustomerDetailsCard.scss";
import IconComponent from "../../../../../shared/components/IconComponent";
import { Col, Row } from "antd";
import { PurchaseModel } from "../../../../../models/Purchase/purchase.model";
import { BadgeTypesDefinition } from "../../../../../definitions/badgeTypesDefinition";
import { VendorTypesDefinition } from "../../../../../definitions/vendorTypesDefinition";
import { VendorTypes } from "./../../../../../enums/vendorTypes";
import CardSkeletonComponent from "./../../../../../shared/components/CardSkeletonComponent";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../../shared/utils/formatDate";
import customerDetailsIcon from "./../../../../../assets/images/customer-details.png"
import otherDetailsIcon from "./../../../../../assets/images/other-details.png"
import { maskFirstSixDigits } from "../../../../../shared/utils/maskFirstSixDigit";

interface PurchaseCustomerDetailsCardProps {
  purchase: PurchaseModel | undefined;
  loading: boolean;
}

const PurchaseCustomerDetailsCard: FC<PurchaseCustomerDetailsCardProps> = (
  props
) => {
  const { purchase, loading } = props;
  const { t } = useTranslation()

  return loading ? (
    <CardSkeletonComponent
      className="purchase-customer-details-card-skeleton"
      rows={8}
    />
  ) : (
    <div className="purchase-customer-details-card">
      <Row gutter={[12, 0]} className="customer-details details-row">
        <Col className="image__container">
          <img src={customerDetailsIcon} />
        </Col>
        <Col className="details__container">
          <p className="header">{t("customer")}</p>
          <h2 className="name">{purchase?.customer?.fullName}</h2>
          <Row gutter={[10, 0]} className="key-values">
            <Col>
              <IconComponent name="icon-phone-grey" />
            </Col>
            <Col>
              <span className="value">
                {purchase?.customer?.countryCode?.isdCode} {maskFirstSixDigits(purchase?.customer?.mobileNumber as string)}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[12, 0]} className="vendor-details details-row">
        <Col className="image__container">
          <div className="icon-container">
            <img src={otherDetailsIcon} />
          </div>
        </Col>
        <Col span={19} className="details__container">
          <p className="header">{t("other-details")}</p>
          <h2 className="name">{purchase?.vendor?.name}</h2>
          <Row justify="space-between" gutter={[10, 0]} className="key-values">
            <Col>
              <span className="key">{t("mode")}</span>
            </Col>
            <Col>
              <span className="value">
                {purchase?.vendor?.mode == VendorTypes?.ONLINE
                  ? VendorTypesDefinition[purchase?.vendor?.mode]
                  : purchase?.vendor?.mode == VendorTypes?.INSTORE
                    ? purchase?.vendor?.instore?.name
                    : purchase?.vendor?.type}
              </span>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[10, 0]} className="key-values">
            <Col>
              <span className="key">Purchase ID</span>
            </Col>
            <Col>
              <span className="value">
                {purchase?.purchaseId}
              </span>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[10, 0]} className="key-values">
            <Col>
              <span className="key">Purchase Date</span>
            </Col>
            <Col>
              <span className="value">
                {formatDate(purchase?.createdAt as string)}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseCustomerDetailsCard;
