import { Col, Row, Steps } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppRoutes } from '../../routes/routeConstants/appRoutes'
import "./businessdetails.scss"
import BusinessDetailsForm from './BusinessDetailsForm'
import ContactDetailsForm from './ContactDetailsForm'
import SupportDocumentsForm from './SupportDocumentsForm'

const BusinessDetails = () => {
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()
  const stepValue = Number(searchParams?.get('step') ?? 0)
  const { Step } = Steps;
  const {t}= useTranslation()
  const [currentStep, setCurrentStep] = useState(stepValue)
  const nextHandler = () => {
    currentStep < 2 ?
    setCurrentStep(currentStep+1) :
    navigate(AppRoutes.VERIFYACCOUNT)
  }
  const steps = [
    {
        title: t("business_details"),
        stepLabel: "Business Details",
        component: <BusinessDetailsForm nextHandler={nextHandler}/>,
        stepNumber: 0
    },
    {
        title: t("contact_details"),
        stepLabel: "Business Contact Details",
        component: <ContactDetailsForm nextHandler={nextHandler}/>,
        stepNumber: 1

    },
    {
        title: t("documents"),
        stepLabel: "Support Documents",
        component: <SupportDocumentsForm nextHandler={nextHandler}/>,
        stepNumber: 2
    }
]
  return (
    <div className='business-details--stepper'>
      <Row justify="space-between">
        <Col span={24}>
          <Steps current={currentStep} className=''>
            {steps.map(item => (
              <Step key={item.title} title={item.title} icon={item.stepNumber+1}/>
            ))}
          </Steps>
        </Col>
        <Col span={24}>
          {steps[currentStep].component}
        </Col>
      </Row>
    </div>
  )
}

export default BusinessDetails