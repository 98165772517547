import { Badge, Col, DatePicker, Dropdown, Menu, Row } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { string } from "yup";
import { DashboardDateFilterTypesDefinition } from "../../../definitions/dashboardDateFilterTypeDefinition";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import VendorService from "../../../services/VendorService/vendor.service";
import { DashboardDateFilterTypes } from "../../types/DashboardDateFilterTypes";
import { generateLabelValues } from "../../utils/generateLabelValues";
import ButtonComponent from "../ButtonComponent";
import ContactUs from "../ContactUs";
import DropdownField from "../DropdownField";
import IconComponent from "../IconComponent";
import Notifications from "../Notifications";
import ProfileDetails from "../ProfileDetails";
import backIcon from "./../../../assets/images/back-icon.png"
import "./pageview.scss";
const { RangePicker } = DatePicker;
const { TODAY, CURRENT_MONTH, CURRENT_YEAR, CUSTOM } = DashboardDateFilterTypes

interface PageViewProps {
  title?: {
    icon?: ReactNode;
    name: string;
    span?: string
  };
  onFilterChange?: (values: { selectedFilter: string, selectedInstore: string }) => void;
  type?: "default" | "back-only" | "back" | "dashboard";
  vendorFormHandler?: ()=>void
}

type RangePickerValueType = [moment.Moment, moment.Moment]

const PageView: FC<PageViewProps> = (props) => {
  const { title, type = "default", children, onFilterChange, vendorFormHandler } = props;
  const navigate = useNavigate();
  const location = useLocation()
  const [selectedFilter, setSelectedFilter] = useState<typeof TODAY | typeof CURRENT_MONTH | typeof CURRENT_YEAR | typeof CUSTOM | string>(TODAY)
  const [selectedInstore, setSelectedInstore] = useState<string>("")

  const { fetchVendorInstoreList, vendorInstores } = VendorService()
  const [instoreFilters, setInstoreFilters] = useState<any>()

  useEffect(() => {
    location.pathname === AppRoutes.DASHBOARD &&
      fetchVendorInstoreList()
  }, [])

  useEffect(() => {
    vendorInstores && setInstoreFilters(generateLabelValues("name", "id", vendorInstores))
  }, [vendorInstores])

  useEffect(() => {
    onFilterChange && selectedFilter !== "Select Custom Range" &&
      onFilterChange({ selectedFilter, selectedInstore })
  }, [selectedFilter, selectedInstore])

  const menu = (
    <Menu className="menu-component-overlay" onClick={(e) => e.key !== CUSTOM && setSelectedFilter(e.key as DashboardDateFilterTypes)}>
      <Menu.Item key={TODAY}>Today</Menu.Item>
      <Menu.Item key={CURRENT_MONTH}>Current Month</Menu.Item>
      <Menu.Item key={CURRENT_YEAR}>Current Year</Menu.Item>
      <SubMenu key={CUSTOM} title="Custom Range">
        <RangePicker
          disabledDate={(date) => date.isAfter(moment()) || date < moment().subtract(30, 'day')}
          className="dashboard-filter"
          onChange={(values) => values?.length ? setSelectedFilter(generateDateString(values as RangePickerValueType)) : setSelectedFilter("Select Custom Range")}
          format={'DD/MM/YYYY'} />
      </SubMenu>
    </Menu>
  );

  const generateDateString = (values: RangePickerValueType) => values?.map((i) => i.format("DD/MM/YYYY")).join(" - ")

  return (
    <div className="page-view">
      <div className="page-view__header">
        <Row className="page-view-header-title__container">
          <Col span={15}>
            <div className="dashboard-title">
              <h2>
                {(type === "back" || type === "back-only") && (
                  <span className="back-icon" onClick={() => navigate(-1)}>
                    <img src={backIcon} alt="back" className="back-icon-image" />
                  </span>
                )}
                {title?.icon} {title?.name} <span className="title-span">{title?.span}</span>
              </h2>
              {
                type === "dashboard" &&
                <Formik
                  initialValues={{ time: "daily", instore: "All Instores" }}
                  onSubmit={() => { }}
                >
                  {({ values, setFieldValue, errors, touched, dirty, isValid }) => (
                    <Form className='dashboard-filter-form'>
                      <Row>
                        <Col span={24}>
                          <div className="select-field">
                            <IconComponent name="icon-vendor-blue" />
                            <DropdownField
                              color="black"
                              label=""
                              options={instoreFilters}
                              name="instore"
                              placeholder="Select"
                              onChange={(value) => {
                                setFieldValue("instore", value)
                                setSelectedInstore(value)
                              }}
                              value={values?.instore}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Dropdown trigger={["click"]} overlay={menu}>
                            <p className="ant-dropdown-link">
                              <IconComponent name="icon-calender-blue" />
                              {(selectedFilter === CURRENT_YEAR || selectedFilter === CURRENT_MONTH) ? DashboardDateFilterTypesDefinition[selectedFilter as DashboardDateFilterTypes] : selectedFilter} <IconComponent name="icon-down" />
                            </p>
                          </Dropdown>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              }

            </div>
          </Col>
          <Col span={9}>
            {type !== "back-only" && (
              <div className="page-view-header-title__right">
                {location?.hash === "#details" && vendorFormHandler &&(
                  <ButtonComponent
                    type="primary"
                    size="medium"
                    icon={<IconComponent name="icon-edit-white" />}
                    onClick={vendorFormHandler}
                    className="vendor-form-edit"
                  >
                    <span className="text">
                      Edit Details
                    </span>
                  </ButtonComponent>
                )}
                {
                  location.pathname === AppRoutes.VENDOR_PROFILE && location.hash === "#details" &&
                  <div className="tnc-link">
                    <a href={window.location.origin + AppRoutes.TERMS_AND_CONDITIONS} target="_blank">
                      <IconComponent name="icon-external-blue" />
                      <span className="text">View T&C </span>
                    </a>
                  </div>
                }
                <ContactUs />
                <Notifications />
                <ProfileDetails />
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div className="page-view-content">{children}</div>
    </div>
  );
};

export default PageView;
