import { Dropdown } from "antd";
import React, { FC, Fragment } from "react";
import { SketchPicker } from "react-color";

import "./colorPickerComponent.scss";

interface ColorPickerComponentProps {
  value: string;
  onChange: (hexCode: string) => void;
  label?: string;
}

const ColorPickerComponent: FC<ColorPickerComponentProps> = (props) => {
  const { value, onChange, label } = props;

  return (
    <div className="color-picker-component__wrapper">
      {label ? <label>{label}</label> : ""}
      <Dropdown
        className="color-picker-component"
        trigger={["click"]}
        overlay={
          <SketchPicker
            color={value}
            onChange={(value) => onChange(value?.hex)}
          />
        }
      >
        <div>
          <div className="selected-color" style={{ background: value }} />
          <div className="selected-color-hex">{value}</div>
        </div>
      </Dropdown>
    </div>
  );
};

export default ColorPickerComponent;
