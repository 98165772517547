import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../routes/routeConstants/appRoutes";
import { LocalStorage } from "../../shared/utils/localStorage";
import Notification from "../../shared/components/Notification";
import { VendorProfileStatusTypes } from "../../enums/vendorTypes";
import { ForgotPasswordRequestModel, LoginRequestModel, NewPasswordRequestModel, ResetPasswordTokenRequestModel } from "../../models/Auth/auth.model";
import { SupportDocuments, Vendor, VendorModel } from "../../models/Vendor/vendor.model";
import { InternalUsersModel } from "../../models/InternalUsers/internalusers.model";

const AuthService = () => {
	const navigate = useNavigate();

	const [error, setError] = useState<Error>();

	const [loading, setLoading] = useState(false);

	const { setAuthenticated, setUnauthenticated } = AuthContext();

	const login = (data: LoginRequestModel) => {
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.LOGIN, {vendor_user: {...data, grant_type: "password"}})
			.then((response) => {
				const vendor = deserialize(VendorModel, response.data["vendor_user"]);
				const token = response?.data?.token;
				setAuthenticated(vendor);
    			LocalStorage.setItem("ACCESS_TOKEN", token?.access_token);
    			LocalStorage.setItem("REFRESH_TOKEN", token?.refresh_token);
    			LocalStorage.setItem("VENDOR", vendor);
				switch (vendor?.status) {
					case VendorProfileStatusTypes.APPROVED:
						navigate(AppRoutes.HOME);
						break;
					case VendorProfileStatusTypes.CREATED:
						navigate(
						{
							pathname: AppRoutes.VALIDATEOTPEMAIL,
							search: `email=${vendor?.email}&mobile-number=${vendor?.mobileNumber}&country-code=${vendor?.countryCodeId}`,
						},
						{ state: vendor?.email }
						);
						break;
					case VendorProfileStatusTypes.OTP_VERIFIED:
						navigate({ pathname: AppRoutes.BUSINESSDETAILS, search: `step=0` })
						break;
					case VendorProfileStatusTypes.BUSINESS_INFO:
						navigate({ pathname: AppRoutes.BUSINESSDETAILS, search: `step=1` })
						break;
					case VendorProfileStatusTypes.CONTACT_INFO:
						navigate({ pathname: AppRoutes.BUSINESSDETAILS, search: `step=2` })
						break;
					case VendorProfileStatusTypes.WAITING_FOR_APPROVAL:
						navigate(AppRoutes.VERIFYACCOUNT);
						break;
					case VendorProfileStatusTypes.INACTIVE:
						Notification({
							message: "This user is not active.",
							description: "Please contact our support - support@deall.zendesk.com",
							type: NotificationTypes.INFO,
						})
						break;
					default:
						navigate(AppRoutes.LOGIN)
				}
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const resendOtp = (data: VendorModel, onSuccess: () => void) => {
		const payload = { vendor_user: serialize(VendorModel, data) };
		setLoading(true);
		return axiosInstance
		  .post(ApiRoutes.RESEND_OTP, payload)
		  .then((response) => {
			onSuccess()
			Notification({
				description: "OTP Sent, Please make sure to check your spam folder",
				type: NotificationTypes.INFO,
			});
		  })
		  .catch((error) => {
			setError(error);
		  })
		  .finally(() => {
			setLoading(false);
		  });
	  };

	const forgotPasswordRedirectUrl = (data: ForgotPasswordRequestModel,onSuccess: Function) => {
		const requestPayload = {
			vendor_user: serialize(ForgotPasswordRequestModel, data),
		  };
		  setLoading(true);
		  return axiosInstance
			.post(ApiRoutes.FORGOT_PASSWORD, requestPayload)
			.then((response) => {
			  Notification({
				description: response?.data?.success,
				type: NotificationTypes.SUCCESS,
			  });
			  onSuccess();
			})
			.catch((error) => {
			  setError(error);
			})
			.finally(() => {
			  setLoading(false);
			});
	}

	const updateNewPassword = (
		data: NewPasswordRequestModel,
		onSuccess: Function
	  ) => {
		const requestPayload = {vendor_user: serialize(NewPasswordRequestModel, data)};
		setLoading(true);
		return axiosInstance
		  .put(ApiRoutes.NEW_PASSWORD, requestPayload)
		  .then((response) => {
			Notification({
			  description: "Your password has been changed successfully.",
			  type: NotificationTypes.SUCCESS,
			});
			onSuccess();
		  })
		  .catch((error) => {
			setError(error);
		  })
		  .finally(() => {
			setLoading(false);
		  });
	  };
	
	  const validateResetPasswordToken = (
		data: ResetPasswordTokenRequestModel
	  ) => {
		const requestPayload = {
		  vendor_user: serialize(ResetPasswordTokenRequestModel, data),
		};
		return axiosInstance.post(
		  ApiRoutes.VALIDATE_RESET_PASSWORD_TOKEN,
		  requestPayload
		);
	  };

	  const createVendor = (data:VendorModel, onSuccess:Function) => {
		const requestPayload = {
			vendor_user: serialize(VendorModel, data)
		  };
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.CREATE_VENDOR, requestPayload)
			.then((response) => {
				const vendor = deserialize(VendorModel, response.data["vendor"]);
				Notification({
					description: "OTP Sent, Please make sure to check your spam folder",
					type: NotificationTypes.INFO,
				});
				onSuccess()
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	  }

	  const validateEmailMobileNumberOtp = (data:VendorModel, onSuccess:Function) => {
		const requestPayload = {
			vendor_user: serialize(VendorModel, data)
		  };
		setLoading(true);
		return axiosInstance
			.post(ApiRoutes.VERIFY_EMAIL_MOBILE_NUMBER_OTP, requestPayload)
			.then((response) => {
				const vendor = deserialize(Vendor, response.data["vendor"]);
				const token = response?.data?.token;
				setAuthenticated(vendor as VendorModel);
    			LocalStorage.setItem("ACCESS_TOKEN", token?.access_token);
    			LocalStorage.setItem("REFRESH_TOKEN", token?.refresh_token);
    			LocalStorage.setItem("VENDOR", vendor);
				onSuccess()
			})
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	  }

	  const addVendorDetails = (data: Vendor,onSuccess: Function) => {
		const requestPayload = {
			vendor: serialize(Vendor, data),
		  };
		  setLoading(true);
		  return axiosInstance
			.post(ApiRoutes.ADD_VENDOR_DETAILS, requestPayload)
			.then((response) => {
			const vendor = deserialize(Vendor, response.data["vendor"]);
			  onSuccess();
			})
			.catch((error) => {
			  setError(error);
			})
			.finally(() => {
			  setLoading(false);
			});
	}

	 const updateContactDetails = (data: InternalUsersModel,onSuccess: Function) => {
		const requestPayload = {
			vendor: serialize(InternalUsersModel, data),
		  };
		  setLoading(true);
		  return axiosInstance
			.post(ApiRoutes.UPDATE_EMAIL_MOBILE_NUMBER, requestPayload)
			.then((response) => {
			const vendor = deserialize(InternalUsersModel, response.data["vendor"]);
			  onSuccess();
			})
			.catch((error) => {
			  setError(error);
			})
			.finally(() => {
			  setLoading(false);
			});
	}

	const updateDocuments = (data: SupportDocuments,onSuccess: Function) => {
		const requestPayload = {
			vendor: serialize(SupportDocuments, data),
		  };
		  setLoading(true);
		  return axiosInstance
			.post(ApiRoutes.UPDATE_DOCUMENTS, requestPayload)
			.then((response) => {
			const vendor = deserialize(SupportDocuments, response.data["vendor"]);
			  onSuccess();
			})
			.catch((error) => {
			  setError(error);
			})
			.finally(() => {
			  setLoading(false);
			});
	}

	  const logout = () => {
		setLoading(true)
		return axiosInstance
		  .delete(ApiRoutes.LOGOUT)
		  .then(() => {
			setUnauthenticated();
			localStorage.clear(); 
		  })
		  .catch((error) => {
			setError(error);
		  })
		  .finally(() => {
			setLoading(false);
		  });
	  };

	return {
		error,
		loading,
		login,
		forgotPasswordRedirectUrl,
		validateResetPasswordToken,
		updateNewPassword,
		createVendor,
		validateEmailMobileNumberOtp,
		addVendorDetails,
		updateContactDetails,
		updateDocuments,
		logout,
		resendOtp
	};
};

export default AuthService;
