import React, { FC, useState } from "react";
import CardComponent from "../CardComponent";
import "./documentsCard.scss";
import IconComponent from "../IconComponent";
import MenuComponent from "../MenuComponent";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import CardSkeletonComponent from "../CardSkeletonComponent";
import { useTranslation } from "react-i18next";
import { Col, Divider, Row } from "antd";
import { generatePath, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import FileViewModal from "../FileViewModal";
interface DocumentsCardProps {
  isUpload?: boolean;
  uploadHandler?: (file: RcFile, fileUrl: string) => void;
  className?: string;
  data?: DocumentItemCardProps[];
  loading?: boolean;
}
interface DocumentItemCardProps {
  fileName?: string;
  fileUrl?: string;
  fileType?: string;
  fileId?: string;
  accept?: string;
  isUpload?: boolean;
  uploadHandler?: (file: RcFile, fileUrl: string) => void;
  handleChange?: (info: UploadChangeParam) => void;
  label?: string;
  viewType?: "modal" | "route";
}

export const DocumentItemCard: FC<DocumentItemCardProps> = (props) => {
  const {
    isUpload,
    uploadHandler,
    fileName,
    fileUrl,
    fileId,
    fileType,
    handleChange,
    accept,
    viewType
  } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [fileViewModalVisible, setFileViewModalVisible] = useState(false);
  const fileAction = () => {
    viewType === "modal" ?
      setFileViewModalVisible(true) :
      navigate(generatePath(AppRoutes.FILE_VIEW, { fileId: `${fileId}` }), { state: { fileUrl, fileName, fileType } })
  }
  
  return (
    <React.Fragment>
      <FileViewModal data={props} visible={fileViewModalVisible} closeHandler={() => setFileViewModalVisible(false)} />
      <Row className="document-item-card">
        <Col span={4} onClick={fileAction}>
          <div className="document-card-icon">
            <IconComponent name="icon-document-blue" />
          </div>
        </Col>
        <Col span={isUpload ? 16 : 18} onClick={fileAction}>
          <p className="document-item-title">
            {fileName}
          </p>
        </Col>
        <Col span={1}>
          {isUpload && (
            <MenuComponent
              dropdownClassName="document-reupload"
              menu={[
                {
                  title: t("reupload"),
                  icon: <IconComponent name="icon-upload-black" />,
                  upload: {
                    handleChange,
                    accept: accept ?? "application/pdf",
                  },
                },
              ]}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const DocumentsCard: FC<DocumentsCardProps> = (props) => {
  const { isUpload, uploadHandler, className, data, loading } = props;
  const { t } = useTranslation()

  return loading ? (
    <CardSkeletonComponent />
  ) : (
    <CardComponent className={`documents-card ${className}`}>
      {data?.map((value, index) => {
        return (
          value?.fileId &&
          <div>
            <p className="document-item-card--label">{value?.label}</p>
            <DocumentItemCard
              {...value}
              isUpload={isUpload}
              uploadHandler={uploadHandler}
            />
            <Divider />
          </div>
        );
      })}
    </CardComponent>
  );
};

export default DocumentsCard;
