export const REGEX = {
    PASSWORD:  {
        match:
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
        error: "Password should be alphanumeric and contain minimum 8 characters!"
    },
    PHONE: {
       match: /^01[0125][0-9]{8}$/gm,
       error: "Enter valid mobile number"
    },
    PHONE_INDIA: {
        match: /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/gm,
        error: "Enter valid mobile number"
    },
    PHONE_US: {
        match: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/gm,
        error: "Enter valid mobile number"
    },
    PHONE_ISRAEL: {
        match: /^\+?(972|0)(\-)?0?(([23489]{1}\d{7})|[5]{1}\d{8})$/gm,
        error: "Enter valid mobile number"
    },
    NAME: {
        match: /^[a-zA-Z0-9.' ]+$/,
        error: "Name should not contain special characters"
    }
}
