export const handleBeforeImageUpload = (file: File, acceptedTypes: Array<string>, type: string) => {
  
    if (acceptedTypes.includes(file.type)) {
        return handleImageValidation(file).then((hasTransparentPixels) => {
          if (!hasTransparentPixels && type === "logo") {
            return cropImage(file)
          }
          return !hasTransparentPixels;
        });
      }
}; 

export const handleImageValidation = (file: File) => {
    const isPng = file.type === 'image/png';
  
    if (!isPng) {
      return Promise.resolve(false);
    }
  
    return new Promise<boolean>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const { width, height } = img;
          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0);
  
          const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
          if (imageData) {
            const hasTransparentPixels = Array.from(imageData.data).some((value, index) => {
              if ((index + 1) % 4 === 0 && value < 255) {
                return true;
              }
              return false;
            });
            resolve(hasTransparentPixels);
          }
        };
      };
    });
  };

export const cropImage = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<File | boolean>((resolve) => {
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target?.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const { width, height } = img;

          const size = Math.min(width, height);
          const offsetX = (width - size) / 2;
          const offsetY = (height - size) / 2;

          canvas.width = size;
          canvas.height = size;
          ctx?.drawImage(img, offsetX, offsetY, size, size, 0, 0, size, size);

          canvas.toBlob((blob) => {
            if (blob) {
              const croppedFile = new File([blob], file.name, { type: file.type });
              resolve(croppedFile);
            } else {
              resolve(false);
            }
          }, file.type);
        };
      };
    });
}