import {
	serializable,
	alias,
	object,
	list,
	primitive,
	custom,
} from "serializr";
import { VendorTypes } from "../../enums/vendorTypes";
import { countryCodesInitialValue } from "../../shared/utils/generateCountryCodeOptions";
import { AttachmentModel } from "../Attachment/attachment.model";
import { InternalUsersModel } from "../InternalUsers/internalusers.model";
import { MetaModel } from "../Meta/meta.model";

const {BOTH, ONLINE, INSTORE} = VendorTypes

export class VendorListModel {
	@serializable(alias("id", primitive()))
	id?: number;

	@serializable(alias("vendor_user_id", primitive()))
	vendorUserId?: number;

	@serializable(alias("name", primitive()))
	name?: string;

	@serializable(alias("type", primitive()))
	type?: string;

	@serializable(alias("email", primitive()))
	email?: string;

	@serializable(alias("no_of_orders", primitive()))
	noOfOrders?: number;

	@serializable(alias("status", primitive()))
	status?: string;

	@serializable(alias("mobile_number", primitive()))
	mobileNumber?: string;

	@serializable(alias("full_name", primitive()))
	fullName?: string;

	@serializable(alias("created_at", primitive()))
	createdAt?: string;

}

export class WalletType {

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('updated_at', primitive()))
	updatedAt?: string;

}


export class PaymentDetails {
	@serializable(alias('account_holder_name', primitive()))
	accountHolderName?: string;

	@serializable(alias('address', primitive()))
	address?: string;

	@serializable(alias('account_number', primitive()))
	accountNumber?: string;

	@serializable(alias('iban', primitive()))
	iban?: string;

	@serializable(alias('swift_code', primitive()))
	swiftCode?: string;

	@serializable(alias('branch', primitive()))
	branch?: string;

	@serializable(alias('wallet_number', primitive()))
	walletNumber?: string;

	@serializable(alias('wallet_type', object(WalletType)))
	walletType?: WalletType;

	@serializable(alias('wallet_type_id', primitive()))
	walletTypeId?: string;
}

export class VendorModel {
	@serializable(alias("logo", object(AttachmentModel)))
	logo?: AttachmentModel;

	@serializable(alias("logo_id", primitive()))
	logoId?: string;

	@serializable(alias("cover_pic", object(AttachmentModel)))
	coverPic?: AttachmentModel;

	@serializable(alias("cover_pic_id", primitive()))
	coverPicId?: string;

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('logo_url', primitive()))
	logoUrl?: string;

	@serializable(alias('cover_pic_url', primitive()))
	coverPicUrl?: string;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;

	@serializable(alias('full_name', primitive()))
	fullName?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('country_code_id', primitive()))
	countryCodeId?: string;

	@serializable(alias('country_code', object(MetaModel)))
    countryCode?: MetaModel;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

	@serializable(alias('mobile_number', primitive()))
	mobileNumber?: string;

	@serializable(alias('email_otp', primitive()))
	emailOtp?: string;

	@serializable(alias('mobile_number_otp', primitive()))
	mobileNumberOtp?: string;

	@serializable(alias('password', primitive()))
	password?: string;

	@serializable(alias('category', primitive()))
	category?: string;

	@serializable(alias('category_id', primitive()))
	categoryId?: string;

	@serializable(alias('sub_category', primitive()))
	subCategory?: string;

	@serializable(alias('sub_category_id', primitive()))
	subCategoryId?: string;

	@serializable(alias('type', primitive()))
	type?: typeof BOTH|typeof ONLINE|typeof INSTORE;

	@serializable(alias('website_url', primitive()))
	websiteUrl?: string;

	@serializable(alias('no_of_physical_stores', primitive()))
	noOfPhysicalStores?: number;

	@serializable(alias('no_of_instores', primitive()))
	noOfInstores?: number;

	@serializable(alias('processing_fee', primitive()))
	processingFee?: number;

	@serializable(alias('monthly_transaction_volume', primitive()))
	monthlyTransactionVolume?: number;

	@serializable(alias('max_credit_per_order', primitive()))
	maxCreditPerOrder?: number;

	@serializable(alias('brand_colour', primitive()))
	brandColour?: string;

	@serializable(alias('bank', primitive()))
	bank?: string;

	@serializable(alias('bank_id', primitive()))
	bankId?: string;

	@serializable(alias('branch', primitive()))
	branch?: string;

	@serializable(alias('branch_id', primitive()))
	branchId?: string;

	@serializable(alias('account_number', primitive()))
	accountNumber?: string;

	@serializable(alias('vallet_number', primitive()))
	walletNumber?: string;

	@serializable(alias('commercial_registration_url', primitive()))
	commercialRegistrationUrl?: string;

	@serializable(alias('tax_card_url', primitive()))
	taxCardUrl?: string;

	@serializable(alias('is_exclusivity_with_deall', primitive()))
	isExclusivityWithDeall?: boolean;

	@serializable(alias('status', primitive()))
	status?: string;

	@serializable(alias('resend_otp_type', primitive()))
	resendOtpType?: string;
}

export class SupportDocuments {
	@serializable(alias('commercial_registration_url', primitive()))
	commercialRegistrationUrl?: string;

	@serializable(alias('tax_card_url', primitive()))
	taxCardUrl?: string;

	@serializable(alias('is_exclusivity_with_deall', primitive()))
	isExclusivityWithDeall?: boolean;

	@serializable(alias('agree_to_tc', primitive()))
	agreeToTC?: boolean;

	@serializable(alias("commercial_registration", object(AttachmentModel)))
	commercialRegistration?: AttachmentModel;

	@serializable(alias("commercial_registration_id", primitive()))
	commercialRegistrationId?: string;

	@serializable(alias("tax_card", object(AttachmentModel)))
	taxCard?: AttachmentModel;

	@serializable(alias("tax_card_id", primitive()))
	taxCardId?: string;
}
export class Vendor {

	@serializable(alias('vendor_details', object(VendorModel)))
	vendorDetails?: VendorModel;

	@serializable(alias('payment_details', object(PaymentDetails)))
	paymentDetails?: PaymentDetails;

	@serializable(alias('contact_person_details', object(InternalUsersModel)))
	contactPersonDetails?: InternalUsersModel;

	@serializable(alias('support_documents', object(SupportDocuments)))
	supportDocuments?: SupportDocuments;

}

export class TokenModel {

	@serializable(alias('access_token', primitive()))
	accessToken?: string;

	@serializable(alias('token_type', primitive()))
	tokenType?: string;

	@serializable(alias('expires_in', primitive()))
	expiresIn?: number;

	@serializable(alias('refresh_token', primitive()))
	refreshToken?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

}