

import {serializable, alias, object, list, primitive} from 'serializr';
import { BadgeTypes } from '../../enums/badgeTypes';
import { VendorTypes } from '../../enums/vendorTypes';
import { AttachmentModel } from '../Attachment/attachment.model';


export class SettlementInstoreModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

}

export class SettlementVendorModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('logo_url', primitive()))
	logoUrl?: string;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('mode', primitive()))
	mode?: VendorTypes;

	@serializable(alias('vendor_settelment_status', primitive()))
	vendorSettelmentStatus?: string;

	@serializable(alias('instore', object(SettlementInstoreModel)))
	instore?: SettlementInstoreModel;

	@serializable(alias('logo', object(AttachmentModel)))
	logo?: AttachmentModel;

}

export class PendingSettlementModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('vendor', object(SettlementVendorModel)))
	vendor?: SettlementVendorModel;

	@serializable(alias('instore', object(SettlementInstoreModel)))
	instore?: SettlementInstoreModel;

	@serializable(alias('is_amount_negetive', primitive()))
	isAmountNegative?: number;

	@serializable(alias('mode', primitive()))
	mode?: VendorTypes;

	@serializable(alias('customer_processing_fee', primitive()))
	customerProcessingFee?: number;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: string;

	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: number = 0;
	
	@serializable(alias('vat', primitive()))
	vat?: number = 0;
	
	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: number = 0;

}


export class PastSettlementListModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('vendor', object(SettlementVendorModel)))
	vendor?: SettlementVendorModel;

	@serializable(alias('mode', primitive()))
	mode?: string;

	@serializable(alias('no_of_orders', primitive()))
	noOfOrders?: number;

	@serializable(alias('transaction_id', primitive()))
	transactionId?: string;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('settled_date', primitive()))
	settledDate?: string;
	
	@serializable(alias('vat', primitive()))
	vat?: string;

}

export class SettlementCustomerPurchaseModel { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('purchase_id', primitive()))
	purchaseId?: string;

	@serializable(alias('vendor', object(SettlementVendorModel)))
	vendor?: SettlementVendorModel;

	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: BadgeTypes;

	@serializable(alias('mode', primitive()))
	mode?: string;

	@serializable(alias('amount', primitive()))
	amount?: number;

	@serializable(alias('total_amount', primitive()))
	totalAmount?: number;

	@serializable(alias('is_amount_negetive', primitive()))
	isAmountNegative?: number;

	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: number = 0;
	
	@serializable(alias('vat', primitive()))
	vat?: number = 0;
	
	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: number = 0;

}

export class SettlementModel extends PastSettlementListModel {
	
	@serializable(alias('vendor_processing_fee', primitive()))
	vendorProcessingFee?: number;

	@serializable(alias('settlement_amount', primitive()))
	settlementAmount?: number;
	
	@serializable(alias('vendor_settlement_amount', primitive()))
	vendorSettlementAmount?: number;
	
	@serializable(alias('refunded_transactions', primitive()))
	refundedTransactions?: number;

	@serializable(alias('customer_purchases', list(object(SettlementCustomerPurchaseModel))))
	customerPurchases?: SettlementCustomerPurchaseModel[] = [];

}

export class PastSettlementFilterModel {

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias("vendor_id", primitive()))
	vendorId?: string;
  
	@serializable(alias("vendor_name", primitive()))
	vendorName?: string;

	@serializable(alias("search", primitive()))
	search?: string;

	@serializable(alias("page", primitive()))
	page?: number;

	@serializable(alias('settled_date', primitive()))
	settledDate?: string;
}

export class PendingSettlementFilterModel extends PastSettlementFilterModel {
	
	@serializable(alias('purchase_status', primitive()))
	purchaseStatus?: string;

}

