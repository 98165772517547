import { ErrorMessage } from "formik";
import React, { FC } from "react";
import "./error.scss"

interface ErrorMessageProps {
    message?: string;
    testId?: string;
}

const Error: FC<ErrorMessageProps> = ({ message, testId }) => {
    return (
        <div className="input__error" data-testid={testId}>
            { message }
        </div>
    )
}

export default Error;