import React, { useEffect, useState } from "react";
import { Formik, Form, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import { Link, useSearchParams } from "react-router-dom";

import { Button } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { AuthContext } from "../../../context/AuthContext";
import { DownloadOutlined } from '@ant-design/icons';
import ButtonComponent from "../../../shared/components/ButtonComponent";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { newPasswordValidationSchema } from "./newPasswordValidationSchema";
import { useTranslation } from "react-i18next";

interface User {
  email: string;
  password: string;
}

const initialValue = {
  email: "",
  password: "",
};

const NewPassword = (props: any) => {
  const navigate = useNavigate();
  const { loading, validateResetPasswordToken, updateNewPassword } = AuthService();
  const initialValue = {
    password: "",
    confirmPassword: "",
  };
  const {t} = useTranslation()
  const [searchParams] = useSearchParams();
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const resetPasswordToken: any = searchParams.get("reset_password_token");
  const email: any = searchParams.get("email");

  const onSubmit = (values: FormikValues) => {
    if (resetPasswordToken && email) {
      updateNewPassword(
        {
          email: email,
          newPassword: values?.password,
          resetPasswordToken: resetPasswordToken,
        },
        () => {
          navigate(AppRoutes.LOGIN);
        }
      );
    } else {
      Notification({
        description: "Invalid password reset link!",
        type: NotificationTypes.ERROR,
      });
    }
  };

  useEffect(() => {
    if (resetPasswordToken && email)
      validateResetPasswordToken({
        resetPasswordToken,
        email,
      })
        .then(() => {
          setIsResetEnabled(true);
        })
        .catch(() => {
          setIsResetEnabled(true);

        });
  }, []);

  return (
    <div>
      <div className='form_heading'>
        <h1>
         {t("reset_password")}
        </h1>
      </div>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        validationSchema={newPasswordValidationSchema}
      >
        <Form>
          <div className='email_part'>
            <InputField type="password" label='New Password' name="password" placeholder="Enter" />
          </div>
          <div className='password_part'>
            <InputField
              label='Confirm Password'
              type="password"
              name="confirmPassword"
              placeholder="Enter"
            />
          </div>
          <div className='login_button'>
            <ButtonComponent type="primary"
              htmlType="submit"
              className="auth-form__btn"
              size="wide"
              disabled={!isResetEnabled}
              loading={loading}>
              {t("update_password")}
            </ButtonComponent>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default NewPassword;
