import React, { useEffect } from "react";
import { Option } from "antd/lib/mentions";
import { MetaModel } from "../../models/Meta/meta.model";
import MetaService from "../../services/MetaService/meta.service";

// TODO needed for validation reference
// const data = [{value: "1",name: "Egypt",flag: "EG",isdCode: "+20"},{value: "2",name: "Israel",flag: "IL",isdCode: "+972"},{value: "3",name: "India",flag: "IN",isdCode: "+91"},{value: "4",name: "US",flag: "US",isdCode: "+1"},]
export const generateCountryCodeOptions = (countryCodesMeta: MetaModel[]) => {
    return countryCodesMeta?.map((option) => (
        <Option value={option?.value as string}>{option?.flag}{" "}{option?.isdCode}</Option>
    ))
}

export const generateCodeOptions = (countryCodesMeta: MetaModel[]) => {
    return countryCodesMeta?.map((item) => ({
        value: item?.value,
        label: `${item?.flag} ${item?.isdCode}`
      })) || [];
}

export const countryCodesInitialValue = (countryCodesMeta: MetaModel[]) => countryCodesMeta?.find((countryCode)=>countryCode?.flag==="EG")?.value as string 

export const getCountryIsdCode = (countryCodesMeta: MetaModel[], id: number | undefined) => countryCodesMeta?.find((countryCode)=>countryCode?.value===id)?.isdCode as string 