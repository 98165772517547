import React, { FC, Fragment, useState } from "react";
import "./vendorInstoreDetails.scss";
import { Col, Divider, Row } from "antd";
import { VendorInstoreModel } from "../../../../../models/Vendor/Instore/instore.model";
import { BadgeTypes } from "../../../../../enums/badgeTypes";
import ModalComponent from "../../../../../shared/components/ModalComponent";
import IconComponent from "../../../../../shared/components/IconComponent";
import MapComponent from "../../../../../shared/components/MapComponent";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import VendorService from "../../../../../services/VendorService/vendor.service";
import { useTranslation } from "react-i18next";
interface VendorInstoreDetailsProps {
  visible: boolean;
  instore: VendorInstoreModel | undefined;
  closeHandler: () => void;
  refreshList?: () => void;
}

const VendorInstoreDetails: FC<VendorInstoreDetailsProps> = (props) => {
  const { visible, closeHandler, instore, refreshList } = props;
  const {t} = useTranslation()
  const { updateVendorInstore, loading } = VendorService();
  const [statusLoading, setStatusLoading] = useState<
    BadgeTypes.ACTIVE | BadgeTypes.REJECTED
  >();

  const handleUpdate = (status: BadgeTypes.ACTIVE | BadgeTypes.REJECTED) => {
    if (instore?.id) {
      setStatusLoading(status);
      updateVendorInstore(
        instore?.id,
        {
          status,
        },
        () => {
          refreshList && refreshList();
          closeHandler();
          setStatusLoading(undefined);
        },
        () => {
          setStatusLoading(undefined);
        }
      );
    }
  };

  return (
    <ModalComponent
      visible={visible}
      closeHandler={closeHandler}
      type="details"
      className="vendor-instore-details-modal"
    >
      <div className="vendor-instore-details">
        <Row align="top">
          <Col span={12}>
            <div className="vendor-details__wrapper">
              <div className="vendor-details">
                <img src={instore?.logoUrl ?? "https://placeimg.com/640/480/abstract"} />
                <h4>{instore?.vendorName}</h4>
              </div>
              <h2 className="instore-name">{instore?.name}</h2>
              <div className="instore-address__wrapper">
                <IconComponent name="icon-map" />
                <p className="instore-address">{instore?.address}</p>
              </div>
            </div>
          </Col>

          <Col span={12}>
            {instore?.lat && instore?.long ? (
              <div className="instore-map__wrapper" onClick={()=>window.open(`https://maps.google.com/?q=${instore?.lat},${instore?.long}`)}>
                <MapComponent
                  position={{
                    lat: instore?.lat,
                    lng: instore?.long,
                  }}
                  editable={false}
                  className="vendor-instore-details-map"
                />
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Divider />
        <div className="instore-contact-details">
          <div className="label">{t("contact_person")}</div>
          <div className="name">{instore?.contactFullName}</div>
          <div className="contact">
            <p className="phone-number">
              <IconComponent name="icon-phone" />
              {instore?.countryCode?.isdCode} {instore?.mobileNumber}
            </p>
            <p className="email">
              <IconComponent name="icon-email" />
              {instore?.email}
            </p>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default VendorInstoreDetails;
