const ApiBasePaths = {
    AUTH: "/user_management/vendor/auth",
    VENDORS: "/vendor_management/vendors",
    PURCHASE_MANAGEMENT: "/purchase_management/vendors",
    SETTLEMENTS_MANAGEMENT: "/settlements_management/vendors",
    SETTLEMENT_MANAGEMENT: "/settlements_management/vendor",
    INTERNAL_USERS_MANAGEMENT: "/user_management/vendor/vendor_users",
    ADMIN_INTERNAL_USERS_MANAGEMENT: "/vendor_management/admin/vendor_users",
    VENDOR_PROMOTIONS_MANAGEMENT: "/vendor_promotions_management/vendors",
    INSTORE_MANAGEMENT: "/instore_management/vendors/instores",
    NOTIFICATION_MANAGEMENT: "/notification_management/vendors/notifications",
    DASHBOARD_MANAGEMENT: "/dashboard_management/vendors/dashboards",
    ATTACHMENT: "/attachment_management/vendors",
    META: "/meta"
}
export const ApiRoutes = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    LOGIN: ApiBasePaths.AUTH + "/login",
    RESEND_OTP: ApiBasePaths.AUTH + "/resend_otp",
    FORGOT_PASSWORD: ApiBasePaths.AUTH + "/forgot_password",
    NEW_PASSWORD: ApiBasePaths.AUTH + "/password",
    VALIDATE_RESET_PASSWORD_TOKEN: ApiBasePaths.AUTH + "/validate_reset_password_token",
    CREATE_VENDOR: ApiBasePaths.AUTH + "/sign_up",
    VERIFY_EMAIL_MOBILE_NUMBER_OTP: ApiBasePaths.AUTH + "/verify_email_mobile_number_otp",
    ADD_VENDOR_DETAILS: ApiBasePaths.VENDORS + "/add_vendor_details",
    UPDATE_EMAIL_MOBILE_NUMBER:  ApiBasePaths.VENDORS + "/update_email_mobile_number",
    UPDATE_DOCUMENTS: ApiBasePaths.VENDORS + "/update_vendor_documents",

    PURCHASES: ApiBasePaths.PURCHASE_MANAGEMENT + "/customer_purchases",
    PURCHASE: ApiBasePaths.PURCHASE_MANAGEMENT + "/customer_purchases/:purchaseId",
    PURCHASE_CHALLENGE_RETURN: ApiBasePaths.PURCHASE_MANAGEMENT + "/customer_purchases/:purchaseId/challenge_return",

    PENDING_SETTLEMENTS: ApiBasePaths.SETTLEMENTS_MANAGEMENT + "/pending_settlements",
    PAST_SETTLEMENTS: ApiBasePaths.SETTLEMENTS_MANAGEMENT + "/past_settlements",
    SETTLEMENT: ApiBasePaths.SETTLEMENTS_MANAGEMENT + "/vendor_settlements/:settlementId",

    INTERNAL_USERS: ApiBasePaths.INTERNAL_USERS_MANAGEMENT,
    INTERNAL_USER: ApiBasePaths.INTERNAL_USERS_MANAGEMENT + "/:userId",
    ADMIN_INTERNAL_USER: ApiBasePaths.ADMIN_INTERNAL_USERS_MANAGEMENT + "/:userId",

    VENDOR_DETAILS: ApiBasePaths.VENDORS + "/current_vendor",

    VENDOR_STORE_PROMOTIONS: ApiBasePaths.VENDOR_PROMOTIONS_MANAGEMENT + "/store_promotions",
    VENDOR_STORE_PROMOTION: ApiBasePaths.VENDOR_PROMOTIONS_MANAGEMENT + "/store_promotions/:storePromotionId",

    VENDOR_INSTORES: ApiBasePaths.INSTORE_MANAGEMENT,
    VENDOR_INSTORE: ApiBasePaths.INSTORE_MANAGEMENT + "/:instoreId",

    CURRENT_VENDOR_DETAILS: ApiBasePaths.VENDORS + "/me",

    LOGOUT: ApiBasePaths.AUTH+"/logout",

    // META
    META_CATEGORY: ApiBasePaths.META + "/categories",
    META_SUB_CATEGORY: ApiBasePaths.META + "/categories/:categoryId/sub_category",
    META_WALLET_TYPES: ApiBasePaths.META + "/wallet_types",
    META_COUNTRY_CODES: ApiBasePaths.META + "/country_codes",

    // ATTACHMENTS
    S3_PRESIGNED_URL: ApiBasePaths.ATTACHMENT + "/presigned_url",
    S3_ATTACHMENT:   ApiBasePaths.ATTACHMENT + "/attachment",

    // NOTIFICATIONS
    NOTIFICATIONS: ApiBasePaths.NOTIFICATION_MANAGEMENT,
    NOTIFICATION: ApiBasePaths.NOTIFICATION_MANAGEMENT + "/:notificationId",

    // DASHBOARD
    DASHBOARD_KEY_METRICS: ApiBasePaths.DASHBOARD_MANAGEMENT + "/key_metrics",
    DASHBOARD_PURCHASE_VALUE: ApiBasePaths.DASHBOARD_MANAGEMENT + "/purchase_value",
    DASHBOARD_PURCHASE_COUNT: ApiBasePaths.DASHBOARD_MANAGEMENT + "/purchase_count",
    
}
